import { v4 as uuidv4 } from 'uuid';
import { useEditor } from "../../data/editor";
import { BaseObject, COLOR_BLACK, Color, Fill, LayoutProps, StylingProps, layoutOf, stylingOf } from "../../data/model";
import equal from 'fast-deep-equal/es6';
import { RGBColor, SketchPicker } from 'react-color';
import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { MultiPropRow, NumberInput, PropButton, PropButtonWithPreview, PropCheckbox, SidebarSection } from './controls';
import { FillPicker, SingleColorPicker } from './colorPickers';
import { ShadowPicker } from './shadowPicker';
import { useRandomColor } from '../../utils/renderViz';

interface StyleControlProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

type PropUpdater<T> = (val: T, gestureId?: string) => void;

export default function StyleControl(props: StyleControlProps) {
    const {wrapperRef, objects} = props;
    const allObjectsHaveStyle = objects.every(obj => stylingOf(obj) !== null) && objects.length > 0;
    const editor = useEditor();
    // const borderColor = useRandomColor();

    function propUpdater<T>(readVal: (obj: StylingProps) => T, writeVal: (obj: StylingProps, val: T) => void): PropUpdater<T> {
        return (val, gestureId) => {
            const ids = objects.map(obj => obj.id);
            const oldValues: [string, T][] = objects.map(obj => ([obj.id, readVal(stylingOf(obj)!)]));
            editor.modifyUndoable(state => {
                return {
                    gestureId,
                    do: state => {
                        ids.forEach(id => {
                            const obj = state.document.objects[id];
                            if (!obj) return;
                            const styling = stylingOf(obj)!;
                            writeVal(styling, val);
                        });
                    },
                    undo: state => {
                        oldValues.forEach(([id, value]) => {
                            const obj = state.document.objects[id];
                            if (!obj) return;
                            const styling = stylingOf(obj)!;
                            writeVal(styling, value);
                        });
                    }
                }
            });
        }
    }

    function setProp<K extends keyof StylingProps>(key: K, value: StylingProps[K], gestureId: string | undefined = undefined) {
        propUpdater(obj => obj[key], (obj, val) => obj[key] = val)(value, gestureId);
    }

    if (!allObjectsHaveStyle) {
        return null;
    }
    const sharedStyle = ifAllEqual(objects.map(obj => stylingOf(obj)));

    let borderSection: React.ReactNode = null;
    let addBorderButton: React.ReactNode = null;
    if (sharedStyle && sharedStyle.borderColor && sharedStyle.borderWidth) {
        const removeBorder = () => {
            setProp('borderColor', undefined);
            setProp('borderWidth', undefined);
        };
        borderSection = (
            <SidebarSection>
                <h6>Border</h6>
                <SingleColorPicker color={sharedStyle.borderColor} onChange={(color, gestureId) => {
                    setProp('borderColor', color, gestureId);
                    // setProp('borderWidth', sharedStyle.borderWidth ?? 1, gestureId);
                }} />
                <NumberInput scrubScale={0.5} label="Width" value={sharedStyle.borderWidth} onChange={width => {
                    // setProp('borderColor', sharedStyle.borderColor || COLOR_BLACK, 'borderWidthSlider');
                    setProp('borderWidth', width, 'borderWidthSlider');
                }} />
                <PropButton label="Remove Border" onClick={removeBorder} />
            </SidebarSection>
        )
    } else {
        const addBorder = () => {
            setProp('borderColor', COLOR_BLACK);
            setProp('borderWidth', 1);
        };
        addBorderButton = <PropButton label="Add Border" onClick={addBorder} />
    }

    return <>
        <SidebarSection>
        <h6>Style</h6>
            <FillPicker fill={sharedStyle?.background} onChange={(fill, gestureId) => setProp('background', fill, gestureId)} />
            <MultiPropRow>
                <PropCheckbox label="Clip" value={sharedStyle?.clip} onChange={clip => setProp('clip', clip)} />
                <NumberInput label="Radius" value={sharedStyle?.cornerRadius || 0} onChange={cornerRadius => setProp('cornerRadius', cornerRadius)} min={0} />
            </MultiPropRow>
            <NumberInput scrubScale={0.01} label="Opacity" value={sharedStyle?.opacity || 1} onChange={opacity => setProp('opacity', opacity)} min={0} max={1} />
            <ShadowPicker shadow={sharedStyle?.shadow} onChange={(shadow, gestureId) => setProp('shadow', shadow, gestureId)} />
            {addBorderButton} 
        </SidebarSection>
        {borderSection}
    </>
}

const DEFAULT_FILL: Fill = { kind: 'solid', color: {r: 0, g: 0, b: 0, a: 1}};

export function ifAllEqual<T>(items: T[]): T | undefined {
    if (items.length === 0) {
        return undefined;
    }
    const first = items[0];
    if (items.every(item => equal(item, first))) {
        return first;
    }
    return undefined;
}

// export type Shadow = { x: number, y: number, blur: number, color: Color };
