import { TbAlignCenter, TbAlignLeft, TbAlignRight } from "react-icons/tb";
import { useEditor } from "../../data/editor";
import { BaseObject, Color, EditableText, Font } from "../../data/model"
import { SingleColorPicker } from "./colorPickers";
import { MultiPropRow, NumberInput, SidebarMenu, SidebarPropContainer, SidebarSection, SidebarSegmentedControl } from "./controls";
import { ifAllEqual } from "./style";

interface TextControlProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

type AlignmentType = EditableText['alignment'];
type PropUpdater<T> = (val: T, gestureId?: string) => void;

// EditableText or EditableTextField
interface TextObject extends BaseObject {
    color?: Color;
    alignment?: AlignmentType;
    font?: Font;
}

function isTextObject(obj: BaseObject): obj is TextObject {
    return obj && obj.type === 'editableText' || obj.type === 'editableTextField';
}

export default function TextControl(props: TextControlProps) {
    const {wrapperRef, objects} = props;
    const allObjectsAreText = objects.every(obj => isTextObject(obj)) && objects.length > 0;
    const editor = useEditor();
    const textObjects = objects as TextObject[];

    function propUpdater<T>(readVal: (obj: TextObject) => T, writeVal: (obj: TextObject, val: T) => void): PropUpdater<T> {
        return (val, gestureId) => {
            const ids = textObjects.map(obj => obj.id);
            const oldValues: [string, T][] = textObjects.map(obj => ([obj.id, readVal(obj)]));
            editor.modifyUndoable(state => {
                return {
                    gestureId,
                    do: state => {
                        ids.forEach(id => {
                            const obj = state.document.objects[id];
                            if (!isTextObject(obj)) return;
                            writeVal(obj as TextObject, val);
                        });
                    },
                    undo: state => {
                        oldValues.forEach(([id, value]) => {
                            const obj = state.document.objects[id];
                            if (!isTextObject(obj)) return;
                            writeVal(obj as TextObject, value);
                        });
                    }
                }
            });
        }
    }

    function setProp<K extends keyof TextObject>(key: K, value: TextObject[K], gestureId: string | undefined = undefined) {
        propUpdater(obj => obj[key], (obj, val) => obj[key] = val)(value, gestureId);
    }

    function setFontProp<K extends keyof Font>(key: K, value: Font[K], gestureId: string | undefined = undefined) {
        propUpdater(obj => obj.font ? obj.font[key] : undefined, (obj, val) => {
            if (obj.font === undefined) {
                obj.font = {};
            }
            obj.font![key] = val
        })(value, gestureId);
    }

    if (!allObjectsAreText) {
        return null;
    }
    const sharedAlignment = ifAllEqual(textObjects.map(obj => obj.alignment));
    const sharedColor = ifAllEqual(textObjects.map(obj => obj.color));
    const fontFamily = ifAllEqual(textObjects.map(obj => obj.font?.family));
    const fontSize = ifAllEqual(textObjects.map(obj => obj.font?.size));
    const fontWeight = ifAllEqual(textObjects.map(obj => obj.font?.weight));

    return (
        <SidebarSection>
        <h6>Text</h6>
            <MultiPropRow>
                <SingleColorPicker color={sharedColor || {r: 0, g: 0, b: 0, a: 1}} onChange={(color, gestureId) => setProp('color', color, gestureId)} />
                <SidebarSegmentedControl options={['left', 'center', 'right'] as AlignmentType[]} selection={sharedAlignment} onChange={alignment => setProp('alignment', alignment)} viewForOption={a => <AlignmentIcon alignment={a} />} />
            </MultiPropRow>
            <SidebarPropContainer>
                <label>Font</label>
                <SidebarMenu onChange={e => setFontProp('family', e.target.value)} value={fontFamily || 'Arial'}>
                    {FONT_FAMILY_OPTIONS.map(family => (
                        <option key={family} value={family}>{family}</option>
                    ))}
                </SidebarMenu>
            </SidebarPropContainer>
            <MultiPropRow>
                <SidebarPropContainer>
                    <label>Weight</label>
                    <SidebarMenu onChange={e => setFontProp('weight', parseInt(e.target.value))} value={fontWeight || 400}>
                        {FONT_WEIGHT_OPTIONS.map(([name, val]) => (
                            <option key={val} value={val}>{name}</option>
                        ))}
                    </SidebarMenu>
                </SidebarPropContainer>
                <NumberInput label="Size" value={fontSize || 16} onChange={size => setFontProp('size', size)} min={1} />
            </MultiPropRow>
        </SidebarSection>
    )
}

const FONT_WEIGHT_OPTIONS: [string, number][] = [
    ['Thin', 100],
    ['Extra-light', 200],
    ['Light', 300],
    ['Regular', 400],
    ['Medium', 500],
    ['Semi-bold', 600],
    ['Bold', 700],
    ['Extra-bold', 800],
    ['Black', 900],
];

const FONT_FAMILY_OPTIONS = [
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Times',
    'Courier New',
    'Courier',
    'Verdana',
    'Georgia',
    'Palatino',
    'Garamond',
    'Bookman',
    'Comic Sans MS',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
    'Lucida Console',
    'Lucida Sans Unicode',
    'Tahoma',
    'Geneva',
    'Century Gothic',
    'MS Sans Serif',
    'MS Serif',
];

function AlignmentIcon({alignment}: {alignment: AlignmentType}) {
    return (
        <div style={{width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {alignment === 'left' && <TbAlignLeft />}
            {alignment === 'center' && <TbAlignCenter />}
            {alignment === 'right' && <TbAlignRight />}
        </div>
    )
}
