import { Ref } from "react";
import { Editor, EditorState, EmptyUndoableOperation, UndoableOperation } from "./editor";
import { Document, EditableFrame, BaseObject, childrenOf, supportsChildren, layoutOf, LayoutProps } from "./model";
import { canvasBoundingRectForObject, canvasOffsetForObject, canvasSizeForObject, offsetFromParentForObject } from "./coordinates";
import { CanvasRect, unionRects } from "./geo";
import { v4 as uuidv4 } from "uuid";
import { replaceInline } from "../utils/replaceAllKeys";
import { processDocumentForConsistency } from "./consistency";
import { sizingFillsParent } from "../utils/moveUtils";

export interface ObjectLocation {
    parent: string | null;
    index: number; // may be -1 to indicate "at end"
}

export interface ObjectLocationWithDepth extends ObjectLocation {
    depth: number;
}

// Not including self
export function descendantIds(doc: Document, id: string): string[] {
    const ids: string[] = [];
    function traverse(objectId: string) {
        const object = doc.objects[objectId] as EditableFrame;
        if (!object) { return };
        if (!object.children) { return };
        for (const childId of object.children) {
            ids.push(childId);
            traverse(childId);
        }
    }
    traverse(id);
    return ids;
}

// Excluding root, highest first, includes self by default
export function ancestorIds(doc: Document, id: string): string[] {
    const ancestors = [id];
    while (true) {
        const obj = doc.objects[id];
        if (obj && obj.parent) {
            id = obj.parent;
            ancestors.splice(0, 0, id);
        } else {
            break;
        }
    }
    return ancestors;
}

function dropLast<T>(items: T[]): T[] {
    return items.slice(0, Math.max(0, items.length - 1));
}

export function nearestCommonParent(doc: Document, ids: string[]): string | null {
    if (ids.length === 0) return null;
    // Exclude self
    const ancestorLists: string[][] = ids.map(id => dropLast(ancestorIds(doc, id)));
    const shortestListLen = Math.min(...(ancestorLists.map(l => l.length)));

    // Search for last index where they all match:
    for (let i = shortestListLen - 1; i >= 0; i--) {
        const allMatch = new Set(ancestorLists.map(l => l[i])).size === 1;
        if (allMatch) {
            return ancestorLists[0][i];
        }
    }
    return null;
}

const STRICT = true;
function throwIfStrict(condition: boolean, message: string) {
    if (STRICT && condition) {
        throw new Error(message);
    }
}

export function addObject(doc: Document, object: BaseObject, location: ObjectLocation) {
    let parentArray = null;
    if (location.parent) {
        const parent = doc.objects[location.parent] as EditableFrame;
        if (!parent.children) {
            parent.children = [];
        }
        parentArray = parent.children;
    } else {
        parentArray = doc.rootIds;
    }
    if (!parentArray) { 
        throwIfStrict(true, "Parent array not found");
        return
    };
    if (location.index === -1) {
        parentArray.push(object.id);
    } else {
        parentArray.splice(location.index, 0, object.id);
    }
    doc.objects[object.id] = {...object, parent: location.parent || undefined};
}

export function locationOfObject(doc: Document, objectId: string): ObjectLocation | null {
    const obj = doc.objects[objectId];
    if (!obj) { 
        // throwIfStrict(true, "Object not found when searching for location");
        return null;
     };
    if (!obj.parent) {
        if (!doc.rootIds.includes(objectId)) {
            // throwIfStrict(true, "Object not found in rootIds, but has no parent");
            return null;
        }
        return { parent: null, index: doc.rootIds.indexOf(objectId) };
    }
    const parent = doc.objects[obj.parent] as EditableFrame;
    if (!parent.children) { 
        throwIfStrict(true, "Parent has no children");
        return null;
     };
    const index = parent.children.indexOf(objectId);
    if (index === -1) { 
        throwIfStrict(true, "Parent.children does not contain object");
        return null;
     };
    return { parent: obj.parent, index };
}

export function removeObject(doc: Document, id: string) {
    const location = locationOfObject(doc, id);
    if (!location) { 
        throwIfStrict(true, "Object not found when removing");
        return
     };
    delete doc.objects[id];
    if (location.parent === null) {
        doc.rootIds.splice(location.index, 1);
        return;
    } else {
        const parent = doc.objects[location.parent] as EditableFrame;
        if (!parent.children) { return };
        parent.children.splice(location.index, 1);
    }
}

interface RemovedObject {
    object: BaseObject;
    location: ObjectLocation;
    descendantObjects: BaseObject[];
}

export function deleteObjects(state: EditorState, ids: string[]): UndoableOperation {
    const allIdsToDelete = new Set<string>(ids); // including descendants
    // Iterate thru each id and remove its descendants
    ids.forEach(id => {
        descendantIds(state.document, id).forEach(descendantId => allIdsToDelete.delete(descendantId));
    });
    
    // If we delete the object we are currently editing, we should stop editing it.
    // Restore it when undoing
    const deletedEditingTextObjectId = state.editingTextObjectId && allIdsToDelete.has(state.editingTextObjectId) ? state.editingTextObjectId : undefined;

    // Capture an array of removed objects without removing yet, so we can restore them later
    const removed: RemovedObject[] = Array.from(allIdsToDelete).flatMap(id => {
        const location = locationOfObject(state.document, id);
        if (!location) { 
            throwIfStrict(true, "Object location not found when deleting");
            return []
         };
        const object = state.document.objects[id];
        if (!object) { 
            throwIfStrict(true, "Object not found when deleting");
            return []
         };
        const descendantObjects = descendantIds(state.document, id).map(id => state.document.objects[id]);
        return [{ object, location, descendantObjects }];
    });

    // Sort removed array by index (highest indices at front) to avoid index conflicts when re-adding
    removed.sort((a, b) => b.location.index - a.location.index);

    const oldSelection = state.selectedObjects;

    return {
        do: (state) => {
            ids.forEach(id => removeObject(state.document, id));
            if (deletedEditingTextObjectId) {
                state.editingTextObjectId = undefined;
            }
            state.selectedObjects = new Set<string>(Array.from(state.selectedObjects).filter(id => !allIdsToDelete.has(id)));
        },
        undo: (state) => {
            removed.forEach(({ object, location, descendantObjects }) => {
                addObject(state.document, object, location);
                // Re-add descendants
                for (const descendant of descendantObjects) {
                    state.document.objects[descendant.id] = descendant;
                }
            });
            if (deletedEditingTextObjectId) {
                state.editingTextObjectId = deletedEditingTextObjectId;
            }
            state.selectedObjects = oldSelection;
        }
    }
}

// If null, return root id array
export function getChildrenArray(objectId: string | null, doc: Document): string[] | null {
    if (!objectId) {
        return doc.rootIds;
    }
    const obj = doc.objects[objectId];
    if (!obj) { return null }
    if (supportsChildren(obj)) {
        return childrenOf(obj);
    }
    // if (obj.type === 'editableFrame') {
    //     const frame = obj as EditableFrame;
    //     if (frame.children === undefined) {
    //         frame.children = [];
    //     }
    //     return frame.children;
    // }
    return null;
}

// Does not remove object from document, just its parent
export function removeFromParent(doc: Document, locationOrId: ObjectLocation | string) {
    let location: ObjectLocation;
    if (typeof locationOrId === 'string') {
        const loc = locationOfObject(doc, locationOrId)
        if (!loc) { return }
        location = loc;
    } else {
        location = locationOrId;
    }
    const children = getChildrenArray(location.parent, doc);
    if (children && children.length > 0) {
        let idx = location.index;
        if (idx < 0) idx += children.length;
        idx = Math.min(idx, children.length - 1);
        idx = Math.max(0, idx);
        children.splice(idx, 1);
    }
}

export function addToParent(doc: Document, id: string, location: ObjectLocation) {
    const children = getChildrenArray(location.parent, doc);
    if (!children) {
        throwIfStrict(true, "move to invalid location");
        return;
    }
    let idx = location.index;
    if (idx < 0) idx += children.length;
    idx = Math.min(idx, children.length);
    idx = Math.max(0, idx);
    children.splice(idx, 0, id);

    const obj = doc.objects[id];
    if (obj) {
        obj.parent = location.parent === null ? undefined : location.parent;
    }
}

export function indexPath(doc: Document, id: string): number[] {
    const path: number[] = [];
    let obj = doc.objects[id];
    while (obj && obj.parent) {
        const parent = doc.objects[obj.parent];
        if (!parent) break;
        const children = childrenOf(parent) || [];
        path.unshift(children.indexOf(obj.id));
        obj = parent;
    }
    return path;
}

export function sortObjectsByRenderOrder(ids: string[], doc: Document) {
    const indexPathsForIds: { [key: string]: number[] } = {};
    ids.forEach(id => {
        indexPathsForIds[id] = indexPath(doc, id);
    });
    ids.sort((a, b) => {
        const pathA = indexPathsForIds[a];
        const pathB = indexPathsForIds[b];
        for (let i = 0; i < Math.min(pathA.length, pathB.length); i++) {
            if (pathA[i] !== pathB[i]) {
                return pathA[i] - pathB[i];
            }
        }
        return pathA.length - pathB.length;
    });
}

export function groupObjectsUndoable(ids: string[], editor: Editor): UndoableOperation {
    const doc = editor.state.value.document;
    ids = [...ids];
    sortObjectsByRenderOrder(ids, doc);

    const canvasWrapper = editor.viewportRef;
    if (!canvasWrapper) {
        return EmptyUndoableOperation;
    }
    // Save layout and tree location
    const undoData: {[id: string]: {layout: LayoutProps | null, location: ObjectLocation}} = {};
    for (const id of ids) {
        const obj = doc.objects[id];
        if (!obj) continue;
        const loc = locationOfObject(doc, id);
        if (!loc) continue;
        undoData[id] = { layout: layoutOf(obj), location: loc };
    }
    const oldSelection = editor.state.value.selectedObjects;

    const parent = nearestCommonParent(doc, ids);
    const parentOffset = canvasOffsetForObject(parent, canvasWrapper);
    if (!parentOffset) {
        return EmptyUndoableOperation;
    }

    const bboxes: CanvasRect[] = [];
    for (const id of ids) {
        const obj = doc.objects[id];
        if (!obj) continue;
        const rect = canvasBoundingRectForObject(id, canvasWrapper, editor.state.value.canvasPos);
        if (rect) {
            bboxes.push(rect);
        }
    }

    if (bboxes.length === 0) {
        return EmptyUndoableOperation;
    }
    // This will be the frame for the new object
    const bbox = unionRects(bboxes)!;
    const parentIsAutoLayout = parent && doc.objects[parent] && !!layoutOf(doc.objects[parent])?.flexLayout;
    const group: EditableFrame = {
        id: uuidv4(),
        type: 'editableFrame',
        name: 'Group',
        children: [],
        stylingProps: {},
        layout: {
            position: parentIsAutoLayout ? {kind: 'inline'} : {kind: 'absolute', x: {anchor: 'leading', value: bbox.x - parentOffset.x, unit: 'pixels'}, y: {anchor: 'leading', value: bbox.y - parentOffset.y, unit: 'pixels'}},
            xSize: {kind: 'fixed', value: bbox.width, unit: 'pixels'},
            ySize: {kind: 'fixed', value: bbox.height, unit: 'pixels'}
        },
    }

    const newLayouts: {[id: string]: LayoutProps} = {};

    for (const id of ids) {
        const obj = doc.objects[id];
        if (!obj) continue;
        const layout = layoutOf(obj);
        if (!layout) continue;
        const offset = canvasOffsetForObject(id, canvasWrapper);
        if (!offset) continue;
        const size = canvasSizeForObject(id, canvasWrapper);
        if (!size) continue;

        const newLayout = {...layout};
        newLayout.position = { kind: 'absolute', x: { anchor: 'leading', value: offset.x - bbox.x, unit: 'pixels' }, y: { anchor: 'leading', value: offset.y - bbox.y, unit: 'pixels' }};
        // See if we need to reset sizing
        if (sizingFillsParent(layout.xSize)) {
            newLayout.xSize = { kind: 'fixed', value: size.x, unit: 'pixels' };
        }
        if (sizingFillsParent(layout.ySize)) {
            newLayout.ySize = { kind: 'fixed', value: size.y, unit: 'pixels' };
        }
        newLayouts[id] = newLayout;
    }

    // Apply
    return {
        do: (state) => {
            // Remove existing objects, then insert new group, then append children backwards
            // TODO: Insert the new group at the topmost index of its children within the parent
            addObject(state.document, group, {parent, index: -1});
            for (let i = ids.length - 1; i >= 0; i--) {
                removeFromParent(state.document, ids[i]);
                addToParent(state.document, ids[i], {parent: group.id, index: -1});
                // update layout
                const obj = state.document.objects[ids[i]];
                if (obj) {
                    const layout = layoutOf(obj);
                    const newLayout = newLayouts[ids[i]];
                    if (layout && newLayout) {
                        replaceInline(newLayout, layout);
                    }
                }
            }
            state.selectedObjects = new Set([group.id]);
            // console.log('new state');
            processDocumentForConsistency(state.document);
            console.log(state.document);
        },
        undo: (state) => {
            // remove group and re-insert children at original locations
            removeObject(state.document, group.id);
            for (const objId of ids) {
                const { layout: oldLayout, location: oldLocation } = undoData[objId];
                const obj = state.document.objects[objId];
                if (!obj) continue;
                addObject(state.document, obj, oldLocation);
                const layoutObj = layoutOf(obj);
                if (layoutObj && oldLayout) {
                    replaceInline(oldLayout, layoutObj);
                }
            }
            state.selectedObjects = oldSelection;
        }
    }
}

// export function updateLayoutToMakePositionFixed(layout: LayoutProps, doc: Document, objectId: string, canvasWrapper: React.RefObject<HTMLDivElement>) {
//     const offset = offsetFromParentForObject(objectId, doc, canvasWrapper);
//     const size = canvasSizeForObject(objectId, canvasWrapper);
//     const obj = doc.objects[objectId];
//     if (!obj || !offset || !size) return;
//     layout.position = { kind: 'absolute', x: { anchor: 'leading', value: offset.x, unit: 'pixels' }, y: { anchor: 'leading', value: offset.y, unit: 'pixels' }};
//     if (!layout.xSize || layout.xSize.kind !== 'fixed') {
//         layout.xSize = { kind: 'fixed', value: size.x, unit: 'pixels' };
//     }
//     if (!layout.ySize || layout.ySize.kind !== 'fixed') {
//         layout.ySize = { kind: 'fixed', value: size.y, unit: 'pixels' };
//     }
// }
