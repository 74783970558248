export const DEFAULT_HTML_EMBED_CHECKERBOARD = `
<html>
  <head>
    <style>
      body, html {
        height: 100%;
        margin: 0;
      }
      .checkerboard {
        width: 100%;
        height: 100%;
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><rect width="20" height="20" fill="black"/><rect y="20" width="20" height="20" fill="white"/><rect x="20" width="20" height="20" fill="white"/><rect x="20" y="20" width="20" height="20" fill="black"/></svg>');
      }
    </style>
  </head>
  <body>
    <div class="checkerboard"></div>
  </body>
</html>
`;
