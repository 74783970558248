import ContentEditable from "react-contenteditable";
import { useEditor, useEditorDisplayState } from "../../data/editor";
import { DEFAULT_TEXT, EditableText } from "../../data/model";
import { useEffect, useRef } from "react";
import { deleteObjects } from "../../data/operations";

export default function EditableTextView({object}: {object: EditableText}) {
    const isEditing = useEditorDisplayState(state => state.editingTextObjectId === object.id);
    const editor = useEditor();
    if (isEditing) {
        const onChange = (newHTML: string) => {
            editor.modifyUndoable(state => {
                const oldText = (state.document.objects[object.id] as EditableText).text;
                return {
                    gestureId: 'edit_text:' + object.id,
                    do: state => {
                        // TODO: Sanitize this HTML before storing it
                        (state.document.objects[object.id] as EditableText).text = newHTML;
                        // state.editingTextObjectId = undefined;
                    },
                    undo: state => {
                        (state.document.objects[object.id] as EditableText).text = oldText;
                    }
                }
            });
        };

        const done = () => {
            editor.modify(state => {
                state.editingTextObjectId = undefined;
            });
        }
        // TODO: Delete empty text objects when done editing

        // const wantsDelete = () => {
        //     editor.modifyUndoable(state => {
        //         return deleteObjects(state, [object.id]); // This handles setting/restoring focus
        //     });
        // }
        // console.log("editijng")
        return <TextViewContent_Editing object={object} onChange={onChange} onDone={done} />;
    } else {
        // console.log("not editijng")
        return <div style={{cursor: 'default', textAlign: object.alignment, flexGrow: 1, alignSelf: 'stretch'}} dangerouslySetInnerHTML={{__html: object.text}} />;
    }
}

interface TextViewContent_EditingProps {
    object: EditableText;
    onChange: (newHTML: string) => void;
    onDone: () => void;
    // wantsDelete: () => void;
}
// Focus on appear; call done on blur
function TextViewContent_Editing({object, onChange, onDone}: TextViewContent_EditingProps) {
    const ref = useRef<HTMLDivElement>(null);

    function save() {
        if (!ref.current) {
            return;
        }
        const html = ref.current!.innerHTML;
        // const empty = ref.current!.innerText.trim().length === 0;
        onChange(html);
        // if (empty) {
        //     onDone();
        // } else {
        //     done(html);
        // }
    }

    useEffect(() => {
        ref.current!.focus();
        if (ref.current) {
            if (object.text === DEFAULT_TEXT) {
                selectAllText(ref.current);
            } else {
                moveCursorToEnd(ref.current);
            }
        }
    }, []);

    return <ContentEditable
        className="bypassCanvasClicks"
        innerRef={ref}
        html={object.text}
        onChange={() => { save() }}
        style={{backgroundColor: 'rgba(255,255,255,0.5)', boxShadow: '0 1 3px rgba(0,0,0,0.1)', flexGrow: 1, alignSelf: 'stretch', textAlign: object.alignment}}
        onKeyDown={(e) => {
            if (e.key === 'Escape') {
                onDone();
            }
            e.stopPropagation();
        }}
        onBlur={() => {
            onDone();
        }} />;
}

function moveCursorToEnd(el: HTMLElement) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
}

function selectAllText(el: HTMLElement) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
}
