import { Editor } from "../data/editor";
import { rectsIntersect, ViewportPoint, ViewportRect } from "../data/geo";
import { childrenOf } from "../data/model";
import { ResizeHandle } from "./selectionFrameHandles";
import { boundingRectForObjectId } from "./viewHelpers";

// Returns the offset to use for snapping, and the viewport-coord values for snap guides.
// Offset will be zero if no snaps occurred.
export function snapViewportRect(rect: ViewportRect | null, parentId: string | undefined, editor: Editor, ignoreObjectIds: string[]): { snapOffset: ViewportPoint, xSnapGuide?: number, ySnapGuide?: number } {
    if (!rect) return { snapOffset: { x: 0, y: 0, space: 'viewport' } };
    const guides = computeSnapGuides(parentId, ignoreObjectIds, editor);

    const hooks: {x: number[], y: number[]} = { 
        x: [rect.x, rect.x + rect.width / 2, rect.x + rect.width],
        y: [rect.y, rect.y + rect.height / 2, rect.y + rect.height]
    };

    const offset: ViewportPoint = { x: 0, y: 0, space: 'viewport' };
    let xSnapGuide: number | undefined = undefined;
    let ySnapGuide: number | undefined = undefined;

    const xSnap = closestSnapGuide(hooks.x, guides.x);
    if (xSnap !== undefined) {
        offset.x = xSnap.delta;
        xSnapGuide = xSnap.guide.value;
    }

    const ySnap = closestSnapGuide(hooks.y, guides.y);
    if (ySnap !== undefined) {
        offset.y = ySnap.delta;
        ySnapGuide = ySnap.guide.value;
    }

    return { snapOffset: offset, xSnapGuide, ySnapGuide };
}

function snapViewportPoint(point: ViewportPoint, parentId: string | undefined, editor: Editor, ignoreObjectIds: string[], allowX: boolean, allowY: boolean): { snapOffset: ViewportPoint, xSnapGuide?: number, ySnapGuide?: number } {
    const rect: ViewportRect = { x: point.x, y: point.y, width: 0, height: 0, space: 'viewport' };
    const snapped = snapViewportRect(rect, parentId, editor, ignoreObjectIds);
    if (!allowX) {
        snapped.snapOffset.x = 0;
        snapped.xSnapGuide = undefined;
    }
    if (!allowY) {
        snapped.snapOffset.y = 0;
        snapped.ySnapGuide = undefined;
    }
    return snapped;
}

// relative to an axis
export interface SnapGuide {
    value: number; // in viewport coords
}

export const SNAP_GUIDE_SNAP_THRESHOLD = 3;

/*
Compute snap guides using:
- parent bounds and center
- sibling bounds and center
*/
export function computeSnapGuides(parentId: string | undefined, ignoreSiblingIds: string[], editor: Editor): { x: SnapGuide[], y: SnapGuide[] } {
    const guides: { x: SnapGuide[], y: SnapGuide[] } = { x: [], y: [] };
    if (!editor.viewportRef || !editor.viewportRef.current) return guides;
    const editorState = editor.state.value;

    const parentInViewportCoords = parentId ? boundingRectForObjectId(parentId, editor.viewportRef) : undefined;
    const parent = parentId ? editorState.document.objects[parentId] : undefined;
    const viewportBounds = editor.viewportBounds(); // only snap to siblings in the viewport

    if (parentInViewportCoords) {
        guides.x.push({ value: parentInViewportCoords.x });
        guides.x.push({ value: parentInViewportCoords.x + parentInViewportCoords.width / 2 });
        guides.x.push({ value: parentInViewportCoords.x + parentInViewportCoords.width });
        guides.y.push({ value: parentInViewportCoords.y });
        guides.y.push({ value: parentInViewportCoords.y + parentInViewportCoords.height / 2 });
        guides.y.push({ value: parentInViewportCoords.y + parentInViewportCoords.height });
    }

    const siblings = parent ? childrenOf(parent) : editorState.document.rootIds;
    for (const siblingId of siblings.filter(id => !ignoreSiblingIds.includes(id))) {
        const siblingInViewportCoords = boundingRectForObjectId(siblingId, editor.viewportRef);
        if (siblingInViewportCoords && rectsIntersect(siblingInViewportCoords, viewportBounds)) {
            guides.x.push({ value: siblingInViewportCoords.x });
            guides.x.push({ value: siblingInViewportCoords.x + siblingInViewportCoords.width / 2 });
            guides.x.push({ value: siblingInViewportCoords.x + siblingInViewportCoords.width });
            guides.y.push({ value: siblingInViewportCoords.y });
            guides.y.push({ value: siblingInViewportCoords.y + siblingInViewportCoords.height / 2 });
            guides.y.push({ value: siblingInViewportCoords.y + siblingInViewportCoords.height });
        }
    }

    return guides;
}

function closestSnapGuide(hooks: number[], guides: SnapGuide[]): {guide: SnapGuide, hook: number, delta: number} | undefined {
    let closest: {guide: SnapGuide, hook: number, distance: number} | undefined = undefined;
    for (const hook of hooks) {
        for (const guide of guides) {
            const distance = Math.abs(hook - guide.value);
            if (distance < SNAP_GUIDE_SNAP_THRESHOLD) {
                if (!closest || distance < closest.distance) {
                    closest = { guide, hook, distance };
                }
            }
        }
    }
    if (closest) {
        return { guide: closest.guide, hook: closest.hook, delta: closest.guide.value - closest.hook };
    }
    return undefined;
}

// MARK: - For resizing
export function adjustDragDeltaUsingSnapping(
    draggingObjectIds: string[],
    parentId: string | undefined,
    dragDelta: ViewportPoint, 
    initialBoundingBox: ViewportRect, 
    handle: ResizeHandle, 
    editor: Editor): {snapOffset: ViewportPoint, xSnapGuide?: number, ySnapGuide?: number} {
    const cursor = computeCursorViewportPosition(dragDelta, initialBoundingBox, handle);
    const { snapOffset, xSnapGuide, ySnapGuide } = snapViewportPoint(cursor, parentId, editor, draggingObjectIds, handle.x !== undefined, handle.y !== undefined);
    return { snapOffset, xSnapGuide, ySnapGuide };
}

// Compute the position of the cursor in the viewport space, for snapping
function computeCursorViewportPosition(drag: ViewportPoint, initial: ViewportRect, handle: ResizeHandle): ViewportPoint {
    const cursor = { ...drag };
    if (handle.x) {
        switch (handle.x) {
            case 'leading':
                cursor.x += initial.x;
                break;
            case 'trailing':
                cursor.x += initial.x + initial.width;
                break;
        }
    }
    if (handle.y) {
        switch (handle.y) {
            case 'leading':
                cursor.y += initial.y;
                break;
            case 'trailing':
                cursor.y += initial.y + initial.height;
                break;
        }
    }
    return cursor;
}
