import OpenAI from "openai";
import { ObjectParam } from "../model";
import { ChatCompletionMessage, ChatCompletionMessageParam, ChatCompletionTool, FunctionDefinition } from "openai/resources";
import { CODEGEN_TEMPLATE } from "./codegenTemplates";
import { OPENROUTER_KEY } from "../secrets";
import { URLMap } from "./urlMap";
import { Editor, EditorContext } from "../editor";
import { DocumentRenderContext, ObjectView } from "../../ui/objectViews/DocumentView";
import pretty from "pretty";
import { renderToString } from "react-dom/server";

export interface GeneratedCode {
    head: string;
    js: string;
    css: string;
}

export type CodeChatMessage = CodeChatUserMessage | CodeChatAssistantMessage;

export interface CodeGenParams {
    programDescription: string;
    urlMap: URLMap;
    thread: CodeChatMessage[];
    lastGeneratedCode?: GeneratedCode;
}

interface CodeChatUserMessage {
    kind: 'user'
    text: string; // May include @Mentions
}

interface CodeChatAssistantMessage {
    kind: 'assistant'
    text: string;
    raw: ChatCompletionMessage
    code?: GeneratedCode;
}

interface CodeGenInputs {
    frameAsString?: string;
    referenceFrames?: {[id: string]: string};
    programDescription?: string;
    params: ObjectParam[];
    urlMap: URLMap;

    thread: CodeChatMessage[]; // First user message is generated from the program description, so the first thread item is an ASSISTANT message
}

export async function codegen2(inputs: CodeGenInputs): Promise<CodeChatAssistantMessage> {
    const systemLines: string[] = [];
    systemLines.push(`
    As an expert web developer, React, JS and HTML expert, I'd like you to write some code that will run in an iframe.
    Rather than writing a whole document, you will fill in a template. Respond with the code that should be slotted into the template to make it functional.

    # Environment
    Here is the template you will write code within:
    \`
    ${CODEGEN_TEMPLATE}
    \`

    # Guidelines
    - Your code should be standalone and self-contained.
    - Import only well-known libraries like React, ThreeJS, or OpenAI, but avoid dependencies if not necessary.
    - You don't need to use React, but it's recommended for complex UIs. (It's not recommended if you can just use a single canvas.)
    - Write all the code necessary; do not omit code or leave TODOs. It must be valid and runnable ES6 / JSX.
    - Fill the viewport unless there's a good reason not to. Use a resize observer for things like canvas elements. Respect devicePixelRatio.
    - Use a transparent BG unless there's a good reason not to.

    # Examples
    Here's an example of a simple React component that we might slot in here:
    <<SCRIPT>>
    function Hello() { return <h1>Im React</h1>; }

    ReactDOM.createRoot(document.getElementById("root-box")).render(
        <Hello />
    );
    <</SCRIPT>>
    `);

    systemLines.push('# Other relevant importable libs');
    systemLines.push('Import these if you need them:');
    systemLines.push('**ThreeJS**: https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js');
    systemLines.push(`
    **OpenAI API**: https://unpkg.com/openai@4.38.3/index.mjs
    <example>
        const client = new OpenAI({
            apiKey: 'YOUR_KEY', 
            dangerouslyAllowBrowser: true, 
        });
        const chatParams = {
        messages: [
            { role: 'user', content: 'My prompt...' }
        ],
        model: 'gpt-4o-mini'
      };
      const response = await client.chat.completions.create(chatParams).choices[0].message.content;
      </example>
    `);

    const nonSignalParams = inputs.params.filter(p => p.kind.kind !== 'signal');
    const signalParams = inputs.params.filter(p => p.kind.kind === 'signal');

    if (nonSignalParams.length > 0) {
        systemLines.push(`
        # Parameters
        External parameters will be passed to your iframe using window.postMessage.
        You must observe all these parameters and use them in your UI. (Your plan should discuss this.)
        You should post a 'requestInitialParams' message to the parent window to get the initial parameters,
        then continue to listen and react to changes. For example:

        <<SCRIPT>>
        function App() {
            const [params, setParams] = React.useState({});
            React.useEffect(() => {
                window.addEventListener('message', event => {
                    if (event.data.kind === 'paramsChanged') {
                        setParams(old => ({...old, ...event.data.params}));
                    }
                });
                window.parent.postMessage({ kind: 'requestInitialParams' }, '*');
            }, []);
            // Use your params below
            return <h1>Params: {JSON.stringify(params)}</h1>;
        }
        ReactDOM.createRoot(document.getElementById("app-root")).render(
            <App />
        );
        <</SCRIPT>>

        `)
    }

    if (signalParams.length > 0) {
        // TODO
    }


    if (nonSignalParams.length > 0) {
        systemLines.push('# Parameters');
        systemLines.push('Here are the params that may be passed, and their types. Consider this the type of the `event.data.params` field on the `paramsChanged` messages.');
        systemLines.push('`\n{');
        for (const param of nonSignalParams) {
            systemLines.push(`  ${typescriptDefForParam(param)}`);
        }
        systemLines.push('}\n`');
    }
    if (signalParams.length > 0) {
        systemLines.push('# Signals');
        systemLines.push('Here are the signals that you may call, and their types.');
        systemLines.push('`\n{');
        for (const param of signalParams) {
            systemLines.push(`  ${typescriptDefForParam(param)}`);
        }
        systemLines.push('}\n`');
    }

    if (inputs.referenceFrames && Object.keys(inputs.referenceFrames).length > 0) {
        systemLines.push(`
            # Reference Frames
            Here are some static HTML documents that have been used as references in the user's instruction.
            You can copy snippets (or entire structures) of this reference code into your output if it's relevant.
            `);
        for (const [id, frame] of Object.entries(inputs.referenceFrames)) {
            systemLines.push(`## Reference ID ${id}`);
            systemLines.push(frame);
        }
    }

    systemLines.push(`
    # Response Format
    Write your code within these tags, which will be slotted into the template provided.
    First, write a plan within <<PLAN>> tags to explain what you're going to do.
    Your script should set up the app and any event listeners, and should be self-contained. (You can use JSX if using React.)
    It may modify the DOM.
    The HEAD section may be used to add any additional imports you used.
    The CSS section may be used to add any additional styles you need. Remember to make sure you make your UI fill the viewport.
    Write each part in the appropriate section of the template within tags. 
    Just write the tags and the code within them; don't respond with the whole template.
    
    This is what your response might look like:

    <<PLAN>>
    This looks like a Todo list app. I'll adapt the provided HTML to use React, and add state management to handle adding and removing items.
    I'll store data in local state, and persist it to/from localStorage.
    <</PLAN>>

    <<SCRIPT>>
    function App()...
    <</SCRIPT>>

    <<CSS>>
    h1, h2 {...
    <</CSS>>

    <<HEAD>>
    <script src=...
    <</HEAD>>

    Remember that each item here should be formatted such that it can be slotted directly into the corresponding part of the template.
    `);

    const firstUserMessageLines: string[] = [];

    if (inputs.frameAsString) {
        firstUserMessageLines.push(`
        # Task
        I'd like you to code an interactive webpage.
        You'll take my static HTML and "make it real" by transforming it into a functional, high-quality React app, without changing its visual appearance or layout.

        Pay attention to clues in the HTML, labels, and data-comment attrs to infer functionality.
        Keep my layout and styling the same, but make it interactive. Copy the styling and layout; only modify it if asked or to support interactivity. Otherwise copy all styling attributes as-is.
        You may invent new UI stylings if necessary to handle edge cases, BUT you should not modify the layout or appearance of my HTML.

        I want it to look the same way it looks now, but interactive.
        You may replace plain divs with richer components (e.g. textareas, canvas) if you think that's the intent.
        If the app needs data persistence, use local storage.
        Begin your code with a comment explaining what this code will DO (based on user's description and your assumptions from the structure) and how you will do it.
        `);

        firstUserMessageLines.push(`
            <static html>
            ${inputs.frameAsString}
            </static html>
        `);
    }

    if (inputs.programDescription) {
        firstUserMessageLines.push(`
        # Description of functionality
        ${inputs.programDescription}
        `);
    }

    if (firstUserMessageLines.length === 0) {
        firstUserMessageLines.push(`[No task description provided]`);
    }

    const messages: ChatCompletionMessageParam[] = [
        { role: 'system', content: systemLines.join('\n') },
        { role: 'user', content: firstUserMessageLines.join('\n') }
    ];
    for (const threadItem of inputs.thread) {
        switch (threadItem.kind) {
            case 'user':
                messages.push({ role: 'user', content: threadItem.text });
                break;
            case 'assistant':
                messages.push(threadItem.raw);
                break;
        }
    }

    console.log('PROMPT:\n', messages);

    const client = new OpenAI({
        apiKey: OPENROUTER_KEY(), 
        dangerouslyAllowBrowser: true, 
        baseURL: 'https://openrouter.ai/api/v1'
    });
    const respMsg = (await client.chat.completions.create({ messages, model: 'openai/gpt-4o', temperature: 0 })).choices[0].message;
    console.log('RESPONSE:\n', respMsg);
    // Code may be undefined, if the assistant just wrote text
    const code = extractGeneratedCodeFromResponse(respMsg.content as string, inputs.urlMap);
    if (code) {
        console.log('Script:\n', code.js);
        console.log('Head:\n', code.head);
        console.log('CSS:\n', code.css);
    }
    // TODO: if assistant writes some fields but not all, we should return the original code for the fields it didn't write
    return {
        kind: 'assistant',
        text: (code ? "" : respMsg.content) || '', // only show the assistant's response if it did NOT update the code
        raw: respMsg,
        code
    };
}

function extractGeneratedCodeFromResponse(text: string, urlMap: URLMap): GeneratedCode | undefined {
    const expanded = expandURLs(text, urlMap);
    const gen = {
        head: textBetweenTags('<<HEAD>>', '<</HEAD>>', expanded) || '',
        js: textBetweenTags('<<SCRIPT>>', '<</SCRIPT>>', expanded) || '',
        css: textBetweenTags('<<CSS>>', '<</CSS>>', expanded) || '',
    };
    // If all are empty, return undefined
    if (gen.head === '' && gen.js === '' && gen.css === '') {
        return undefined;
    }
    return gen;
}

// HELPERS

function expandURLs(text: string, urlMap: URLMap): string {
    for (const [short, long] of Object.entries(urlMap.shortToLong)) {
        text = text.replace(short, long);
    }
    return text;
}

function textBetweenTags(start: string, end: string, text: string): string | undefined {
    const startIndex = text.indexOf(start);
    if (startIndex === -1) {
        return '';
    }
    const endIndex = text.indexOf(end, startIndex);
    if (endIndex === -1) {
        return '';
    }
    return text.substring(startIndex + start.length, endIndex);
}


export function renderObjectToString(objectId: string, editor: Editor): string {
    const str = renderToString((
        <DocumentRenderContext.Provider value={{ renderingToString: true }}>
            <EditorContext.Provider value={editor}>
                <ObjectView id={objectId} fillParent={true} />
            </EditorContext.Provider>
        </DocumentRenderContext.Provider>
    ));
    return pretty(str);
}

function typescriptDefForParam(param: ObjectParam): string {
    switch (param.kind.kind) {
        case 'string':
            return `'${param.name}': string`;
        case 'number':
            return `'${param.name}': number`;
        case 'boolean':
            return `'${param.name}': boolean`;
        case 'color':
            return `'${param.name}': {r: number, g: number, b: number, a: number} // 0..255 for r,g,b; 0..1 for a`;
        case 'dict':
            return `any` // TODO
        case 'signal': {
            if (!param.kind.signalValue) {
                return `${param.name}: (any) => void`; // oops!
            }
            switch (param.kind.signalValue.kind) {
                case 'string':
                    return `'${param.name}': (string) => void`;
                case 'number':
                    return `'${param.name}': (number) => void`;
                case 'boolean':
                    return `'${param.name}': (boolean) => void`;
                case 'color':
                    return `'${param.name}': ({r: number, g: number, b: number, a: number}) => void`;
                    default: return `${param.name}: any`; // oops!
            }
        }
    }
}
