export interface Point {
    x: number;
    y: number;
}

// Viewport points have x=0, y=0 at the top-left corner of the canvas within the viewport. If there's a 200px sidebar and then the canvas, the viewport coordinate space will start after that.
// Canvas is the basic HTML coordinate space before scroll/zoom
// Client is the DOM `client` coordinate space -- the viewport of the browser window.
export type CoordinateSpace = 'canvas' | 'viewport' | 'client' | undefined;
export interface TypedPoint<T extends CoordinateSpace> extends Point {
    space: T;
}

export type CanvasPoint = TypedPoint<'canvas'>;
export type ViewportPoint = TypedPoint<'viewport'>;
export type ClientPoint = TypedPoint<'client'>;
export type AnyPoint = TypedPoint<undefined>;

export interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface TypedRect<T extends CoordinateSpace> extends Rect {
    space: T;
}

export type CanvasRect = TypedRect<'canvas'>;
export type ViewportRect = TypedRect<'viewport'>;
export type ClientRect = TypedRect<'client'>;
export type AnyRect = TypedRect<undefined>;

export function distance<P extends Point>(a: P, b: P): number {
    return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
}

export function subtract<P extends Point>(a: P, b: P): P {
    return { ...a, x: a.x - b.x, y: a.y - b.y };
}

export function centerOfRect<T extends CoordinateSpace>(rect: TypedRect<T>): TypedPoint<T> {
    return {
        space: rect.space,
        x: rect.x + rect.width/2,
        y: rect.y + rect.height/2
    }
}

export function boundingRectForPoints<T extends CoordinateSpace>(points: TypedPoint<T>[]): TypedRect<T> | null {
    const pointsToRects: TypedRect<T>[] = points.map(p => ({...p, width: 0, height: 0}));
    return unionRects(pointsToRects);
}

export function rectFromCorners<S extends CoordinateSpace>(a: TypedPoint<S>, b: TypedPoint<S>, lockedAspect: number | undefined = undefined): TypedRect<S> {
    let width = Math.abs(a.x - b.x);
    let height = Math.abs(a.y - b.y);
    if (lockedAspect && lockedAspect > 0) {
        if (width > height) {
            height = width / lockedAspect;
        } else {
            width = height * lockedAspect;
        }
    }
    return {
        x: Math.min(a.x, b.x),
        y: Math.min(a.y, b.y),
        width,
        height,
        space: a.space
    };
}

export interface InfiniteCanvasPosition {
    zoom: number;
    centerX: number;
    centerY: number;
}

export function rectFromClient(bounds: DOMRect): ClientRect {
    return {
        x: bounds.left,
        y: bounds.top,
        width: bounds.width,
        height: bounds.height,
        space: 'client'
    };
}

export function rectContainsPoint<S extends CoordinateSpace>(rect: TypedRect<S>, point: TypedPoint<S>): boolean {
    return point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height;
}

export function unionRects<S extends CoordinateSpace>(rects: TypedRect<S>[]): TypedRect<S> | null {
    if (rects.length === 0) {
        return null;
    }
    let x = rects[0].x;
    let y = rects[0].y;
    let right = rects[0].x + rects[0].width;
    let bottom = rects[0].y + rects[0].height;
    for (let i = 1; i < rects.length; i++) {
        x = Math.min(x, rects[i].x);
        y = Math.min(y, rects[i].y);
        right = Math.max(right, rects[i].x + rects[i].width);
        bottom = Math.max(bottom, rects[i].y + rects[i].height);
    }
    return {
        x,
        y,
        width: right - x,
        height: bottom - y,
        space: rects[0].space
    };
}

export function rectsIntersect<S extends CoordinateSpace>(a: TypedRect<S>, b: TypedRect<S>): boolean {
    return a.x < b.x + b.width && a.x + a.width > b.x && a.y < b.y + b.height && a.y + a.height > b.y;
}

export function rectIntersection<S extends CoordinateSpace>(a: TypedRect<S>, b: TypedRect<S>): TypedRect<S> | null {
    if (!rectsIntersect(a, b)) {
        return null;
    }
    return {
        x: Math.max(a.x, b.x),
        y: Math.max(a.y, b.y),
        width: Math.min(a.x + a.width, b.x + b.width) - Math.max(a.x, b.x),
        height: Math.min(a.y + a.height, b.y + b.height) - Math.max(a.y, b.y),
        space: a.space
    };
}

export function rectArea<S extends CoordinateSpace>(rect: TypedRect<S> | null | undefined): number {
    if (!rect) {
        return 0;
    }
    return rect.width * rect.height;
}

export function boundingRectForAllSelectors(element: HTMLElement, selectors: string[], offsetX: number, offsetY: number): ViewportRect | null {
    const rects: ViewportRect[] = [];
    selectors.forEach(selector => {
        const elements = element.querySelectorAll(selector);
        elements.forEach(el => {
            const rect = rectFromClient(el.getBoundingClientRect());
            rect.x += offsetX;
            rect.y += offsetY;
            rects.push({...rect, space: 'viewport'});
        });
    });
    return unionRects(rects);
}

export const ZeroRect: Rect = { x: 0, y: 0, width: 0, height: 0 };
export function zeroRect<S extends CoordinateSpace>(space: S): TypedRect<S> {
    return { ...ZeroRect, space };
}
