
import { useCallback, useRef, useState } from "react";
import { TbCheck, TbCircleCheck, TbCircleCheckFilled } from "react-icons/tb";
import styled from "styled-components";

const ROW_HEIGHT = 22;
export const CONTROL_ROW_HEIGHT = 22;

export const SidebarSection = styled.div`
    margin: 8px;
    user-select: none;
    &:not(:first-child) {
        margin-top: 8px;
        padding-top: 12px;
        border-top: 1px solid rgba(0,0,0,0.07);
    }
    h6 {
        opacity: 0.7;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 2px !important;
        font-size: 12px;
        height: ${ROW_HEIGHT}px;
    }
    > *:not(:last-child) {
        margin-bottom: 8px;
    }
    label {
        font-weight: bold;
        font-size: 11px;
        opacity: 0.6;
    }
`;

interface SegmentedControlProps<T> {
    options: T[];
    selection?: T;
    onChange: (value: T) => void;
    viewForOption: (option: T) => React.ReactNode;
}

// A small roundrect with a border
export const SidebarPropContainer = styled.div<{disabled?: boolean}>`
    border-radius: 3px;
    background-color: #f3f3f3;
    border: 1px solid #EBE9E6;
    height: ${ROW_HEIGHT}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 4px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 11px;

    label {
        opacity: ${props => props.disabled ? 0.2 : 0.6};
        min-width: ${ROW_HEIGHT}px;
    }
`;

const SegmentedControlItem = styled.div<{selected: boolean}>`
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    background-color: ${props => props.selected ? 'white' : 'transparent'};
    border-radius: 3px;
    margin: 4px 0;
    flex-grow: 1;
    opacity: ${props => props.selected ? 1 : 0.6};

    &:hover {
        background-color: ${props => props.selected ? 'white' : 'rgba(0,0,0,0.05)'};
    }
`;

export function SidebarSegmentedControl<T>(props: SegmentedControlProps<T>) {
    const {options, selection, onChange, viewForOption} = props;
    return (
        <SidebarPropContainer>
            {options.map(option => {
                const selected = option === selection;
                return (
                    <SegmentedControlItem  selected={selected} key={`${option}`} onClick={() => onChange(option)}>
                        {viewForOption(option)}
                    </SegmentedControlItem>
                )
            })}
        </SidebarPropContainer>
    )
}

interface PropButtonWithPreviewProps {
    label: string;
    onClick: () => void;
    children: React.ReactNode;
}

export function PropButtonWithPreview(props: PropButtonWithPreviewProps) {
    return (
        <SidebarPropContainer onClick={props.onClick}>
            <label>{props.label}</label>
            <Spacer />
            {props.children}
        </SidebarPropContainer>
    )
}

interface PropButtonProps {
    label: string;
    disabled?: boolean;
    onClick: () => void;
}
export function PropButton(props: PropButtonProps) {
    return (
        <PropButtonDiv onClick={props.onClick} disabled={props.disabled ?? false}>
            <label>{props.label}</label>
        </PropButtonDiv>
    )
}

const PropButtonDiv = styled.div<{disabled: boolean}>`
    border-radius: 3px;
    border: 1px solid #EBE9E6;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    background-color: #18f;
    color: white;
    height: ${ROW_HEIGHT}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 4px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 11px;
    place-content: center;

    label {
        opacity: ${props => props.disabled ? 0.2 : 0.9};
        min-width: ${ROW_HEIGHT}px;
    }
`;

export const MultiPropRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: ${ROW_HEIGHT}px;
    & > *:not(:first-child) {
        margin-left: 8px;
    }
    & > * {
        flex-basis: 0;
        flex-grow: 1;
    }
`;

interface PropCheckboxProps {
    label: string;
    value?: boolean;
    onChange: (value: boolean) => void;
}

export function PropCheckbox(props: PropCheckboxProps) {
    return (
        <SidebarPropContainer onClick={() => props.onChange(!props.value)}>
            <label>{props.label}</label>
            <Spacer />
            <TbCheck style={{opacity: props.value ? 0.8 : 0.2, transform: 'scale(1.4)'}} />
        </SidebarPropContainer>
    )
}

export const Spacer = styled.div`
    flex: 1;
`;

interface NumberInputProps {
    label: string;
    value?: number;
    min?: number;
    max?: number;
    scrubScale?: number; // defaults to 1
    accessories?: React.ReactNode;
    onChange: (value: number) => void;
    width?: string;
}

function formatNumber(value: number): string {
    return (+(parseFloat(value.toFixed(2)))).toString();
}

export function NumberInput(props: NumberInputProps) {
    const {label, value, min, max, scrubScale, onChange, accessories, width} = props;

    const [focused, setFocused] = useState(false);
    const [text, setText] = useState(value === undefined ? '' : formatNumber(value));

    const inputRef = useRef<HTMLInputElement>(null);

    function commitText() {
        const floatVal = text === '' ? 0 : parseFloat(text);
        onChangeClamped(floatVal);
    }

    const onChangeClamped = useCallback((value: number) => {
        if (min !== undefined) {
            value = Math.max(value, min);
        }
        if (max !== undefined) {
            value = Math.min(value, max);
        }
        onChange(value);
    }, [min, max, onChange]);

    const onChangeText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    }, []);

    const onMouseDown = useCallback((e: React.MouseEvent) => {
        const scrubScale = props.scrubScale || 1;
        e.preventDefault();
        const startX = e.clientX;
        const startValue = value || 0;
        const onMouseMove = (e: MouseEvent) => {
            const delta = (e.clientX - startX) * scrubScale;
            onChangeClamped(startValue + delta);
        };
        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    }, [value, scrubScale, onChangeClamped]);

    // When enter or escape is pressed, commit the text
    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
            inputRef.current?.blur();
        }
    }, [inputRef]);

    const onBlur = useCallback(() => {
        setFocused(false);
        commitText();
    }, [commitText]);

    const onFocus = useCallback(() => {
        setFocused(true);
        setText(value === undefined ? '' : formatNumber(value));
    }, [value]);

    const textVal = focused ? text : (value === undefined ? '' : formatNumber(value));
    const cssVals: React.CSSProperties = {};
    if (width) {
        cssVals.width = width;
    }

    return (
        <SidebarPropContainer style={cssVals}>
            <label style={{cursor: 'ew-resize'}} onMouseDown={onMouseDown}>{label}</label>
            <InlineInput ref={inputRef} type="number" value={textVal} onChange={onChangeText} placeholder="0" onKeyDown={onKeyDown} onBlur={onBlur} onFocus={onFocus} />
            {accessories}
        </SidebarPropContainer>
    )
}

export const InlineInput = styled.input`
    font-weight: bold;
    font-size: 11px;
    border: none;
    background: none;
    flex-grow: 1;
    flex-basis: 0;
    text-align: right;
    width: 40px;
    &:focus {
        outline: none;
    }
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`;

export const AccessoryButton = styled.button<{selected?: boolean}>`
    background: none;
    border: none;
    cursor: pointer;
    opacity: ${props => props.selected ? 1 : 0.6};
    color: ${props => props.selected ? '#45f' : 'black'};
    position: relative;
    flex-grow: 0;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    svg {
        font-size: 16px;
        top: 2px;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 0.8;
    }
`;

export const SidebarMenu = styled.select`
    font-size: 11px;
    font-weight: bold;
    border: none;
    background: none;
    cursor: pointer;
    text-align: right;
    flex-grow: 1;
    &:focus {
        outline: none;
    }
`;

export const SidebarTextArea = styled.textarea`
    border-radius: 3px;
    border: 1px solid #EBE9E6;
    width: 100%;
    font-size: 11px;
    height: 4em;
    &:focus {
        outline: none;
    }
    margin-top: 4px;
`;

interface TwoColumnPropGroupProps {
    left: React.ReactNode;
    right: React.ReactNode;
}

const TwoColumnPropGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
`;

const Column = styled.div`
    flex-basis: 0;
    flex-grow: 1;
    width: 0;
    > *:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export function TwoColumnPropGroup(props: TwoColumnPropGroupProps) {
    return (
        <TwoColumnPropGroupContainer>
            <Column>{props.left}</Column>
            <Column>{props.right}</Column>
        </TwoColumnPropGroupContainer>
    )
}

interface DropdownButtonProps<T> {
    children: React.ReactNode;
    options: T[];
    labelForOption: (option: T) => string;
    onPick: (option: T) => void;
}

const DropdownButtonElement = styled.select`
    border-radius: 3px;
    border: 1px solid #EBE9E6;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    background-color: #18f;
    color: rgba(255,255,255,0.9);
    height: ${ROW_HEIGHT}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 4px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 11px;
    place-content: center;
    appearance: none;
`;

// Looks like a PropButton but opens a dropdown menu when clicked
export function DropdownButton<T>(props: DropdownButtonProps<T>) {
    return (
        <DropdownButtonElement value={-1} onChange={e => {
            const idx = parseInt(e.target.value);
            if (idx >= 0) {
                props.onPick(props.options[idx]);
            }
        }}>
            <option value={-1} disabled>{props.children}</option>
            {props.options.map((option, i) => (
                <option key={i} value={i}>{props.labelForOption(option)}</option>
            ))}
        </DropdownButtonElement>
    )
}

export const SidebarMiniSection = styled.div`
    border-radius: 6px;
    border: 1px solid #EBE9E6;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3px;
    gap: 3px;
`;

export const MissableButton = styled.button`
    background: none;
    border: none;
    cursor: default;
    font-weight: bold;
    font-size: 11px;
    color: #18f;
    &:focus {
        outline: none;
    }
    &:hover {
        opacity: 0.8;
    }
`;


export const ErrorLabel = styled.label`
    color: red;
    font-size: 11px;
    font-weight: bold;
    margin-top: 4px;
    display: block;
`;

// Use as root of popover content view
export const PopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background: white;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 240px;
    box-shadow: 0 20px 100px rgba(0,0,0,0.4);
`;

// Place within popover container
export const PopoverBackdrop = styled.div`
    content: '';
    position: fixed;
    top: -100vw;
    left: -100vh;
    width: 300vw;
    height: 300vh;
    z-index: -1;
`;
