
const colors = [
    'red', 'green', 'blue', 'yellow', 'purple', 'orange', 'pink', 'brown', 'black', 'white',
    'gray', 'cyan', 'magenta', 'lime', 'olive', 'maroon', 'navy', 'teal', 'silver', 'gold'
]
function randomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
}

// For visualizing repaints
export function useRandomColor() {
    return randomColor();
}
