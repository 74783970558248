import { BaseObject, childrenOf, Document, stylingOf } from "../model";

export interface URLMap {
    shortToLong: {[short: string]: string};
    longToShort: {[long: string]: string};
    hostCounts: {[host: string]: number};
}

export function emptyURLMap(): URLMap {
    return { shortToLong: {}, longToShort: {}, hostCounts: {} };
}

export function addToURLMap(map: URLMap, url: string): void {
    if (map.longToShort[url]) {
        return;
    }
    const host = new URL(url).host || 'localhost';
    const num = map.hostCounts[host] || 0;
    const short = `${host}/${num}`;
    map.shortToLong[short] = url;
    map.longToShort[url] = short;
    map.hostCounts[host] = num + 1;
}

export function getAllURLs(obj: BaseObject, doc: Document): string[] {
    const urls = new Set<string>();
    function traverse(obj: BaseObject) {
        const styling = stylingOf(obj);
        if (styling && styling.image && styling.image.signedUrl) {
            urls.add(styling.image.signedUrl);
        }
        const children = childrenOf(obj);
        for (const child of children) {
            const obj = doc.objects[child];
            if (obj) {
                traverse(obj);
            }
        }
    }
    traverse(obj);
    return Array.from(urls);
}
