
export function compactMap<T, U>(array: T[], mapper: (item: T) => U | undefined): U[] {
    const result: U[] = [];
    for (const item of array) {
        const mapped = mapper(item);
        if (mapped !== undefined) {
            result.push(mapped);
        }
    }
    return result;
}

export function mapArrayToDict<T, V>(array: T[], mapper: (item: T) => [string, V] | undefined): {[key: string]: V} {
    const result: {[key: string]: V} = {};
    for (const item of array) {
        const res = mapper(item);
        if (res) {
            const [key, val] = res;
            result[key] = val;
        }
    }
    return result;
}
