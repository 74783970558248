import React, { useState, useEffect, useRef } from 'react';
import { useAPIClient } from '../data/apiClient/apiClientHooks';
import { DocumentMetadata } from '../data/apiClient/apiClient';
import { Document } from '../data/model';
import { createDefaultDoc } from '../data/defaultModel';
import { v4 as uuidv4 } from "uuid";
import { currentBaseDomain, ParsedRoute, urlForParsedRoute } from '../routing';
import { newDoc } from '../data/apiClient/apiHelpers';

// const DocumentList: React.FC = () => {
//   const apiClient = useAPIClient();
//   const [documents, setDocuments] = useState<DocumentMetadata[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const subscription = apiClient.documentsForSignedInUser().subscribe(
//       (docs) => {
//         setDocuments(docs);
//         setIsLoading(false);
//       }
//     );

//     return () => subscription();
//   }, [apiClient]);

//   const createNewDocument = async () => {
//     try {
//       // Assuming we have a createDocument method in our APIClient
//       const docData: Document = createDefaultDoc(uuidv4());
//       await apiClient.insert(docData);
//       // Redirect to the new document
//       const route: ParsedRoute = {
//         kind: 'editDoc',
//         docId: docData.id,
//         baseDomain: currentBaseDomain()
//       };
//       window.location.href = urlForParsedRoute(route);
//     } catch (err) {
//       setError('Failed to create a new document. Please try again.');
//       console.error('Error creating new document:', err);
//     }
//   };

//   if (isLoading) return <div>Loading documents...</div>;
//   if (error) return <div>Error: {error}</div>;

//   const baseDomain = currentBaseDomain();

//   return (
//     <div>
//       <h2>Your Documents</h2>
//       <button onClick={createNewDocument}>Create New Document</button>
//       {documents.length === 0 ? (
//         <p>You don't have any documents yet. Create one to get started!</p>
//       ) : (
//         <ul>
//           {documents.map(doc => (
//             <li key={doc.id}>
//               <a href={urlForParsedRoute({ kind: 'editDoc', docId: doc.id, baseDomain })}>
//                 <span>{doc.name}</span>
//                 <span> - Last updated: {doc.lastModified.toLocaleString()}</span>
//               </a>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

function RedirectToLastUpdatedDocument() {
  // Redirects to the last updated document or creates new

  const didBegin = useRef(false);
  const apiClient = useAPIClient();

  useEffect(() => {
    if (didBegin.current) return;
    didBegin.current = true;
    apiClient.fetchDocumentsForSignedInUser().then((documents) => {
      if (documents.length === 0) {
        newDoc(apiClient);
      } else {
        const lastUpdatedDoc = documents.reduce((prev, current) => {
          return prev.lastModified > current.lastModified ? prev : current;
        });
        window.location.href = urlForParsedRoute({
          kind: 'editDoc',
          docId: lastUpdatedDoc.id,
          baseDomain: currentBaseDomain(),
        });
      }
    });
  }, []);

  return <div>One sec...</div>
}

function HomeScreen() {
    return (
        <RedirectToLastUpdatedDocument />
    );
}

export default HomeScreen;
