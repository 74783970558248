import { useEffect, useRef } from "react";
import { ObjectParam, ParamValue } from "../model";
import equal from "fast-deep-equal/es6";
import { GeneratedCode } from "./codegen2";
import { inflateCode } from "./codegenTemplates";
import { DEFAULT_HTML_EMBED_CHECKERBOARD } from "./checkerboardTemplate";

interface LiveCodeViewProps {
    code?: GeneratedCode;
    interactive: boolean;
    params: ObjectParam[];
}

export default function LiveCodeView(props: LiveCodeViewProps) {
    const { code, interactive, params } = props;
    const finalHTML = code ? inflateCode(code) : DEFAULT_HTML_EMBED_CHECKERBOARD;
    const ref = useRef<HTMLIFrameElement>(null);
    const latestParamsRef = useRef(params);
    latestParamsRef.current = params;
    const lastSentParamVals = useRef<{[key: string]: ParamValue}>({});
    console.log(finalHTML)

    // Only call from useEffect
    function sendChangedParamVals() {
        if (!ref.current) { return; }
        const frame = ref.current;
        const newParamVals: {[key: string]: ParamValue} = {};
        for (const param of latestParamsRef.current) {
            if (param.value !== undefined && !equal(lastSentParamVals.current[param.name],  param.value)) {
                newParamVals[param.name] = param.value;
            }
        }
        if (Object.keys(newParamVals).length > 0) {
            // console.log('Sending params', newParamVals);
            frame.contentWindow?.postMessage({ kind: 'paramsChanged', params: newParamVals }, '*');
            Object.assign(lastSentParamVals.current, newParamVals);
        }
    }

    useEffect(() => {
        if (!ref.current) { return; }
        const frame = ref.current;
        frame.srcdoc = finalHTML;

        // Register message handler
        const handler = (event: MessageEvent) => {
            const payload = extractCrossFramePayload(event);
            if (!payload) { return; }
            switch (payload.kind) {
                case 'requestInitialParams': {
                    // Send all params
                    lastSentParamVals.current = {};
                    sendChangedParamVals();
                    break;
                }
                case 'paramsChanged':
                    // No op (child should not send this message)
                    break;
                case 'signal': {
                    const p = payload as SendSignalMessage;
                    console.log('Received signal', p.name, p.value);
                    // TODO: Handle
                    break;
                }
            }
        };
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [finalHTML]);

    useEffect(() => {
        sendChangedParamVals();
    });

    return (
        <iframe sandbox="allow-scripts" srcDoc={finalHTML} style={{ pointerEvents: interactive ? 'auto' : 'none', width: '100%', height: '100%', border: 'none' }} ref={ref} />
    );
}

function extractCrossFramePayload(message: MessageEvent): CrossFrameMessage | undefined {
    if (message.data && typeof message.data === 'object' && 'kind' in message.data) {
        return message.data as CrossFrameMessage;
    }
    return undefined;
}

interface CrossFrameMessage {
    kind: string;
}

// Sent from child iframe
interface RequestInitialParamsMessage extends CrossFrameMessage {
    kind: 'requestInitialParams';
}

// Sent from parent to child
interface ParamsChangedMessage extends CrossFrameMessage {
    kind: 'paramsChanged';
    params: {[key: string]: ParamValue};
}

interface SendSignalMessage extends CrossFrameMessage {
    kind: 'signal';
    name: string;
    value: ParamValue;
}
