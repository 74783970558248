import { v4 as uuidv4 } from "uuid";
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import Observable from '../observable'
import { Document } from '../model'
import { APIClient, User, FileMetadata, DocumentMetadata, Unsubscribe } from './apiClient'
import { Point } from '../geo'
import { SHARED_SUPABASE_CLIENT } from './supabaseClient'
import { OFFLINE } from "../editor";
import { blobToDataURL } from "../../utils/image";

export class SupabaseAPIClient implements APIClient {
  private currentUser: Observable<User | null | undefined>
  private supabase: SupabaseClient

  constructor() {
    this.supabase = SHARED_SUPABASE_CLIENT;
    this.currentUser = new Observable<User | null | undefined>(undefined)
    this.initializeAuth()
  }

  private async initializeAuth() {
    const { data: { user } } = await this.supabase.auth.getUser()
    if (user) {
      this.currentUser.value = {
        id: user.id,
        name: user.user_metadata.full_name || 'Unknown'
      }
    } else {
      this.currentUser.value = null
    }

    this.supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        this.currentUser.value = {
          id: session.user.id,
          name: session.user.user_metadata.full_name || 'Unknown'
        }
      } else if (event === 'SIGNED_OUT') {
        this.currentUser.value = null
      }
    })
  }

  async awaitCurrentUser(): Promise<User | null | undefined> {
    const { data: { user } } = await this.supabase.auth.getUser()
    if (user) {
      return {
        id: user.id,
        name: user.user_metadata.full_name || 'Unknown'
      }
    } else {
      return null
    }
  }

  observerSignedInUser(callback: (user: User | null | undefined) => void): Unsubscribe {
    return this.currentUser.subscribe(callback)
  }

  observeDocument(id: string, callback: (doc: Document) => void): Unsubscribe {
    const fetchDocument = async () => {
      const currentUser = await this.awaitCurrentUser()
      if (!currentUser) {
        throw new Error('User must be signed in to create documents')
      }

      const { data, error } = await this.supabase
        .from('docs')
        .select('id, name, data, updated_at, creator_id')
        .eq('id', id)
        .single()

      if (error) {
        console.error('Error fetching document:', error)
        return
      }

      if (data) {
        callback({
          id: data.id,
          name: data.name,
          ...data.data,
          lastModified: new Date(data.updated_at)
        })
      }
    }

    fetchDocument()

    // Set up real-time subscription
    const subscription = this.supabase
      .channel(`doc:${id}`)
      .on('postgres_changes', { event: '*', schema: 'public', table: 'docs', filter: `id=eq.${id}` }, fetchDocument)
      .subscribe()

    return () => subscription.unsubscribe()
  }

  async insert(document: Document): Promise<void> {
    const currentUser = await this.awaitCurrentUser()
    if (!currentUser) {
      throw new Error('User must be signed in to create documents')
    }
    const userId = currentUser.id

    const { error } = await this.supabase
      .from('docs')
      .insert({
        id: document.id,
        name: document.name,
        data: { ...document },
        updated_at: new Date().toISOString(),
        creator_id: userId
      })

    if (error) {
      throw new Error(`Failed to insert document: ${error.message}`)
    }
  }

  async save(document: Document): Promise<void> {
    const currentUser = await this.awaitCurrentUser()
    if (!currentUser) {
      throw new Error('User must be signed in to create documents')
    }
    const userId = currentUser.id

    const { error } = await this.supabase
      .from('docs')
      .upsert({
        id: document.id,
        name: document.name,
        data: { ...document },
        updated_at: new Date().toISOString(),
        creator_id: userId
      })

    if (error) {
      throw new Error(`Failed to save document: ${error.message}`)
    }
  }

  async publish(document: Document): Promise<void> {
    const currentUser = await this.awaitCurrentUser()
    if (!currentUser) {
      throw new Error('User must be signed in to publish documents')
    }
    const userId = currentUser.id

    const { error } = await this.supabase
      .from('published_docs')
      .upsert({
        id: document.id,
        name: document.name,
        data: { ...document },
        updated_at: new Date().toISOString(),
        creator_id: userId
      })
    
    if (error) {
      throw new Error(`Failed to publish document: ${error.message}`)
    }
  }

  async fetchPublishedDoc(id: string): Promise<Document | null> {
    const { data, error } = await this.supabase
      .from('published_docs')
      .select('id, name, data, updated_at, creator_id')
      .eq('id', id)
      .single()

    if (error) {
      throw new Error(`Failed to fetch published document: ${error.message}`)
    }

    if (data) {
      return {
        id: data.id,
        name: data.name,
        ...data.data,
        lastModified: new Date(data.updated_at)
      }
    }

    return null
  }

  async fetchDocumentsForSignedInUser(): Promise<DocumentMetadata[]> {
    const currentUser = await this.awaitCurrentUser()
    if (!currentUser) {
      throw new Error('User must be signed in to create documents')
    }

    const { data, error } = await this.supabase
      .from('docs')
      .select('id, name, updated_at, creator_id')
      .eq('creator_id', currentUser.id)

    if (error) {
      throw new Error(`Failed to fetch documents: ${error.message}`)
    }

    return data.map(doc => ({
      id: doc.id,
      name: doc.name,
      lastModified: new Date(doc.updated_at),
      creator: currentUser
    }))
  }

  observeDocumentsForSignedInUser(callback: (docs: DocumentMetadata[]) => void): Unsubscribe {
    const fetchDocuments = async () => {
      const currentUser = await this.awaitCurrentUser()
      if (!currentUser) {
        throw new Error('User must be signed in to create documents')
      }
      if (!this.currentUser.value) {
        callback([])
        return
      }

      const { data, error } = await this.supabase
        .from('docs')
        .select('id, name, updated_at, creator_id')
        .eq('creator_id', currentUser.id)

      if (error) {
        console.error('Error fetching documents:', error)
        return
      }

      callback(data.map(doc => ({
        id: doc.id,
        name: doc.name,
        lastModified: new Date(doc.updated_at),
        creator: currentUser
      }))
      )
    }

    fetchDocuments()

    // Set up real-time subscription
    const subscription = this.supabase
      .channel('docs')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'docs' }, fetchDocuments)
      .subscribe()

      return () => subscription.unsubscribe()
    }

  async upload(file: Blob, name: string | undefined): Promise<FileMetadata> {
    if (OFFLINE) {
      const url = await blobToDataURL(file);
      const metadata: FileMetadata = {
        id: uuidv4(),
        url: url,
        signedUrl: url,
        name: name || 'File',
        mimeType: file.type
      };
      if (file.type.startsWith('image/')) {
        metadata.dimensions = await this.getImageDimensions(file);
      }
      return metadata;
    }

    const currentUser = this.currentUser.value
    if (!currentUser) {
      throw new Error('User must be signed in to upload files')
    }

    const fileName = uuidv4();
    const filePath = `${currentUser.id}/${fileName}`

    const { data, error } = await this.supabase.storage
      .from('doc_uploads')
      .upload(filePath, file)

    if (error) {
      throw new Error(`Failed to upload file: ${error.message}`)
    }

    const { data: { publicUrl } } = this.supabase.storage
      .from('doc_uploads')
      .getPublicUrl(data.path)

    const signedURLResult = await this.supabase.storage.from('doc_uploads').createSignedUrl(filePath, 60 * 60 * 24 * 365 * 50);
    if (signedURLResult.error || !signedURLResult.data) {
      throw new Error(`Failed to create signed URL: ${signedURLResult.error.message}`)
    }
    const signedURL = signedURLResult.data.signedUrl;

    let dimensions: Point | undefined

    if (file.type.startsWith('image/')) {
      dimensions = await this.getImageDimensions(file)
    }

    return {
      id: data.path,
      name: name || 'File',
      url: publicUrl,
      signedUrl: signedURL,
      mimeType: file.type,
      dimensions: dimensions
    }
  }

  private getImageDimensions(file: Blob): Promise<Point> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ x: img.width, y: img.height })
      }
      img.onerror = reject
      img.src = URL.createObjectURL(file)
    })
  }

  signedInUser(): Observable<User | null | undefined> {
    return this.currentUser
  }
}