import React, { RefObject, useCallback } from "react";
import { EditorContext, EditorState, EmptyUndoableOperation, useEditor, useEditorState } from "../../data/editor";
import { BaseObject, EditableFrame, isArtboard } from "../../data/model";
import StyleControl from "./style";
import HTMLEmbedSettings from "./html";
import { renderToString } from 'react-dom/server';
import { DocumentRenderContext, DocumentView, ObjectView } from "../objectViews/DocumentView";
import styled from "styled-components";
import LayoutControls from "./layout";
import TextControl from "./text";
import HierarchyControl from "./hierarchy";
import MakeReal from "./makeReal";
import { InlineInput, SidebarPropContainer, SidebarSection } from "./controls";
import { useRandomColor } from "../../utils/renderViz";
import AdvancedSidebarSection from "./advanced";
import EditableTextControl from "./editableTextField";
import ParamsEditor from "./paramsEditor";
import Toolbar from "../toolbar";
import DocBar from "../docBar";
import PublishControls from "./publish";
import { SIDEBAR_WIDTH } from "../uiConstants";

interface SidebarProps {
    canvasWrapper: RefObject<HTMLDivElement>;
}

const SidebarDiv = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(255,255,255,0.98);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${SIDEBAR_WIDTH}px;
    overflow: hidden;
    box-shadow: -2px 0 16px rgba(0,0,0,0.07);
    border-left: 1px solid rgba(0,0,0,0.1);
    color: #241F1E;
`;

function _Sidebar(props: SidebarProps) {
    const canvasWrapper = props.canvasWrapper;
    const selectedObjects = useEditorState(state => getSelectedObjects(state));
    return (
        <SidebarDiv className="sidebar">
            <DocBar />
            <div style={{flexDirection: 'row', display: 'flex', flexGrow: 1, flexBasis: 0, height: 0}}>
                <Toolbar />
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: 0, flexGrow: 1, overflowY: 'auto', minHeight: 0}}>
                    <NameField objects={selectedObjects} />
                    { selectedObjects.length === 1 && isArtboard(selectedObjects[0]) ? <PublishControls object={selectedObjects[0] as EditableFrame} /> : null }
                    { selectedObjects.length === 1 && selectedObjects[0].type === 'editableFrame' && !selectedObjects[0].parent ? <MakeReal object={selectedObjects[0] as EditableFrame} /> : null }
                    <LayoutControls wrapperRef={canvasWrapper} objects={selectedObjects} />
                    <StyleControl wrapperRef={canvasWrapper} objects={selectedObjects} />
                    <ParamsEditor wrapperRef={canvasWrapper} objects={selectedObjects} />
                    { selectedObjects.length > 0 && <HTMLEmbedSettings canvasWrapper={canvasWrapper} objects={selectedObjects} /> }
                    <EditableTextControl wrapperRef={canvasWrapper} objects={selectedObjects} />
                    <TextControl wrapperRef={canvasWrapper} objects={selectedObjects} />
                    <HierarchyControl wrapperRef={canvasWrapper} objects={selectedObjects} />
                    <AdvancedSidebarSection wrapperRef={canvasWrapper} objects={selectedObjects} />
                    {/* <RenderString objects={selectedObjects} /> */}
                </div>
            </div>
        </SidebarDiv>
    );
}

const Sidebar = React.memo(_Sidebar);
export default Sidebar;

function NameField({objects}: {objects: BaseObject[]}) {
    const hasSingleObject = objects.length === 1;
    const editor = useEditor();
    const existingName = hasSingleObject ? objects[0].name || '' : undefined;
    const singleObjId = hasSingleObject ? objects[0].id : null;

    const setName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        editor.modifyUndoable(state => {
            if (!singleObjId) return EmptyUndoableOperation;
            const oldName = state.document.objects[singleObjId].name;
            return {
                do: state => state.document.objects[singleObjId] && (state.document.objects[singleObjId].name = name),
                undo: state => state.document.objects[singleObjId] && (state.document.objects[singleObjId].name = oldName),
                gestureId: singleObjId ?? undefined,
            };
        })
    }, [singleObjId]);

    // On Esc and Enter, blur the input
    const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);

    if (!hasSingleObject) {
        return null;
    }

    return (
        <SidebarSection>
            <SidebarPropContainer>
                <label>Label</label>
                <InlineInput value={existingName || ""} onChange={setName} disabled={!singleObjId} onKeyUp={onKeyUp} />
            </SidebarPropContainer>
        </SidebarSection>
    )
}

function getSelectedObjects(editorState: EditorState): BaseObject[] {
    const objects: BaseObject[] = [];
    editorState.selectedObjects.forEach(id => {
        const object = editorState.document.objects[id];
        if (object) {
            objects.push(object);
        }
    });
    return objects;
}

function RenderString({objects}: {objects: BaseObject[]}) {
    const editor = useEditor();
    if (objects.length !== 1) {
        return null;
    }
    return (
        <div style={{fontFamily: 'monospace'}}>
            {renderToString((
                <DocumentRenderContext.Provider value={{ renderingToString: true }}>
                    <EditorContext.Provider value={editor}>
                        <ObjectView id={objects[0].id} />
                    </EditorContext.Provider>
                </DocumentRenderContext.Provider>
            ))}
        </div>
    )
}
