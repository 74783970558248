import clone from 'rfdc';
import { EditorState } from "./editor";
import { addObject } from './operations';
import { applyMoves } from '../ui/moveLogic';
import { applyResizes } from '../ui/resizeLogic';
import { EditableFrame } from './model';
import { applyExternalDropPlaceholder } from '../ui/dragHandlers';

const copy = clone();

export function editorStateModifiedForDisplay(state: EditorState): EditorState {
    // Quickly finish if no processing needed:
    if (!state.drawingInProgress && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.externalDropPlaceholder) {
        return state;
    }
    // OK, process:
    const newState = copy(state);
    
    if (newState.drawingInProgress) {
        // Insert into tree
        addObject(newState.document, newState.drawingInProgress.object, newState.drawingInProgress.locationInTree);
    }
    
    // Process moves
    if (newState.movesInProgress.length > 0) {
        applyMoves(newState, newState.movesInProgress, true).do(newState);
    }

    // Process resizes
    if (newState.resizesInProgress.length > 0) {
        applyResizes(newState.document, newState.resizesInProgress).do(newState);
    }

    if (newState.externalDropPlaceholder) {
        applyExternalDropPlaceholder(newState, newState.externalDropPlaceholder);
    }

    return newState;
}
