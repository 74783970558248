import { useState, useEffect } from 'react';

export const useCurrentURL = (): string => {
    const [currentURL, setCurrentURL] = useState<string>(() => new URL(window.location.href).href);
  
    useEffect(() => {
      // Function to update the URL state
      const updateURL = () => {
        setCurrentURL(new URL(window.location.href).href);
      };
  
      // Create a MutationObserver instance
      const observer = new MutationObserver(() => {
        const newURL = new URL(window.location.href).href;
        if (newURL !== currentURL) {
          updateURL();
        }
      });
  
      // Start observing the document with the configured parameters
      observer.observe(document, { subtree: true, childList: true });
  
      // Listen for popstate event (for back/forward navigation)
      window.addEventListener('popstate', updateURL);
  
      // Cleanup function
      return () => {
        observer.disconnect();
        window.removeEventListener('popstate', updateURL);
      };
    }, [currentURL]);
  
    return currentURL;
  };
  

export function useParsedRoute(): ParsedRoute | null {
    return parseRoute(useCurrentURL());
}

function parseRoute(urlStr: string): ParsedRoute | null {
    const urlObj = new URL(urlStr);
    const hostParts = urlObj.hostname.split('.');
    const leadingSubdomain = hostParts[0];
    if (urlObj.hostname === 'localhost' || urlObj.hostname === '127.0.0.1.sslip.io' || urlObj.hostname === '42pag.es' || urlObj.hostname === 'four2pages.onrender.com') {
        const pathParts = urlObj.pathname.split('/');
        if (pathParts.length === 2 && pathParts[1] === '') {
            return {
                kind: 'home',
                baseDomain: urlObj.host
            };
        }
        if (pathParts.length === 3 && pathParts[1] === 'edit') {
            return {
                kind: 'editDoc',
                docId: pathParts[2],
                baseDomain: urlObj.host
            };
        }
        return null;
    }
    // otherwise leading subdomain is app id
    let baseDomain = hostParts.slice(1).join('.');
    if (urlObj.port !== '') {
        baseDomain += ':' + urlObj.port;
    }
    return {
        kind: 'viewDocPage',
        docId: leadingSubdomain,
        pageName: urlObj.pathname,
        baseDomain
    };
}

export function currentBaseDomain(): string {
    const parsedRoute = parseRoute(window.location.href);
    if (parsedRoute === null) {
        return window.location.hostname;
    }
    switch (parsedRoute.kind) {
        case 'home':
            return parsedRoute.baseDomain;
        case 'editDoc':
            return parsedRoute.baseDomain;
        case 'viewDocPage':
            return parsedRoute.baseDomain;
    }
}

export type ParsedRoute = {
    kind: 'home',
    baseDomain: string // the base domain of the site overall, includes port
} | {
    kind: 'editDoc'
    docId: string
    baseDomain: string
} | {
    kind: 'viewDocPage'
    docId: string
    pageName: string // e.g. / or /page1
    baseDomain: string
}

export function urlForParsedRoute(parsedRoute: ParsedRoute): string {
    // navigate preserving current scheme (Http/Https)
    switch (parsedRoute.kind) {
        case 'home':
            return `//${parsedRoute.baseDomain}`;
        case 'editDoc':
            return `//${parsedRoute.baseDomain}/edit/${parsedRoute.docId}`;
        case 'viewDocPage':
            return `//${parsedRoute.docId}.${parsedRoute.baseDomain}${parsedRoute.pageName}`;
    }
}
