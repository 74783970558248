import { memo, useCallback } from "react";
import { PenMode, useEditor, useEditorDisplayState } from "../data/editor";
import styled from "styled-components";
import { TbArtboard, TbBaseline, TbCircle, TbCode, TbPointer, TbRectangle, TbTextSize } from "react-icons/tb";

const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
    align-self: stretch;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

const ToolbarButton = styled.button<{ $active: boolean }>`
    border: none;
    background-color: ${(props) => (props.$active ? 'lightblue' : 'white')};
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    cursor: default;
    width: 32px;
    height: 32px;
    outline: none;

    &:hover {
        background-color: lightgray;
    }
`;

interface ButtonConfig {
    mode?: PenMode;
    tooltip: string;
    icon: React.ReactNode;
}

const Configs: ButtonConfig[] = [
    {
        mode: undefined,
        tooltip: 'Select',
        icon: <TbPointer />,
    },
    {
        mode: PenMode.FRAME,
        tooltip: 'Frame',
        icon: <TbArtboard />,
    },
    {
        mode: PenMode.RECTANGLE,
        tooltip: 'Rectangle',
        icon: <TbRectangle />,
    },
    {
        mode: PenMode.OVAL,
        tooltip: 'Oval',
        icon: <TbCircle />,
    },
    {
        mode: PenMode.TEXT,
        tooltip: 'Text',
        icon: <TbTextSize />,
    },
    {
        mode: PenMode.INPUT_FIELD,
        tooltip: 'Input Field',
        icon: <TbBaseline />,
    },
    {
        mode: PenMode.HTML,
        tooltip: 'HTML',
        icon: <TbCode />,
    },

];

function _Toolbar() {
    // Toolbar controls the pen mode
    const penMode = useEditorDisplayState((state) => state.penMode);
    const editor = useEditor();
    const setPenMode = useCallback((mode: PenMode | undefined) => {
        editor.modify(state => {
            state.penMode = mode;
        });
    }, [editor]);

    return (
        <ToolbarContainer>
            {Configs.map((config) => (
                <ToolbarButton
                    key={config.tooltip}
                    $active={penMode === config.mode}
                    onClick={() => setPenMode(config.mode)}
                    title={config.tooltip}
                >
                    {config.icon}
                </ToolbarButton>
            ))}
        </ToolbarContainer>
    );
}

const Toolbar = memo(_Toolbar);
export default Toolbar;
