import { Editor } from "./editor";
import { BaseObject, EditableText, LayoutProps, Sizing, childrenOf, isAbsolutePosition, layoutOf, supportsChildren } from "./model";
import { addObject, deleteObjects, descendantIds, getChildrenArray, nearestCommonParent, sortObjectsByRenderOrder } from "./operations";
import { v4 as uuidv4 } from "uuid";
import { escapeHTML } from "../utils/html";
import clone from 'rfdc';
import equal from "fast-deep-equal/es6";
import { CanvasPoint, CanvasRect, rectArea, rectIntersection, rectsIntersect, unionRects, ViewportRect, zeroRect, ZeroRect } from "./geo";
import { canvasBoundingRectForObject, canvasSizeForObject, offsetFromParentForObject } from "./coordinates";
import { ensureSizesDontFillParent } from "../utils/moveUtils";
import { SIDEBAR_WIDTH } from "../ui/uiConstants";

const copy = clone();

interface ClipboardPayload {
    kind: 'designtool-payload-v1',
    copiedObjectIds: string[];
    // Contains all selected objects and children
    relevantObjects: {[key: string]: BaseObject};
    canvasPositions: {[id: string]: CanvasRect};
}

export function copyToClipboard(editor: Editor, cut: boolean) {
    const editorState = editor.state.value;
    if (editorState.selectedObjects.size === 0) {
        return;
    }
    // Construct payload and copy it
    const payload: ClipboardPayload = {
        kind: 'designtool-payload-v1',
        copiedObjectIds: Array.from(editorState.selectedObjects),
        relevantObjects: {},
        canvasPositions: {}
    };
    sortObjectsByRenderOrder(payload.copiedObjectIds, editorState.document);
    for (const id of payload.copiedObjectIds) {
        const object = copy(editorState.document.objects[id]);
        object.parent = undefined;
        payload.relevantObjects[id] = object;

        // Copy all descendants too
        descendantIds(editorState.document, id).forEach(descendantId => {
            payload.relevantObjects[descendantId] = copy(editorState.document.objects[descendantId]);
        });
    }
    // Store canvas positions
    Object.keys(payload.relevantObjects).forEach(id => {
        const rect = editor.boundingBoxForObjectId(id);
        if (rect) {
            payload.canvasPositions[id] = rect;
        }
    });

    // Use async clipboard api to write data
    // TODO: Define our own format
    const clipboardItem = new ClipboardItem({
        "web application/json": new Blob([JSON.stringify(payload)], {type: "application/json"}),
    });
    navigator.clipboard.write([clipboardItem]);

    // If cut, delete the objects
    if (cut) {
        editor.modifyUndoable(state => deleteObjects(state, payload.copiedObjectIds));
    }
}

export async function paste(editor: Editor) {
    const editorState = editor.state.value;
    const payload = await readPayloadFromClipboard();
    if (payload === null) {
        return;
    }
    // Normally we paste objects into the selected object. 
    // If there is a multi-selection, we paste into the nearest common parent.
    // If there is no selection, we paste into the root.
    // If the selection does not support children, we paste into the parent of the selection.
    // If the selection IS the originally copied object, we paste into the parent of the selection. (duplicate as sibling)
    let parentId: string | null;
    const pasteBbox = unionRects(Object.values(payload.canvasPositions));

    // TODO: When pasting siblings, preserve position in list
    // let insertionIndex: number | null = null;

    if (editorState.selectedObjects.size === 1) {
        parentId = editorState.selectedObjects.values().next().value;
        const parentObj = editorState.document.objects[parentId!];
        const parentRect = parentId ? editor.boundingBoxForObjectId(parentId) : null;
        // If we're pasting a single object and it's not significantly larger than the parent, paste it as a sibling
        if (payload.copiedObjectIds.length === 1 && rectArea(parentRect) < rectArea(payload.canvasPositions[payload.copiedObjectIds[0]]) * 1.1) {
            parentId = parentObj.parent || null;
            // insertionIndex = getChildrenArray(parentId, editorState.document)?.indexOf(parentId!);
        } else if (!supportsChildren(parentObj)) {
            // If the parent does not support children, paste into the parent
            parentId = parentObj.parent || null;
        }
    } else if (editorState.selectedObjects.size > 1) {
        parentId = nearestCommonParent(editorState.document, Array.from(editorState.selectedObjects));
    } else {
        // No selection
        parentId = null;
    }

    const payloadWithNewIds = copy(payload);        
    const newToOldIdMap = assignNewIds(payloadWithNewIds);

    const bounds = posConstraintForPasteInParentBounds(parentId, editor) || zeroRect('canvas');
    const parentHasAutoLayout = parentId && layoutOf(editorState.document.objects[parentId])?.flexLayout !== undefined;
    for (const id of payloadWithNewIds.copiedObjectIds) {
        const oldId = newToOldIdMap[id]!;
        const obj = payloadWithNewIds.relevantObjects[id];
        const layout = layoutOf(obj);
        if (!layout) continue;
        if (!parentId && payload.canvasPositions[oldId]) {
            ensureSizesDontFillParent(layout, payload.canvasPositions[oldId]);
        }
        if (parentHasAutoLayout) {
            layout.position = { kind: 'inline' };
        } else {
            // Insert at top-left of bounds
            const origin: CanvasPoint = { 
                x: bounds.x + Math.min(10, bounds.width),
                y: bounds.y + Math.min(10, bounds.height),
                space: 'canvas' 
            };
            // Add offset within paste bbox, so objects maintain relative positions
            if (pasteBbox && payload.canvasPositions[oldId]) {
                origin.x += payload.canvasPositions[oldId].x - pasteBbox.x;
                origin.y += payload.canvasPositions[oldId].y - pasteBbox.y;
            }

            layout.position = {
                kind: 'absolute', 
                x: {anchor: 'leading', unit: 'pixels', value: origin.x}, 
                y: {anchor: 'leading', unit: 'pixels', value: origin.y}
            };
        }
    }

    editor.modifyUndoable(state => {
        const oldSelectedObjects = new Set(state.selectedObjects);
        return {
            do: state => {
                // Add all objects to the document
                for (const obj of Object.values(payloadWithNewIds.relevantObjects)) {
                    state.document.objects[obj.id] = obj;
                }
                for (const id of payloadWithNewIds.copiedObjectIds) {
                    const obj = state.document.objects[id];
                    addObject(state.document, obj, {parent: parentId, index: -1});
                }
                state.selectedObjects = new Set(payloadWithNewIds.copiedObjectIds);
            },
            undo: state => {
                state.selectedObjects = oldSelectedObjects;
                deleteObjects(state, payloadWithNewIds.copiedObjectIds).do(state);
            }
        }
    })
}

// Defines the area we are allowed to position objects within when pasting.
// We don't wanna paste outside of the parent bounds or the viewport. 
// Convert viewport to parent coords
// Then intersect them (if they intersect)
// This is the constraint rect
function posConstraintForPasteInParentBounds(parent: string | null, editor: Editor): CanvasRect | undefined {
    const wrapper = editor.viewportRef && editor.viewportRef.current;
    if (!wrapper || !editor.viewportRef) return undefined;

    const viewportSize: ViewportRect = { x: SIDEBAR_WIDTH, y: 0, width: wrapper.clientWidth - SIDEBAR_WIDTH, height: wrapper.clientHeight, space: 'viewport' };

    if (parent === null) {
        // OK just constraint to viewport rect in canvas coords
        return editor.viewportToCanvasRect(viewportSize);
    }

    const viewportInCanvasCoords = editor.viewportToCanvasRect(viewportSize);
    const parentOffset = offsetFromParentForObject(parent, editor.state.value.document, editor.viewportRef);
    const parentSize = canvasSizeForObject(parent, editor.viewportRef);
    if (!parentOffset || !parentSize) return viewportInCanvasCoords;

    const parentBounds: CanvasRect = { x: 0, y: 0, width: parentSize.x, height: parentSize.y, space: 'canvas' };

    const viewportInParentCoords: CanvasRect = (() => {
        const rect = editor.viewportToCanvasRect(viewportSize);
        rect.x -= parentOffset.x;
        rect.y -= parentOffset.y;
        return rect;
    })();

    const intersection = rectIntersection(viewportInParentCoords, parentBounds);
    if (!intersection) {
        return parentBounds;
    }

    return intersection;
}

function readPayloadFromClipboard(): Promise<ClipboardPayload | null> {
    return navigator.clipboard.read().then(data => {
        for (const item of data) {
            // console.log(item.types);
            if (item.types.includes("web application/json")) {
                return item.getType("web application/json").then(blob => {
                    return tryDecodePayload(blob);
                });
            }
            // Check if clipboard includes text; paste if so
            if (item.types.includes("text/plain")) {
                return item.getType("text/plain").then(blob => {
                    return blob.text().then(text => {
                        return clipboardPayloadForPastingPlainText(text);
                    });
                });
            }
            // TODO: Support pasting images
        }
        return null;
    });
}

async function tryDecodePayload(blob: Blob): Promise<ClipboardPayload | null> {
    try {
        const text = await blob.text();
        const payload = JSON.parse(text);
        // Validate
        // TODO: Do better type validation
        if (!payload.copiedObjectIds || !payload.relevantObjects) {
            return null;
        }
        if (payload.kind !== 'designtool-payload-v1') {
            return null;
        }
        return payload;
    } catch (e) {
        console.error(e);
        return null;
    }
}

function clipboardPayloadForPastingPlainText(text: string): ClipboardPayload {
    const payload: ClipboardPayload = {
        copiedObjectIds: [],
        relevantObjects: {},
        kind: 'designtool-payload-v1',
        canvasPositions: {}
    };
    const newObj: EditableText = {
        id: uuidv4(),
        type: "editableText",
        text: escapeHTML(text),
        layout: {
            position: { 
                kind: 'absolute',
                x: { value: 0, unit: 'pixels', anchor: 'leading' },
                y: { value: 0, unit: 'pixels', anchor: 'leading' },
            }
        },
        alignment: 'left',
    };
    payload.copiedObjectIds.push(newObj.id);
    payload.relevantObjects[newObj.id] = newObj;
    return payload;
}

// Returns new -> old mapping
function assignNewIds(payload: ClipboardPayload): {[newId: string]: string} {
    const oldToNewMap: {[key: string]: string} = {};
    const newToOldMap: {[key: string]: string} = {};
    for (const id of Object.keys(payload.relevantObjects)) {
        const newId = uuidv4()
        oldToNewMap[id] = newId;
        newToOldMap[newId] = id;
    }

    // iterate thru all relevant objects, update children and parent
    const newRelevantObjects: {[key: string]: BaseObject} = {};
    for (const obj of Object.values(payload.relevantObjects)) {
        obj.id = oldToNewMap[obj.id];
        if (obj.parent) {
            obj.parent = oldToNewMap[obj.parent!];
        }
        if (supportsChildren(obj)) {
            const children = childrenOf(obj);
            const newChildren = children.map(id => oldToNewMap[id]);
            children.splice(0, children.length, ...newChildren);
        }
        newRelevantObjects[obj.id] = obj;
    }
    payload.copiedObjectIds = payload.copiedObjectIds.map(id => oldToNewMap[id]);
    payload.relevantObjects = newRelevantObjects;
    return newToOldMap;
}
