import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ClientRect, InfiniteCanvasPosition, Rect } from "../data/geo";
import { viewportToCanvasPos } from "../data/coordinates";

interface InfiniteCanvasProps {
    children: React.ReactNode;
    position: InfiniteCanvasPosition;
    onPositionChange: (position: InfiniteCanvasPosition) => void;
    attachSwipeListenerToRef: MutableRefObject<HTMLDivElement | null>;
}

function InfiniteCanvas(props: InfiniteCanvasProps) {
    const ref = useRef<HTMLDivElement>(null);
    const attachSwipeListenerToRef = props.attachSwipeListenerToRef;
    const curProps = useRef(props);
    curProps.current = props;
    const [viewportRect, setViewportRect] = useState<ClientRect>({ x: 0, y: 0, width: window.innerWidth, height: window.innerHeight, space: 'client' });

    useEffect(() => {
        const wheelListener = (e: WheelEvent) => {
            e.stopPropagation();
            e.preventDefault();
            const friction = 1;
            const event = e as WheelEvent;
            const deltaX = event.deltaX * friction;
            const deltaY = event.deltaY * friction;
            if (!event.ctrlKey) {
                curProps.current.onPositionChange(panCanvas(curProps.current.position, deltaX, deltaY));
            } else {
                curProps.current.onPositionChange(zoomCanvas(curProps.current.position, deltaX, deltaY, event.clientX - viewportRect.x, event.clientY - viewportRect.y, viewportRect));
            }
            return false;
        };
        attachSwipeListenerToRef.current?.addEventListener('wheel', wheelListener, { passive: false });
        return () => {
            attachSwipeListenerToRef.current?.removeEventListener('wheel', wheelListener);
        };
    }, [attachSwipeListenerToRef, viewportRect]);

    useEffect(() => {
        const resizeListener = () => {
            const clientRect = ref.current?.getBoundingClientRect();
            if (clientRect) {
                setViewportRect({ x: clientRect.left, y: clientRect.top, width: clientRect.width, height: clientRect.height, space: 'client' });
            }
        };
        resizeListener();
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [ref]);

    const offsetX = -props.position.centerX - viewportRect.width / 2;
    const offsetY = -props.position.centerY - viewportRect.height / 2;
    const transform = `scale(${props.position.zoom}) translate(${-offsetX}px, ${-offsetY}px)`;

    return (
        <div className="infiniteCanvas" ref={ref}>
            <div style={{ transform, width: '100%', height: '100%', position: 'relative' }} className="canvas-root">
                {props.children}
            </div>
        </div>
    );
}

export default InfiniteCanvas;

// Canvas position utils
function panCanvas(position: InfiniteCanvasPosition, deltaX: number, deltaY: number): InfiniteCanvasPosition {
    return {
        ...position,
        centerX: position.centerX - deltaX / position.zoom,
        centerY: position.centerY - deltaY / position.zoom
    };
}

function zoomCanvas(position: InfiniteCanvasPosition, dx: number, dy: number, zoomCenterX: number, zoomCenterY: number, viewport: ClientRect): InfiniteCanvasPosition {
    const zoomFactor = 1 - dy / 100;
    const oldZoomPointInCanvasCoords = viewportToCanvasPos({x: zoomCenterX, y: zoomCenterY, space: 'viewport'}, position, viewport);
    const newZoom = position.zoom * zoomFactor;
    const newZoomPointInCanvasCoords = viewportToCanvasPos({x: zoomCenterX, y: zoomCenterY, space: 'viewport'}, { ...position, zoom: newZoom }, viewport);
    return {
        zoom: newZoom,
        centerX: position.centerX - (oldZoomPointInCanvasCoords.x - newZoomPointInCanvasCoords.x),
        centerY: position.centerY - (oldZoomPointInCanvasCoords.y - newZoomPointInCanvasCoords.y)
    };
}
