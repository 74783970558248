import { useEffect, useState } from "react";
import { useEditor, useEditorDisplayState } from "../data/editor";
import styled from "styled-components";
import { useAPIClient, useUserDocuments } from "../data/apiClient/apiClientHooks";
import { DocumentMetadata } from "../data/apiClient/apiClient";
import { currentBaseDomain, ParsedRoute, urlForParsedRoute } from "../routing";
import { Popover } from "react-tiny-popover";
import { TbCloudCheck, TbCloudUp, TbEdit, TbFolder, TbGrid3X3, TbPlus } from "react-icons/tb";
import { newDoc } from "../data/apiClient/apiHelpers";

{/* <Popover content={picker} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
<div>
    <PropButtonWithPreview label="Color" onClick={() => setPopoverOpen(true)}>
        <ColorPreview color={color} />
    </PropButtonWithPreview>
</div>
</Popover> */}

// const DocumentList: React.FC = () => {
//   const apiClient = useAPIClient();
//   const [documents, setDocuments] = useState<DocumentMetadata[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const subscription = apiClient.documentsForSignedInUser().subscribe(
//       (docs) => {
//         setDocuments(docs);
//         setIsLoading(false);
//       }
//     );

//     return () => subscription();
//   }, [apiClient]);

//   const createNewDocument = async () => {
//     try {
//       // Assuming we have a createDocument method in our APIClient
//       const docData: Document = createDefaultDoc(uuidv4());
//       await apiClient.insert(docData);
//       // Redirect to the new document
//       const route: ParsedRoute = {
//         kind: 'editDoc',
//         docId: docData.id,
//         baseDomain: currentBaseDomain()
//       };
//       window.location.href = urlForParsedRoute(route);
//     } catch (err) {
//       setError('Failed to create a new document. Please try again.');
//       console.error('Error creating new document:', err);
//     }
//   };

//   if (isLoading) return <div>Loading documents...</div>;
//   if (error) return <div>Error: {error}</div>;

//   const baseDomain = currentBaseDomain();

//   return (
//     <div>
//       <h2>Your Documents</h2>
//       <button onClick={createNewDocument}>Create New Document</button>
//       {documents.length === 0 ? (
//         <p>You don't have any documents yet. Create one to get started!</p>
//       ) : (
//         <ul>
//           {documents.map(doc => (
//             <li key={doc.id}>
//               <a href={urlForParsedRoute({ kind: 'editDoc', docId: doc.id, baseDomain })}>
//                 <span>{doc.name}</span>
//                 <span> - Last updated: {doc.lastModified.toLocaleString()}</span>
//               </a>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// const ToolbarContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     padding: 4px;
// `;

// const ToolbarButton = styled.button<{ active: boolean }>`
//     border: none;
//     background-color: ${(props) => (props.active ? 'lightblue' : 'white')};
//     border-radius: 4px;
//     padding: 4px;
//     margin: 4px;
//     cursor: default;
//     width: 32px;
//     height: 32px;
//     outline: none;

//     &:hover {
//         background-color: lightgray;
//     }
// `;

const DocBarButton = styled.button`
    border: none;
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    cursor: default;
    min-width: 28px;
    height: 28px;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: lightgray;
    }
`;

const DocbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 4px;
    gap: 4px;
    align-self: stretch;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DocListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 300px;
    height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    gap: 4px;
`;

const DocListItem = styled.li<{ $selected?: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 4px;
    list-style-type: none;
    &:hover {
        background-color: lightgray;
    }
    border-radius: 4px;
        font-weight: ${(props) => (props.$selected ? 'bold' : 'normal')};
`;

function DocList({curDocId}: {curDocId: string}) {
    const docs = useUserDocuments();
    const apiClient = useAPIClient();

    return (
        <DocListContainer>
            <DocListItem onClick={() => newDoc(apiClient)}>
                <TbPlus />
                <span style={{marginLeft: '0.5em'}}>New</span>
            </DocListItem>
            {docs.map(doc => (
                <DocListItem $selected={curDocId === doc.id} key={doc.id} onClick={() => openDocument(doc)}>
                    <span>{doc.name}</span>
                </DocListItem>
            ))}
        </DocListContainer>
    )
}

function openDocument(doc: DocumentMetadata) {
    const route: ParsedRoute = {
        kind: 'editDoc',
        docId: doc.id,
        baseDomain: currentBaseDomain()
    };
    window.location.href = urlForParsedRoute(route);
}

function DocListButton() {
    const curDocId = useEditorDisplayState(state => state.document.id);
    const [showing, setShowing] = useState(false);
    return (
        <Popover isOpen={showing} onClickOutside={() => setShowing(false)} content={<DocList curDocId={curDocId} />} align="start">
            <DocBarButton title="All Docs" onClick={() => setShowing(true)}><TbFolder /></DocBarButton>
        </Popover>
    )
}

const Spacer = styled.div`
    flex: 1;
    min-width: 1em;
`;

// Docbar allows you to rename the current doc, shows the save state, and allows you to browse other docs.
// For now, renaming happens via the prompt dialog.
function DocBar() {
    const editor = useEditor();
    const [saved, setSaved] = useState(editor.saved.value);
    useEffect(() => {
        return editor.saved.subscribe(setSaved);
    }, [editor]);
    const name = useEditorDisplayState(state => state.document.name);

    const rename = () => {
        const newName = prompt('Rename this document:', name);
        if (newName) {
            editor.modify(state => state.document.name = newName);
        }
    }

    return (
        <DocbarContainer>
            <DocListButton />
            <DocBarButton title="Rename..." onClick={rename} style={{flex: 1}}>
                <span>{name}</span>
                <Spacer />
                <TbEdit />
            </DocBarButton>
            <DocBarButton onClick={() => editor.save()} title={saved ? 'Saved' : 'Saving...'}>
                {saved ? <TbCloudCheck /> : <TbCloudUp />}
            </DocBarButton>
        </DocbarContainer>
    )
}

export default DocBar;