import { RefObject } from "react";
import { CanvasPoint, CanvasRect, ClientRect, ViewportPoint, ViewportRect, rectContainsPoint, rectFromClient, unionRects, zeroRect } from "../data/geo";
import { Document, BaseObject, Positioning, Sizing, childrenOf, isAbsolutePosition, layoutOf, LayoutProps, supportsChildren } from "../data/model";
import { ObjectLocation, addToParent, deleteObjects, locationOfObject, nearestCommonParent, removeFromParent } from "../data/operations";
import { Editor, EditorState, UndoableOperation } from "../data/editor";
import { boundingRectForObjectId, elementForObjectId } from "./viewHelpers";
import { canvasBoundingRectForObject, canvasOffsetForObject, canvasToViewportPos } from "../data/coordinates";
import clone from 'rfdc';
import { editorStateModifiedForDisplay } from "../data/renderHelpers";
import { v4 as uuid } from 'uuid';
import { Constraints } from "../data/updateLayout";
import { replaceInline } from "../utils/replaceAllKeys";
import { snapViewportRect } from "./snapGuides";
import { ensureSizesDontFillParent } from "../utils/moveUtils";

const copy = clone();

export interface MoveSource {
    id: string;
    location: ObjectLocation;
    rectInViewport: ViewportRect;
    offsetFromParent: CanvasPoint;
    canvasPosition: CanvasRect;
    parentCanvasRect?: CanvasRect
    constraints: Constraints;
}

export interface MoveTarget {
    id: string;
    location: ObjectLocation;
    newLayout: LayoutProps;
    duplicate?: boolean;
    originalBox: CanvasRect;
    // TODO: Support multiple guides in single operation
    snapRuleX?: number; // Display red snap guide at this, in canvas coords
    snapRuleY?: number;
}

export function computeMoveTargets(
    sources: MoveSource[], 
    dragStart: ViewportPoint,
    dragEnd: ViewportPoint,
    // canvasWrapper: RefObject<HTMLDivElement>, 
    // editorState: EditorState,
    editor: Editor,
    duplicate: boolean
): {[id: string]: MoveTarget} {
    /*
    Rules:
    - if all objects have same parent and are auto-layout, reorder within same container
    - by default, move within same container
    - if parent has auto layout but this is the only item, switch to absolute position
    - if cursor moves outside parent container, move up to next ancestor
    - when inserting into a new parent, decide if using auto layout based on predominance
    - if cursor moves into child, check if content mostly overlap. if so, move a level deeper
    - sort by z and make sure elements have their order incremented when inserting

    TODO: Figure out snap guides
    */
    const canvasWrapper = editor.viewportRef;
    const editorState = editor.state.value;
    if (!canvasWrapper) return {};
    // const viewportRect: ClientRect = canvasWrapper.current ? rectFromClient(canvasWrapper.current.getBoundingClientRect()) : zeroRect('client')

    const exclude = allDescendantsIncludingSelf(sources.map(x => x.id), editorState.document);
    const selectionBbox = unionRects(sources.map(x => x.rectInViewport));
    if (!selectionBbox) return {};
    const selectionSizeViewportCoords: ViewportPoint = {x: selectionBbox.width, y: selectionBbox.height, space: 'viewport'};
    // During move ops, we display the document with the objects in their new positions. This is necessary for calculating drop targets
    // so we need to compute it
    const displayedDoc = editorStateModifiedForDisplay(editorState).document;

    const commonParent = nearestCommonParent(editorState.document, sources.map(x => x.id));
    const allObjectsHaveSameParent = sources.every(x => x.location.parent === sources[0].location.parent);
    const allObjectsAreAutoLayout = sources.every(x => {
        const obj = editorState.document.objects[x.id];
        return obj && !isAbsolutePosition(obj);
    });
    const commonParentHasMoreChildrenThanSelection = allObjectsHaveSameParent && commonParent ? childrenOf(editorState.document.objects[commonParent]).length > sources.length : false;

    // There are two kinds of drags: drags in which the selection is moved within the hierarchy (reparents) and drags in which it's not.
    // Reparents can happen when the selection is moved into a new parent or child,
    // OR in auto-layout frames when reordering. (In this case, we don't need to change the parent of the objects, just their index)
    let reparent: { newParentId: string | null, autoLayout: boolean, index?: number } | undefined = undefined;
    (function() {
        let nextParent = commonParent ? nextParentContainingMouse(editorState.document, commonParent, exclude, dragEnd, canvasWrapper) : commonParent;
        // Now, try moving into children
        nextParent = diveIntoChildrenContainingMouseAndFittingSize(editorState.document, nextParent, exclude, dragEnd, selectionSizeViewportCoords, canvasWrapper);

        const canReorderInSameParent = nextParent === commonParent && allObjectsHaveSameParent && allObjectsAreAutoLayout && commonParentHasMoreChildrenThanSelection;

        // const nextParentContainingInitialMousePos = nextParentContainingMouse(editorState.document, commonParent, exclude, dragStart, canvasWrapper);
        if (nextParent !== commonParent || canReorderInSameParent) {
            reparent = { newParentId: nextParent, autoLayout: mostChildrenHaveAutoLayout(editorState.document, nextParent) };
            if (reparent.autoLayout) {
                // Adjust offset
                reparent.index = dropIndex(displayedDoc, nextParent, dragEnd, canvasWrapper, new Set(sources.map(x => x.id)));
            }
        }
    })();

    const deltaViewport: ViewportPoint = {x: dragEnd.x - dragStart.x, y: dragEnd.y - dragStart.y, space: 'viewport'};
    const originalBoundingViewportRect = unionRects(sources.map(x => x.rectInViewport));
    const translatedBoundingViewportRect = originalBoundingViewportRect ? { ...originalBoundingViewportRect, x: originalBoundingViewportRect.x + deltaViewport.x, y: originalBoundingViewportRect.y + deltaViewport.y } : null;
    const {snapOffset, xSnapGuide, ySnapGuide} = snapViewportRect(translatedBoundingViewportRect, reparent?.newParentId || commonParent || undefined, editor, sources.map(x => x.id));
    const deltaCanvas = {x: (deltaViewport.x + snapOffset.x) / editorState.canvasPos.zoom, y: (deltaViewport.y + snapOffset.y) / editorState.canvasPos.zoom, space: 'canvas'};

    const targets: {[id: string]: MoveTarget} = {};
    let index = 0;
    for (const source of sources) {
        const obj = editorState.document.objects[source.id];
        const layout = layoutOf(obj);
        if (!layout) continue;
        const existingXPos = layout.position?.kind === 'absolute' ? layout.position.x : undefined;
        const existingYPos = layout.position?.kind === 'absolute' ? layout.position.y : undefined;
        // const existingConstraints = constraintsFromLayout(layout);
        targets[source.id] = {
            id: source.id,
            location: source.location,
            newLayout: {
                ...layout,
                position: {
                    kind: 'absolute',
                    x: updatePos(source.offsetFromParent.x + deltaCanvas.x, source.canvasPosition, source.parentCanvasRect, existingXPos, 'x'),
                    y: updatePos(source.offsetFromParent.y + deltaCanvas.y, source.canvasPosition, source.parentCanvasRect, existingYPos, 'y'),
                },
            },
            duplicate,
            originalBox: source.canvasPosition,
        }
        // console.log('x', (targets[source.id].newLayout.position as any).x, 'delta', deltaCanvas.x, 'source', source.offsetFromParent.x, 'existing', existingXPos);
        let needsConcreteXSize = false;
        let needsConcreteYSize = false;
        if (reparent) {
            const { newParentId, autoLayout } = reparent;
            if (autoLayout) {
                targets[source.id].newLayout.position = { kind: 'inline' };
            } else {
                const newParentOffset = canvasOffsetForObject(newParentId, canvasWrapper);
                const newParentRect: CanvasRect | undefined = newParentId ? canvasBoundingRectForObject(newParentId, canvasWrapper, editorState.canvasPos) || undefined : undefined;
                if (newParentOffset) {
                    // TODO: Preserve trailing anchor and percentages
                    targets[source.id].newLayout.position = {
                        kind: 'absolute',
                        x: updatePos(source.canvasPosition.x + deltaCanvas.x - newParentOffset.x, source.canvasPosition, newParentRect, existingXPos, 'x'),
                        y: updatePos(source.canvasPosition.y + deltaCanvas.y - newParentOffset.y, source.canvasPosition, newParentRect, existingYPos, 'y'),
                    }
                }
            }
            // needsConcreteXSize ||= sizingFillsParent(layout.xSize);
            // needsConcreteYSize ||= sizingFillsParent(layout.ySize);
            ensureSizesDontFillParent(layout, source.canvasPosition);
            targets[source.id].location = { parent: newParentId, index: index + (reparent.index || 0) };
        }
        // If we previously had a size defined by left-right or top-bottom edges, OR if we're moving away from auto layout, we need to make sure we have a concrete size (aka a fixed or hug size, not 'fill')
        // otherwise we could wind up with a zero-sized element
        // if (needsConcreteXSize) {
        //     // targets[source.id].sizing.x = { fixed: 'pixels', value: source.canvasPosition.width };
        //     targets[source.id].newLayout.xSize = { kind: 'fixed', value: source.canvasPosition.width, unit: 'pixels' };
        // }
        // if (needsConcreteYSize) {
        //     targets[source.id].newLayout.ySize = { kind: 'fixed', value: source.canvasPosition.height, unit: 'pixels' };
        // }

        if (index === 0 && targets[source.id].newLayout.position?.kind === 'absolute') {
            // HACK: snap guides should be per-operation, not per-object, since it's applied to the bounding rect of the content,
            // but it's not, so just attach it to the first object 
            targets[source.id].snapRuleX = xSnapGuide;
            targets[source.id].snapRuleY = ySnapGuide;
        }
        index += 1;
    }
    return targets;
}

// Compute the drop index by searching for the first child that contains the mouse, or insert at end.
// Must pass the `displayDoc` -- the document, after processing to render for display during live drag.
export function dropIndex(displayDoc: Document, parentId: string | null, mouse: ViewportPoint, canvasWrapper: RefObject<HTMLDivElement>, selection: Set<string>): number {
    if (!parentId) return 0;
    const parent = displayDoc.objects[parentId];
    if (!parent) return 0;
    const children = childrenOf(parent);
    let index = 0;
    for (const id of children) {
        const rect = boundingRectForObjectId(id, canvasWrapper);
        if (rect && rectContainsPoint(rect, mouse)) {
            return index;
        }
        index += 1;
    }
    // If no match, check to see if we've already given the selected items a position in the displayDoc, and use that
    const existingSelectionIdx = children.findIndex(id => selection.has(id));
    if (existingSelectionIdx >= 0) {
        return existingSelectionIdx;
    }
    return children.length;
}

// TODO: Get rid of this, instead check an object's `flexLayout` to determine if it's auto layout
function mostChildrenHaveAutoLayout(doc: Document, parentId: string | null): boolean {
    if (!parentId) return false;
    const parent = doc.objects[parentId];
    if (!parent) return false;
    const children = childrenOf(parent);
    let countAutoLayout = 0;
    for (const id of children) {
        const obj = doc.objects[id];
        if (!isAbsolutePosition(obj)) {
            countAutoLayout += 1;
        }
    }
    return countAutoLayout > children.length / 2;
}

function nextParentContainingMouse(
    doc: Document,
    objectId: string,
    excludeIds: Set<string>,
    mouse: ViewportPoint, // in viewport coords
    canvasWrapper: RefObject<HTMLDivElement>
): string | null {
    const canvas = canvasWrapper.current;
    if (!canvas) return null;
    let parentId: string | null = objectId;
    while (parentId) {
        const parent: BaseObject | undefined = doc.objects[parentId];
        if (parent) {
            const parentElement = elementForObjectId(parentId, canvasWrapper);
            const parentRect = boundingRectForObjectId(parentId, canvasWrapper);
            if (parentElement && parentRect) {
                if (rectContainsPoint(parentRect, mouse) && !excludeIds.has(parentId)) {
                    return parentId;
                }
            }
            parentId = parent.parent || null;
        } else {
            break;
        }
    }
    return null;
}

export function diveIntoChildrenContainingMouseAndFittingSize(
    doc: Document,
    objectId: string | null,
    excludeIds: Set<string>,
    mouse: ViewportPoint, // in viewport coords
    size: ViewportPoint, // in viewport coords
    canvasWrapper: RefObject<HTMLDivElement>
): string | null {
    const canvas = canvasWrapper.current;
    if (!canvas) return objectId;
    let children = objectId ? (doc.objects[objectId] ? childrenOf(doc.objects[objectId]) : []) : doc.rootIds;
    children = children.filter(id => !excludeIds.has(id));

    function canDiveInto(id: string): boolean {
        // First, check if the object is a frame
        const obj = doc.objects[id];
        if (!obj) return false;
        if (!supportsChildren(obj)) return false;
        // Then check bounding box
        const rect = boundingRectForObjectId(id, canvasWrapper);
        if (!rect) return false;
        // Does the object contain the mouse, and is it larger than the size?
        return rectContainsPoint(rect, mouse) && rect.width > size.x && rect.height > size.y;
    }

    for (const id of children) {
        if (canDiveInto(id)) {
            return diveIntoChildrenContainingMouseAndFittingSize(doc, id, excludeIds, mouse, size, canvasWrapper);
        }
    }
    return objectId;
}

export function sortByZ(id: string[], doc: Document, reverse: boolean): string[] {
    // TODO
    return id;
}

function allDescendantsIncludingSelf(ids: string[], doc: Document): Set<string> {
    const all = new Set<string>(ids);
    function traverse(id: string) {
        all.add(id);
        const obj = doc.objects[id];
        if (obj) {
            childrenOf(obj).forEach(id => traverse(id));
        }
    }
    for (const id of ids) {
        traverse(id);
    }
    return all;
}

export function applyMoves(editorState: EditorState, moves: MoveTarget[], moveInProgress: boolean): UndoableOperation {
    const doc = editorState.document;
    const duplicate = moves.filter(x => x.duplicate).length > 0;
    const oldSelection = editorState.selectedObjects;

    if (duplicate) {
        const oldToNewIdMapping = createOldToNewIdsMapping(doc, moves.map(x => x.id), moveInProgress);
        const newMoveTargets = moves.map(move => ({...move, id: oldToNewIdMapping[move.id]}));
        return {
            do: state => {
                duplicateObjects(oldToNewIdMapping, state.document);
                _applyMoves(state, newMoveTargets);
                state.movesInProgress = [];
                state.selectedObjects = new Set(newMoveTargets.map(x => x.id));
            },
            undo: state => {
                deleteObjects(state, newMoveTargets.map(x => x.id)).do(state);
                state.selectedObjects = oldSelection;
            }
        }
    }

    const origTargets: MoveTarget[] = [];
    moves.forEach(move => {
        const id = move.id;
        const loc = locationOfObject(doc, id);
        const obj = doc.objects[id];
        if (loc && obj) {
            const layout = layoutOf(obj);
            if (layout) {
                origTargets.push({
                    id, 
                    location: loc, 
                    newLayout: layout,
                    originalBox: move.originalBox,
                });
            }
        }
    });

    return {
        do: state => { 
            _applyMoves(state, moves)
            state.movesInProgress = [];
            state.selectedObjects = new Set(moves.map(x => x.id));
        },
        undo: state => {
            _applyMoves(state, origTargets)
            state.selectedObjects = oldSelection;
        }
    }
}

function _applyMoves(editorState: EditorState, moves: MoveTarget[]) {
    const doc = editorState.document;
    const oldParents: {[id: string]: string | null} = {};
    // Remove from old parents:
    moves.forEach(move => {
        const oldLoc = locationOfObject(doc, move.id);
        if (oldLoc) {
            removeFromParent(doc, oldLoc);
            oldParents[move.id] = oldLoc.parent;
        }
    });
    // Add to new parents:
    moves.forEach(move => {
        addToParent(doc, move.id, move.location);
        const obj = doc.objects[move.id];
        if (obj) {
            const layout = layoutOf(obj);
            if (layout) {
                replaceInline(move.newLayout, layout);
            }
        }
    });
}

export function commitMove(editor: Editor) {
    const moves = editor.state.value.movesInProgress;
    if (moves.length > 0) {
        editor.modifyUndoable(state => applyMoves(state, moves, false /* not in progress */));
    }
}

function createOldToNewIdsMapping(doc: Document, ids: string[], moveInProgress: boolean): {[id: string]: string} {
    const allOldIds = allDescendantsIncludingSelf(ids, doc);
    const oldToNewIdMap: {[id: string]: string} = {};
    allOldIds.forEach(id => {
        if (moveInProgress) {
            // HACK: This avoid thrashing IDs when moving
            oldToNewIdMap[id] = id + "__duplicating"; // uuid();
        } else {
            oldToNewIdMap[id] = uuid();
        }
    });
    return oldToNewIdMap;
}

// Duplicates objects and their children using an old->new mapping. Does not reinsert into tree.
function duplicateObjects(oldToNewIdMap: {[id: string]: string}, document: Document) {
    // Copy objects, reassign child and parent ids.
    // Detach root `ids` from parent
    Object.keys(oldToNewIdMap).forEach(id => {
        const oldObj = document.objects[id];
        const newObj = copy(oldObj);
        newObj.id = oldToNewIdMap[id];
        newObj.parent = oldObj.parent ? oldToNewIdMap[oldObj.parent || ''] : undefined;
        const children = childrenOf(newObj);
        const newChildren = children.map(id => oldToNewIdMap[id]);
        children.splice(0, children.length, ...newChildren);
        document.objects[newObj.id] = newObj;
    });
}

function updatePos(leadingPos: number, objectRect: CanvasRect, parentRect: CanvasRect | undefined, existing: Positioning | undefined, axis: 'x' | 'y'): Positioning {
    if (!parentRect) {
        return { value: leadingPos, unit: 'pixels', anchor: 'leading' };
    }
    const unit = existing?.unit ?? 'pixels';
    const anchor = existing?.anchor ?? 'leading';
    const parentSize = axis === 'x' ? parentRect.width : parentRect.height;
    const selfSize = axis === 'x' ? objectRect.width : objectRect.height;

    function convertToUnit(val: number): number {
        return unit === 'percent' ? val / parentSize * 100 : val;
    }

    // TODO: handle multi-constraints
    switch (anchor) {
        case 'leading': 
            return { value: convertToUnit(leadingPos), unit, anchor: 'leading' };
        case 'center': 
            const offsetFromCenter = leadingPos + selfSize / 2 - parentSize / 2;
            return { value: convertToUnit(offsetFromCenter), unit, anchor: 'center' };
        case 'trailing':
            const distFromRightEdge = parentSize - leadingPos - selfSize;
            return { value: convertToUnit(distFromRightEdge), unit, anchor };
    }
}
