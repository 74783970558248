import equal from "fast-deep-equal/es6";
import { CanvasPoint } from "./geo";
import { CodeGenParams } from "./generation/codegen2";

export interface Document {
    id: string;
    objects: { [id: string]: BaseObject };
    rootIds: string[];
    name: string;
}

export interface BaseObject {
    id: string
    type: string
    parent?: string
    hidden?: boolean
    name?: string // user-visible
    userCSS?: React.CSSProperties
}

export interface EditableFrame extends BaseObject {
    type: 'editableFrame'
    layout: LayoutProps
    stylingProps: StylingProps
    children: string[] // IDs of objects
    isArtboard?: boolean

    // "Make Real"
    codeGenParams?: CodeGenParams;
    displayAsCode?: boolean;

    // Publish
    webPublishData?: WebPublishOptions;
}

export interface WebPublishOptions {
    path: string; // E.g. / or /hello or /doc/[id]
}

export interface ImageObject extends BaseObject {
    type: 'image'
    layout: LayoutProps
    stylingProps: StylingProps
}

export const DEFAULT_TEXT = "Your text here"
export interface EditableText extends BaseObject {
    type: 'editableText'
    layout: LayoutProps
    text: string
    alignment: 'left' | 'center' | 'right'
    color?: Color;
    font?: Font;
}

export interface EditableTextField extends BaseObject {
    type: 'editableTextField'
    layout: LayoutProps
    placeholder: string
    value: string
    alignment?: 'left' | 'center' | 'right'
    font?: Font;
    color?: Color;
    stylingProps: StylingProps
}

export interface LayoutProps {
    position?: { kind: 'absolute', x: Positioning, y: Positioning } | { kind: 'inline' };
    xSize?: Sizing
    ySize?: Sizing
    flexLayout?: FlexLayout
    padding?: Padding
}

export interface HTMLEmbed extends BaseObject {
    type: 'htmlEmbed'
    codeGenParams: CodeGenParams;
    layout: LayoutProps
    params: ObjectParam[]
}

export type Sizing = { kind: 'hug' } | { kind: 'fixed', value: number, unit: 'pixels' | 'percent' } | { kind: 'trailing', offset: number, unit: 'pixels' | 'percent' };
export type Positioning = { value: number, unit: 'pixels' | 'percent', anchor: 'leading' | 'center' | 'trailing' };
export type FlexLayout = { direction: 'row' | 'column', justifyContent: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around', alignItems: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline', gap?: number, wrap?: boolean };
export type Padding = { top: number, right: number, bottom: number, left: number }

export function paddingAll(value: number): Padding {
    return { top: value, right: value, bottom: value, left: value }
}

export interface StylingProps {
    background?: Fill
    image?: ImagePointer
    clip?: boolean;
    opacity?: number;
    cornerRadius?: number;
    shadow?: Shadow;
    borderColor?: Color;
    borderWidth?: number;
}

export type Gradient = { kind: 'gradient', colors: Color[], degrees: number };
export type Fill = { kind: 'solid', color: Color } | Gradient;
export type Color = { r: number, g: number, b: number, a: number }; // r: 0..255, g: 0..255, b: 0..255, a: 0..1

export type Shadow = { x: number, y: number, blur: number, color: Color };
export type ImagePointer = { id: string, url: string, signedUrl: string, dimensions: CanvasPoint, placeholderColor: Color, alt?: string };

export interface Font {
    size?: number
    weight?: number
    family?: string
}

export interface ParamKind {
    kind: 'string' | 'number' | 'boolean' | 'color' | 'signal' | 'dict'
    range?: { min: number, max: number } // for kind: 'number'
    signalValue?: ParamKind
    fields?: { [key: string]: ParamKind }
}

export interface ObjectParam {
    id: string
    name: string
    kind: ParamKind
    value?: ParamValue; // Signals do not have values
}

export type ParamValue = string | number | boolean | Color | { [key: string]: ObjectParam['value'] };

// MARK: - Helpers

export function childrenOf(object: BaseObject): string[] {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.children || [];
    }
    return [];
}

export function supportsChildren(object: BaseObject): boolean {
    return object.type === 'editableFrame';
}

export function layoutOf(object: BaseObject): LayoutProps | null {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.layout;
    }
    if (object.type === 'image') {
        return (object as ImageObject).layout;
    }
    if (object.type === 'editableText') {
        return (object as EditableText).layout;
    }
    if (object.type === 'editableTextField') {
        return (object as EditableTextField).layout;
    }
    if (object.type === 'htmlEmbed') {
        return (object as HTMLEmbed).layout;
    }
    return null;
}

export function flexLayoutOf(object: BaseObject): FlexLayout | null {
    const layout = layoutOf(object);
    return layout && layout.flexLayout ? layout.flexLayout : null;
}

export function stylingOf(object: BaseObject): StylingProps | null {
    if (object.type === 'editableFrame') {
        const editableFrame = object as EditableFrame;
        return editableFrame.stylingProps;
    }
    if (object.type === 'editableTextField') {
        return (object as EditableTextField).stylingProps;
    }
    if (object.type === 'image') {
        return (object as ImageObject).stylingProps;
    }
    return null;
}

export function paramsOf(object: BaseObject): ObjectParam[] | null {
    if (object.type === 'htmlEmbed') {
        return (object as HTMLEmbed).params;
    }
    return null;
}

// MARK: - Conversion to CSS

export function cssForObject(obj: BaseObject, fillParent: boolean = false): React.CSSProperties {
    let dict: React.CSSProperties = {};
    const styling = stylingOf(obj);
    if (styling) {
        dict = { ...dict, ...stylingToCSS(styling) }
    }
    const layout = layoutOf(obj);
    if (layout) {
        dict = { ...dict, ...layoutToCSS(layout, fillParent) }
    }
    if (obj.type === 'editableText') {
        const editableText = obj as EditableText;
        dict = { ...dict, textAlign: editableText.alignment };
        if (editableText.color) {
            dict = { ...dict, color: `rgba(${editableText.color.r}, ${editableText.color.g}, ${editableText.color.b}, ${editableText.color.a})` }
        }
        if (editableText.font) {
            dict = { ...dict, ...fontToCSS(editableText.font) }
        }
    }
    if (obj.type === 'editableTextField') {
        const editableTextField = obj as EditableTextField;
        if (editableTextField.font) {
            dict = { ...dict, ...fontToCSS(editableTextField.font) }
        }
        if (editableTextField.alignment) {
            dict = { ...dict, textAlign: editableTextField.alignment }
        }
    }
    if (obj.type === 'htmlEmbed') {
        const htmlEmbed = obj as HTMLEmbed;
        if (htmlEmbed.layout) {
            dict = { ...dict, ...layoutToCSS(htmlEmbed.layout, fillParent) }
        }
    }
    if (obj.hidden) {
        dict = { ...dict, display: 'none' };
    }
    if (obj.userCSS) {
        dict = { ...dict, ...obj.userCSS }
    }
    return dict;
}

// We can skip padding if we only want positionanal CSS, e.g. for html embeds
export function layoutToCSS(layout: LayoutProps, fillParent: boolean, skipPadding: boolean = false): React.CSSProperties {
    let dict: React.CSSProperties = {};
    if (layout.xSize) {
        dict = { ...dict, ...sizeToCSS(layout.xSize, 'x') }
    }
    if (layout.ySize) {
        dict = { ...dict, ...sizeToCSS(layout.ySize, 'y') }
    }
    if (layoutNeedsWrapper(layout)) {
        dict.position = 'relative';
        dict.pointerEvents = 'all'; // Because the wrapper has pointer events turned off
    } else {
        // If either position axis is fixed, we need to set the position to absolute
        if (layout.position && layout.position.kind === 'absolute') {
            dict.position = 'absolute';
        } else {
            dict.position = 'relative';
        }
    }
    if (layout.position && layout.position.kind === 'absolute') {
        dict = { ...dict, ...positionToCSS(layout.position.x, 'x'), ...positionToCSS(layout.position.y, 'y') }
    }
    if (layout.flexLayout) {
        dict = { ...dict, ...flexLayoutToCSS(layout.flexLayout) }
    }
    if (layout.padding && !skipPadding) {
        dict = { ...dict, ...paddingToCSS(layout.padding) }
    }
    if (fillParent) {
        delete dict.left;
        delete dict.top;
        dict.width = '100%';
        dict.height = '100%'; // TODO: implement scrollability
    }
    return dict;
}

export function layoutNeedsWrapper(layout: LayoutProps | null): boolean {
    if (!layout) return false;
    if (layout.position && layout.position.kind === 'absolute') {
        if (layout.position.x.anchor === 'center' || layout.position.y.anchor === 'center') {
            return true;
        }
    }
    return false;
}

export function wrapperCSS(layout: LayoutProps | null): React.CSSProperties {
    if (!layout) return {};
    const dict: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none'
    };

    function anchorToFlex(anchor: 'leading' | 'center' | 'trailing'): 'flex-start' | 'center' | 'flex-end' {
        switch (anchor) {
            case 'leading':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'trailing':
                return 'flex-end';
        }
    }

    if (layout.position && layout.position.kind === 'absolute') {
        dict.alignItems = anchorToFlex(layout.position.x.anchor);
        dict.justifyContent = anchorToFlex(layout.position.y.anchor);
    }
    return dict;
}

function stylingToCSS(styling: StylingProps): React.CSSProperties {
    let dict: React.CSSProperties = {};
    if (styling.background) {
        dict = { ...dict, ...fillToCSS(styling.background) }
    }
    if (styling.image) {
        dict = { ...dict, backgroundImage: `url(${styling.image.signedUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }
    }
    if (styling.clip) {
        dict = {...dict, overflow: 'hidden'}
    }
    if (styling.opacity) {
        dict = {...dict, opacity: styling.opacity}
    }
    if (styling.cornerRadius) {
        dict = {...dict, borderRadius: styling.cornerRadius}
    }
    if (styling.shadow) {
        const shadow = styling.shadow;
        dict = {
            ...dict,
            boxShadow: `${shadow.x}px ${shadow.y}px ${shadow.blur}px rgba(${shadow.color.r}, ${shadow.color.g}, ${shadow.color.b}, ${shadow.color.a})`
        }
    }
    if (styling.borderColor && styling.borderWidth) {
        dict = {...dict, border: `${styling.borderWidth}px solid ${colorToCSS(styling.borderColor)}`}
    }
    return dict;
}

export function fillToCSS(fill: Fill | undefined): React.CSSProperties {
    if (!fill) return {};
    switch (fill.kind) {
        case 'solid':  return { backgroundColor:  colorToCSS(fill.color) };
        case 'gradient': 
        const stops = fill.colors.map(x => colorToCSS(x)).join(', ');
        return {
            background: `linear-gradient(${fill.degrees}deg, ${stops})`
        };
    }
}

const ColorStringMapping: [Color, string][] = [
    [{r: 255, g: 255, b: 255, a: 1}, 'white'],
    [{r: 0, g: 0, b: 0, a: 1}, 'black']
];
export function colorToCSS(color: Color): string {
    for (const [reference, str] of ColorStringMapping) {
        if (equal(color, reference)) {
            return str
        }
    }
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

function fontToCSS(font: Font): React.CSSProperties {
    return { fontSize: font.size, fontFamily: font.family, fontWeight: font.weight }
}

function flexLayoutToCSS(flexLayout: FlexLayout): React.CSSProperties {
    return {
        display: 'flex',
        flexDirection: flexLayout.direction,
        justifyContent: flexLayout.justifyContent,
        alignItems: flexLayout.alignItems,
        gap: flexLayout.gap,
        flexWrap: flexLayout.wrap ? 'wrap' : undefined
    }
}

function paddingToCSS(padding: Padding): React.CSSProperties {
    // if all props are same, use shorthand
    if (padding.top === padding.right && padding.right === padding.bottom && padding.bottom === padding.left) {
        return padding.top === 0 ? {} : { padding: padding.top };
    }
    return { paddingTop: padding.top, paddingRight: padding.right, paddingBottom: padding.bottom, paddingLeft: padding.left }
}

function sizeToCSS(size: Sizing, axis: 'x' | 'y'): React.CSSProperties {
    switch (size.kind) {
        case 'hug':
            return {};
        case 'fixed':
            return { [axis === 'x' ? 'width' : 'height']: valueAndUnit(size) }
        case 'trailing':
            return { [axis === 'x' ? 'right' : 'bottom']: valueAndUnit({value: size.offset, unit: size.unit}) };
    }
}

function valueAndUnit(layout: {value: number, unit: 'pixels' | 'percent'}): string {
    return `${layout.value}${layout.unit === 'pixels' ? 'px' : '%'}`;
}

function positionToCSS(position: Positioning, axis: 'x' | 'y'): React.CSSProperties {
    const valAndUnit = valueAndUnit(position);
    switch (position.anchor) {
        case 'leading':
            return { [axis === 'x' ? 'left' : 'top']: valAndUnit };
        case 'trailing':
            return { [axis === 'x' ? 'right' : 'bottom']: valAndUnit };
        case 'center':
            return { [axis === 'x' ? 'left' : 'top']: valAndUnit }; // We'll be wrapped in a centered flex container, so just need to handle the offset
    }
    return {};
}

// HELPERS
export function getFixedLeadingPosition(layout: LayoutProps): CanvasPoint | null {
    if (layout.position && layout.position.kind === 'absolute') {
        return {
            x: layout.position.x.value,
            y: layout.position.y.value,
            space: 'canvas'
        }
    }
    return null;
}

export function isAbsolutePosition(obj: BaseObject): boolean {
    const layout = layoutOf(obj);
    if (!layout) return false;
    return !!(layout.position && layout.position.kind === 'absolute');
}

export function isArtboard(obj: BaseObject): boolean {
    return obj.type === 'editableFrame' && !obj.parent && !!((obj as EditableFrame).isArtboard);
}

export const COLOR_BLACK: Color = {
    r: 0, g: 0, b: 0, a: 1
};

export const COLOR_RED: Color = {
    r: 255, g: 0, b: 0, a: 1
};

export const COLOR_CLEAR: Color = {
    r: 0, g: 0, b: 0, a: 0
};
