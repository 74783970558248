import { GeneratedCode } from "./codegen2";

export const CODEGEN_TEMPLATE = `
<html>
<head>
    <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
    <script src="https://unpkg.com/@babel/standalone@7.19.3/babel.js"></script>
    [HEAD CONTENT HERE]
    <style>
    body, html {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: transparent;
    }
    * {
        box-sizing: border-box;
    }
    #root-box {
        height: 100%;
        width: 100%;
    }
    [ADDITIONAL CSS HERE]
    </style>
</head>
<body>
    <div id="root-box"></div>
    <script data-plugins="transform-modules-umd" type="text/babel" data-presets="react" data-type="module">
    [YOUR SCRIPT HERE]
    </script>
</body>
</html>
`

export const CODEGEN_SCRIPT_PLACEHOLDER = '[YOUR SCRIPT HERE]';
export const CODEGEN_HEAD_PLACEHOLDER = '[HEAD CONTENT HERE]';
export const CODEGEN_CSS_PLACEHOLDER = '[ADDITIONAL CSS HERE]';

export function inflateCode(code: GeneratedCode): string {
    return CODEGEN_TEMPLATE
        .replace(CODEGEN_SCRIPT_PLACEHOLDER, code.js)
        .replace(CODEGEN_HEAD_PLACEHOLDER, code.head)
        .replace(CODEGEN_CSS_PLACEHOLDER, code.css);
}
