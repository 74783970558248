import { useCallback } from "react";
import { useEditor, useEditorState } from "../../data/editor";
import { Document, EditableFrame, isArtboard, WebPublishOptions } from "../../data/model";
import { ErrorLabel, InlineInput, MissableButton, MultiPropRow, PropButton, SidebarPropContainer, SidebarSection } from "./controls";
import { useAPIClient } from "../../data/apiClient/apiClientHooks";
import { currentBaseDomain, ParsedRoute, urlForParsedRoute } from "../../routing";

interface PublishControls {
    object: EditableFrame;
}

export default function PublishControls({ object }: PublishControls) {
    const webData = object.webPublishData;
    const editor = useEditor();
    const numObjectsWithSamePath = useEditorState(state => numberOfObjectsWithSamePath(state.document, object.id), [object.id]);
    const apiClient = useAPIClient();

    const updateWebPublishData = useCallback((data: WebPublishOptions | undefined) => {
        editor.modifyUndoable(state => {
            const oldData = (object as EditableFrame).webPublishData;
            return {
                do: state => {
                    const obj = state.document.objects[object.id];
                    if (obj && isArtboard(obj)) {
                        (obj as EditableFrame).webPublishData = data;
                    }
                },
                undo: state => {
                    const obj = state.document.objects[object.id];
                    if (obj && isArtboard(obj)) {
                        (obj as EditableFrame).webPublishData = oldData;
                    }
                }
            }});
    }, [object.id]);

    const addWebData = useCallback(() => {
        const path = pathForNewlyPublishedObject(editor.state.value.document);
        updateWebPublishData({ path });
    }, [object.id]);

    const removeWebData = useCallback(() => {
        updateWebPublishData(undefined);
    }, [object.id]);

    const publishAndView = useCallback(() => {
        const path = webData?.path;
        if (!path) {
            return;
        }
        apiClient.publish(editor.state.value.document).then(() => {
            // open in new tab
            const route: ParsedRoute = {
                kind: 'viewDocPage',
                docId: editor.state.value.document.id,
                pageName: webData!.path,
                baseDomain: currentBaseDomain()
            };
            const url = urlForParsedRoute(route);
            window.open(url, '_blank');
        });
    }, [object.id, webData?.path]);

    if (!webData) {
        return (
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <MissableButton onClick={addWebData}>Publish to web...</MissableButton>
            </div>
        )
    }

    return (
        <SidebarSection>
            <h6>Publish to Web</h6>
            <SidebarPropContainer>
                <label>Path</label>
                <InlineInput value={webData.path} onChange={e => updateWebPublishData({ ...webData, path: e.target.value })} />
            </SidebarPropContainer>
            {numObjectsWithSamePath > 1 && <ErrorLabel>{numObjectsWithSamePath === 2 ? "There is another artboard published at this same path." : `There are ${numObjectsWithSamePath-1} other artboards published at this path.`}</ErrorLabel>}
            <MultiPropRow>
                <PropButton label="Publish and View" onClick={publishAndView} />
                <PropButton label="Unpublish" onClick={removeWebData} />
            </MultiPropRow>
        </SidebarSection>
    )
};

function pathForNewlyPublishedObject(doc: Document): string {
    const published = publishedObjects(doc);
    const allPaths = new Set(published.map(([path, _]) => path));
    if (!allPaths.has("/")) {
        return "/";
    }
    let i = 1;
    while (allPaths.has("/page" + i)) {
        i++;
    }
    return "/page" + i;
}

function numberOfObjectsWithSamePath(doc: Document, objId: string): number {
    const obj = doc.objects[objId];
    if (!(obj && isArtboard(obj))) {
        return 0;
    }
    const frame = obj as EditableFrame;
    const path = frame.webPublishData?.path;
    if (!path) {
        return 0;
    }
    return publishedObjects(doc).filter(([p, _]) => p === path).length;
}

export function publishedObjects(doc: Document): [string, EditableFrame][] {
    const published: [string, EditableFrame][] = [];
    for (const object of Object.values(doc.objects)) {
        if (isArtboard(object)) {
            const frame = object as EditableFrame;
            const webData = frame.webPublishData;
            if (webData && webData.path) {
                published.push([webData.path, frame]);
            }
        }
    }
    return published;
}

export function publishedFrameForPath(doc: Document, path: string): EditableFrame | null {
    // TODO: Parse out [placeholders]
    for (const object of Object.values(doc.objects)) {
        if (isArtboard(object)) {
            const frame = object as EditableFrame;
            const webData = frame.webPublishData;
            if (webData && webData.path === path) {
                return frame;
            }
        }
    }
    return null;
}

// kind: 'viewDocPage'
// docId: string
// pageName: string // e.g. / or /page1
// baseDomain: string