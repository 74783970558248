import { CSSProperties, createContext, memo, useCallback, useContext, useEffect, useRef } from "react";
import { EditableFrame, EditableText, EditableTextField, HTMLEmbed, childrenOf, cssForObject, getFixedLeadingPosition, isArtboard, layoutNeedsWrapper, layoutOf, layoutToCSS, wrapperCSS } from "../../data/model";
import { useEditor, useEditorDisplayState, useEditorState } from "../../data/editor";
import EditableTextView from "./EditableTextView";
import LiveCodeView from "../../data/generation/LiveCodeView";
import { GeneratedCode } from "../../data/generation/codegen2";
import { TbPlayerPlay, TbPlayerPlayFilled } from "react-icons/tb";
import { modifyObjectFieldUndoable } from "../../data/editUtils";
import { inflateCode } from "../../data/generation/codegenTemplates";
import { DEFAULT_HTML_EMBED_CHECKERBOARD } from "../../data/generation/checkerboardTemplate";

interface DocumentRenderOptions {
    renderingToString: boolean;
}

export const DocumentRenderContext = createContext<DocumentRenderOptions>({ renderingToString: false });

function _DocumentView() {
    const rootIds = useEditorDisplayState(state => state.document.rootIds);
    return (
        <div className="document" style={{ width: '100%', height: '100%', overflow: 'hidden', color: 'black' }}>
            {rootIds.map(id => {
                return <ObjectView key={id} id={id} />
            })}
        </div>
    );
}

export const DocumentView = memo(_DocumentView);

interface ObjectViewProps {
    id: string
    fillParent?: boolean;
}

function _ObjectView(props: ObjectViewProps) {
    const object = useEditorDisplayState(state => state.document.objects[props.id]);
    const { renderingToString } = useContext(DocumentRenderContext);
    const fillParent = props.fillParent ?? false;
    const viewMode = !useEditor().editMode;

    if (!object) {
        return null;
    }

    const idProps: any = renderingToString ? {} : { 'data-object-id': object.id };
    if (object.name) {
        idProps['data-comment'] = object.name;
    };

    const css = cssForObject(object, fillParent);
    // css['border'] = `2px solid ${randomColor()}`;
    css['boxSizing'] = 'border-box';

    const children = childrenOf(object).map(id => {
        return <ObjectView key={id} id={id} />;
    });

    let content: React.ReactNode | null = null;

    if (object.type === 'editableFrame') {
        const frame = object as EditableFrame;
        const displayCode = (frame.displayAsCode || viewMode) && frame.codeGenParams && frame.codeGenParams.lastGeneratedCode && !renderingToString;
        const inner = displayCode 
            ? <LiveCodeFrame object={frame} generatedCode={frame.codeGenParams!.lastGeneratedCode!} fillParent={fillParent} /> 
            : <div style={css} {...idProps}>{children}</div>;
        const showArtboardLabel = frame.isArtboard && !frame.parent && !renderingToString && !fillParent;
        content = (
            <>
                {inner}
                {showArtboardLabel ? <ArtboardLabel frame={frame} key="__label" /> : null}
            </>
        );
    }
    if (object.type === 'image') {
        content = <div style={css} {...idProps}>{children}</div>;
    }
    if (object.type === 'editableText') {
        css['display'] = 'flex';
        content = (
            <div style={css} {...idProps}>
                <EditableTextView object={object as EditableText} />
            </div>
        )
    }
    if (object.type === 'editableTextField') {
        const field = object as EditableTextField;
        content = <input style={css} {...idProps} placeholder={field.placeholder} value={field.value} readOnly />;
    }
    if (object.type === 'htmlEmbed') {
        content = <HTMLEmbedView object={object as HTMLEmbed} {...idProps} />;
    }
    if (!content) {
        content = <div {...idProps}>Unknown object type: {object.type}</div>;
    }

    const needsWrapper = layoutNeedsWrapper(layoutOf(object));
    if (needsWrapper) {
        return (
            <div style={wrapperCSS(layoutOf(object))}>
                {content}
            </div>
        )
    } else {
        return content;
    }
}
export const ObjectView = memo(_ObjectView);

// Render viz

interface ArtboardLabelProps {
    frame: EditableFrame
}

function ArtboardLabel(props: ArtboardLabelProps) {
    const { frame } = props;
    // We expect an absolute position for these
    const {x,y} = getFixedLeadingPosition(frame.layout) || {x: 0, y: 0};
    // const x = getFixedLeadingPosition(frame.layout.xPosition);
    // const y = getFixedLeadingPosition(frame.layout.yPosition);
    const css: CSSProperties = {
        position: 'absolute',
        left: x + 'px',
        top: y + 'px',
        marginTop: '-1.8em',
        padding: '0px 4px',
        color: 'rgba(0,0,0,0.5)',
        fontSize: '12px',
        zIndex: 1000,
        cursor: 'grab',
        // do not allow wrap
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
    }
    // TODO: click to rename using prompt() (implement in clickHandlers)
    const canShowCode = props.frame.codeGenParams && props.frame.codeGenParams.lastGeneratedCode;
    return <div style={css} data-object-handle-id={frame.id}>
        {frame.name || "Artboard"}
        { canShowCode ? <div style={{left: 4, top: 1, position: 'relative', cursor: 'default'}}><ArtboardCodeToggle frame={frame} /></div> : null }
    </div>;
}

function ArtboardCodeToggle({frame}: {frame: EditableFrame}) {
    const showingCode = frame.displayAsCode;
    const editor = useEditor();
    const toggleCode = useCallback(() => {
        // export function modifyObjectFieldUndoable<T extends BaseObject, K extends keyof T>(editor: Editor, id: string, type: T['type'], field: K, value: T[K]) {
        modifyObjectFieldUndoable<EditableFrame, 'displayAsCode'>(editor, frame.id, 'editableFrame', 'displayAsCode', !showingCode);
    }, [frame.id, showingCode]);
    return (
        showingCode ? <TbPlayerPlayFilled key='showing' title="Show Design" onClick={toggleCode} style={{color: '#18f'}} /> : <TbPlayerPlay key='notShowing' title="Show Code" onClick={toggleCode} />
    );
}


interface HTMLEmbedViewProps {
    object: HTMLEmbed
}

// Create a wrapper view, with a handle for the object that extends 3px outside the object. Then embed the html using an iframe (make sure to only update when the html changes)
function HTMLEmbedView(props: HTMLEmbedViewProps) {
    
    const { object } = props;
    const css = cssForObject(object);
    const genCode = object.codeGenParams.lastGeneratedCode;
    const viewMode = !useEditor().editMode;
    const selected = useEditorState(state => state.selectedObjects.has(object.id) && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.penMode);
    const iframeInteractive = viewMode || (selected && isArtboard(object)); // TODO: Implement setting for controlling interactive mode
    return (
        <div style={css} data-object-id={object.id}>
            <LiveCodeView code={genCode} interactive={iframeInteractive} params={object.params} />
            {!iframeInteractive && <div data-object-handle-id={object.id} style={{ position: 'absolute', left: '-5px', top: '-5px', width: 'calc(100% + 10px)', height: 'calc(100% + 10px)', cursor: 'grab' }} /> }
        </div>
    );
}

interface LiveCodeFrameProps {
    generatedCode: GeneratedCode
    object: EditableFrame;
    fillParent: boolean
}
function LiveCodeFrame({ object, generatedCode, fillParent }: LiveCodeFrameProps) {
    // TODO: Implement setting for controlling interactive mode

    // Don't take all the CSS (it'll be encoded into the code).
    // Just take positional attributes (layout sans padding)
    // const css = layoutToCSS(layoutOf(object) || {}, fillParent, true /* skip padding */); // cssForObject(object, fillParent);
    // const iframeRef = useRef<HTMLIFrameElement>(null);
    const viewMode = !useEditor().editMode;
    const selected = useEditorState(state => state.selectedObjects.has(object.id) && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.penMode);
    const iframeInteractive = viewMode || (selected && isArtboard(object)); // TODO: Implement setting for controlling interactive mode

    // Don't take all the CSS (it'll be encoded into the code).
    // Just take positional attributes (layout sans padding)
    const css = layoutToCSS(layoutOf(object) || {}, fillParent, true /* skip padding */); // cssForObject(object, fillParent);

    return (
        <div style={css} data-object-id={object.id}>
            <LiveCodeView code={generatedCode} interactive={iframeInteractive} params={[]} />
            {/* {!iframeInteractive && <div data-object-handle-id={object.id} style={{ position: 'absolute', left: '-5px', top: '-5px', width: 'calc(100% + 10px)', height: 'calc(100% + 10px)', cursor: 'grab' }} /> } */}
        </div>
    );

    // useEffect(() => {
    //     if (iframeRef.current) {
    //         // Clear doc and set src html via srcDoc
    //         iframeRef.current.srcdoc = src;
    //         console.log('set srcdoc', src);
    //     }
    // }, [src]);
    // return (
    //     <div style={css} data-object-id={object.id}>
    //         <iframe sandbox="allow-scripts" data-object-id={object.id} style={{ pointerEvents: iframeInteractive ? 'auto' : 'none', position: 'absolute', width: '100%', height: '100%', border: 'none' }} ref={iframeRef} />
    //         {!iframeInteractive && <div data-object-handle-id={object.id} style={{ position: 'absolute', left: '-5px', top: '-5px', width: 'calc(100% + 10px)', height: 'calc(100% + 10px)', cursor: 'grab' }} /> }
    //     </div>
    // );
}


// interface LiveCodeFrameProps {
//     src: string
//     object: EditableFrame
//     fillParent: boolean
// }
// function LiveCodeFrame({ src, object, fillParent }: LiveCodeFrameProps) {
//     // TODO: Implement setting for controlling interactive mode

//     // Don't take all the CSS (it'll be encoded into the code).
//     // Just take positional attributes (layout sans padding)
//     const css = layoutToCSS(layoutOf(object) || {}, fillParent, true /* skip padding */); // cssForObject(object, fillParent);
//     const iframeRef = useRef<HTMLIFrameElement>(null);
//     const viewMode = !useEditor().editMode;
//     const selected = useEditorState(state => state.selectedObjects.has(object.id) && state.movesInProgress.length === 0 && state.resizesInProgress.length === 0 && !state.penMode);
//     const iframeInteractive = viewMode || (selected && isArtboard(object)); // TODO: Implement setting for controlling interactive mode
//     useEffect(() => {
//         if (iframeRef.current) {
//             // Clear doc and set src html via srcDoc
//             iframeRef.current.srcdoc = src;
//             console.log('set srcdoc', src);
//         }
//     }, [src]);
//     return (
//         <div style={css} data-object-id={object.id}>
//             <iframe sandbox="allow-scripts" data-object-id={object.id} style={{ pointerEvents: iframeInteractive ? 'auto' : 'none', position: 'absolute', width: '100%', height: '100%', border: 'none' }} ref={iframeRef} />
//             {!iframeInteractive && <div data-object-handle-id={object.id} style={{ position: 'absolute', left: '-5px', top: '-5px', width: 'calc(100% + 10px)', height: 'calc(100% + 10px)', cursor: 'grab' }} /> }
//         </div>
//     );
// }
