import { useEditor } from "../../data/editor";
import { BaseObject, Color, EditableText, EditableTextField, Font } from "../../data/model"
import { SingleColorPicker } from "./colorPickers";
import { InlineInput, MultiPropRow, NumberInput, SidebarMenu, SidebarPropContainer, SidebarSection, SidebarSegmentedControl, SidebarTextArea } from "./controls";
import { ifAllEqual } from "./style";

interface EditableTextControlProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

type AlignmentType = EditableTextField['alignment'];
type PropUpdater<T> = (val: T, gestureId?: string) => void;

function isEditableTextField(obj: BaseObject): obj is EditableTextField {
    return obj && obj.type === 'editableTextField';
}

export default function EditableTextControl(props: EditableTextControlProps) {
    const {wrapperRef, objects} = props;
    const allObjectsAreTextFields = objects.every(obj => isEditableTextField(obj)) && objects.length > 0;
    const editor = useEditor();
    const textObjects = objects as EditableTextField[];

    function propUpdater<T>(readVal: (obj: EditableTextField) => T, writeVal: (obj: EditableTextField, val: T) => void): PropUpdater<T> {
        return (val, gestureId) => {
            const ids = textObjects.map(obj => obj.id);
            const oldValues: [string, T][] = textObjects.map(obj => ([obj.id, readVal(obj)]));
            editor.modifyUndoable(state => {
                return {
                    gestureId,
                    do: state => {
                        ids.forEach(id => {
                            const obj = state.document.objects[id];
                            if (!isEditableTextField(obj)) return;
                            writeVal(obj as EditableTextField, val);
                        });
                    },
                    undo: state => {
                        oldValues.forEach(([id, value]) => {
                            const obj = state.document.objects[id];
                            if (!isEditableTextField(obj)) return;
                            writeVal(obj as EditableTextField, value);
                        });
                    }
                }
            });
        }
    }

    function setProp<K extends keyof EditableTextField>(key: K, value: EditableTextField[K], gestureId: string | undefined = undefined) {
        propUpdater(obj => obj[key], (obj, val) => obj[key] = val)(value, gestureId);
    }

    // function setFontProp<K extends keyof Font>(key: K, value: Font[K], gestureId: string | undefined = undefined) {
    //     propUpdater(obj => obj.font ? obj.font[key] : undefined, (obj, val) => {
    //         if (obj.font === undefined) {
    //             obj.font = {};
    //         }
    //         obj.font![key] = val
    //     })(value, gestureId);
    // }

    if (!allObjectsAreTextFields) {
        return null;
    }
    const sharedPlaceholder = ifAllEqual(textObjects.map(obj => obj.placeholder));
    const sharedValue = ifAllEqual(textObjects.map(obj => obj.value));

    return (
        <SidebarSection>
        <h6>Text Field</h6>
            <SidebarPropContainer>
                <label>Placeholder</label>
                <InlineInput value={sharedPlaceholder} onChange={e => setProp('placeholder', e.target.value)} />
            </SidebarPropContainer>
            <SidebarPropContainer>
                <label>Initial Value</label>
                <InlineInput value={sharedValue} onChange={e => setProp('value', e.target.value)} />
            </SidebarPropContainer>
        </SidebarSection>
    )
}
