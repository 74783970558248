import { useRef, useState } from "react";
import { Editor, EditorState, EmptyUndoableOperation, PenMode, useEditor, useEditorState } from "../data/editor";
import { CanvasPoint, CanvasRect, ClientPoint, ClientRect, Point, Rect, ViewportPoint, ViewportRect, ZeroRect, boundingRectForAllSelectors, rectContainsPoint, rectFromClient, rectFromCorners, rectsIntersect, subtract, zeroRect } from "../data/geo";
import { canvasBoundingRectForObject, canvasOffsetForObject, viewportBoundingRectForObject, viewportToCanvasRect } from "../data/coordinates";
import { v4 as uuidv4 } from 'uuid';
import { addObject, deleteObjects, descendantIds, getChildrenArray, locationOfObject } from "../data/operations";
import { Document, EditableFrame, BaseObject, Positioning, LayoutProps, isArtboard, EditableText, DEFAULT_TEXT, HTMLEmbed, layoutOf, EditableTextField, childrenOf, supportsChildren } from "../data/model";
import { MoveSource, commitMove, computeMoveTargets } from "./moveLogic";
import { boundingRectForObjectId, elementForObjectId } from "./viewHelpers";
import { RESIZE_HANDLES, ResizeHandle } from "./selectionFrameHandles";
import { ItemToResize, commitResizesInProgress, computeResizes, createInitialItemsToResize } from "./resizeLogic";
import { constraintsFromLayout } from "../data/updateLayout";
import { emptyURLMap } from "../data/generation/urlMap";

interface Handlers {
    mouseDown: (e: React.MouseEvent) => void;
    mouseMove: (e: React.MouseEvent) => void;
    mouseUp: (e: React.MouseEvent) => void;
    mouseLeave: (e: React.MouseEvent) => void;
}

// points and rects are in viewport coordinates
type MouseState = {
    type: 'down',
    startPoint: ViewportPoint,
    onObjectId: string | null,
    onObjectHandleId: string | null,
    onResizeHandle: ResizeHandle | null,
    overSelection: boolean,
} | {
    type: 'move',
    startPoint: ViewportPoint,
    endPoint: ViewportPoint,
    sources: MoveSource[],
    duplicate?: boolean,
} | {
    type: 'boxSelect',
    startPoint: ViewportPoint,
    rect: ViewportRect,
    inParent: string | null, // Parent ID for which to select children, or null for root
    gestureId: string
} | {
    type: 'drawBox',
    startPoint: ViewportPoint,
    rect: ViewportRect,
    inParent: string | null,
    mode: PenMode,
} | {
    type: 'resize',
    handle: ResizeHandle,
    startPoint: ViewportPoint,
    endPoint: ViewportPoint,
    items: ItemToResize[],
    mirror: boolean,
    lockAspectRatio: boolean,
};

export function useMouseHandlers(editor: Editor, canvasWrapperRef: React.RefObject<HTMLDivElement>): Handlers {
    const [mouseState, setMouseState] = useState<MouseState | null>(null);
    const [gestureId, setGestureId] = useState<number>(0);
    const selection = useEditorState(state => state.selectedObjects);
    const pointerLockedMovement = useRef<ViewportPoint | null>(null); // if null, pointer is not locked

    function getSelectionBoundingBoxInViewportCoords(): ViewportRect | null {
        const wrapper = canvasWrapperRef.current;
        if (!wrapper) {
            return null;
        }
        const wrapperRect = wrapper.getBoundingClientRect();
        const boundingRect = boundingRectForAllSelectors(wrapper, Array.from(selection).map(id => `[data-object-id="${id}"]`), -wrapperRect.left, -wrapperRect.top);
        return boundingRect;
    }

    function viewportPos(e: React.MouseEvent): ViewportPoint {
        const bounds = canvasWrapperRef.current?.getBoundingClientRect();
        const lockedMvmt = pointerLockedMovement.current || {x: 0, y: 0, space: 'viewport'};
        // console.log('clientX', e.clientX, 'movementX', e.movementX, 'bounds', bounds?.left);
        return {
            x: e.clientX + lockedMvmt.x - bounds!.left,
            y: e.clientY + lockedMvmt.y - bounds!.top,
            space: 'viewport'
        };
    }
    
    function idIsArtboard(id: string): boolean {
        const doc = editor.state.value.document;
        const obj = doc.objects[id];
        return obj && isArtboard(obj);
    }

    function isEditableText(id: string): boolean {
        const doc = editor.state.value.document;
        const obj = doc.objects[id];
        return obj && obj.type === 'editableText';
    }

    function viewportRect(): ClientRect {
        return canvasWrapperRef.current ? rectFromClient(canvasWrapperRef.current.getBoundingClientRect()) : zeroRect('client');
    }

    function eventIsNearEdge(e: React.MouseEvent): boolean {
        const bounds = canvasWrapperRef.current?.getBoundingClientRect();
        if (!bounds) { return false; }
        const margin = 10;
        return e.clientX < bounds.left + margin || e.clientX > bounds.right - margin || e.clientY < bounds.top + margin || e.clientY > bounds.bottom - margin;
    }

    function rectToCanvasCoords(rect: ViewportRect): CanvasRect {
        return viewportToCanvasRect(rect, editor.state.value.canvasPos, viewportRect());
    }

    // Attached directly to element
    function mouseDown(e: React.MouseEvent) {
        if (e.button === 2) { return; }
        const bypass = clickedBypassView(e);
        if (bypass) { return }
        e.preventDefault();
        const box = getSelectionBoundingBoxInViewportCoords();
        updateMouseState({
            type: 'down',
            startPoint: viewportPos(e),
            onObjectId: clickedObjectId(e),
            onObjectHandleId: clickedHandleId(e),
            overSelection: !!(box && rectContainsPoint(box, viewportPos(e))),
            onResizeHandle: clickedResizeHandle(e),
        });
        if (editor.state.value.editingTextObjectId) {
            editor.modify(state => state.editingTextObjectId = undefined);
        }
    }
    
    // Helper
    // Update editor state to reflect in-progress mouse gesture
    function updateMouseState(state: MouseState) {
        setMouseState(state);
        if (state.type === 'drawBox') {
            const rect = rectToCanvasCoords(state.rect);
            const offset = (state.inParent ? canvasOffsetForObject(state.inParent, canvasWrapperRef) : null) || {x: 0, y: 0};
            rect.x -= offset.x;
            rect.y -= offset.y;
            editor.modify(s => {
                const obj = newObjectForPenMode(state.mode, rect, state.inParent, s);
                s.drawingInProgress = { object: obj, locationInTree: {parent: state.inParent, index: -1} };
            })
        } else if (state.type === 'move') {
            const moves = computeMoveTargets(state.sources, state.startPoint, state.endPoint, editor, state.duplicate ?? false);
            editor.modify(s => s.movesInProgress = Object.values(moves));
        } else if (state.type === 'resize') {
            const resizeEdits = computeResizes(state.items, state.handle, subtract(state.endPoint, state.startPoint), editor, state.mirror, state.lockAspectRatio, viewportRect());
            editor.modify(s => s.resizesInProgress = resizeEdits);
        } else if (state.type === 'boxSelect') {
            const selection = new Set(objectsWithinBoxSelection(state.inParent, state.rect, editor));
            const gestureId = state.gestureId;
            editor.modifyUndoable(s => {
                const oldSelection = s.selectedObjects
                return {
                    do: s => s.selectedObjects = selection,
                    undo: s => s.selectedObjects = oldSelection,
                    gestureId
                }
            });
            editor.modify(s => s.boxSelectRect = state.rect);
        }
    }

    // Helper
    function beginMove(startPoint: ViewportPoint, endPoint: ViewportPoint, objectIds: Set<string>, duplicate: boolean) {
        const sources = moveSources(objectIds, editor.state.value, canvasWrapperRef);
        updateMouseState({
            type: 'move',
            startPoint,
            endPoint,
            sources,
            duplicate,
        });
    }

    // Attached directly to element
    function mouseMove(e: React.MouseEvent) {
        if (!mouseState) { // we may have no mouse state if the user clicks on a bypass view
            return;
        }
        // TODO: request a pointer lock if we're moving close to the edge of the viewport
        e.preventDefault();
        const editorState = editor.state.value;
        if (pointerLockedMovement.current) {
            pointerLockedMovement.current.x += e.movementX;
            pointerLockedMovement.current.y += e.movementY;
        }
        const currentPoint = viewportPos(e);
        if (eventIsNearEdge(e) && !pointerLockedMovement.current) {
            // Request pointer lock
            canvasWrapperRef.current?.requestPointerLock();
            pointerLockedMovement.current = {x: 0, y: 0, space: 'viewport'};
        }

        if (mouseState.type === 'down') {
            const distance = Math.sqrt((currentPoint.x - mouseState.startPoint.x) ** 2 + (currentPoint.y - mouseState.startPoint.y) ** 2);
            if (distance < 2) { return; }
            // Dragged enough -- start move
            // First, if dragging on a handle, start drag
            if (editorState.penMode) {
                updateMouseState({
                    type: 'drawBox',
                    startPoint: mouseState.startPoint,
                    rect: rectFromCorners(mouseState.startPoint, currentPoint, e.shiftKey ? 1 : undefined),
                    inParent: objectIdAtClientPos({x: e.clientX, y: e.clientY}, true, canvasWrapperRef, editor.state.value.document), // TODO: Find parent to draw into
                    mode: editorState.penMode,
                });
                return;
            }
            if (mouseState.onResizeHandle && selection.size > 0) {
                updateMouseState({
                    type: 'resize',
                    startPoint: mouseState.startPoint,
                    endPoint: currentPoint,
                    handle: mouseState.onResizeHandle!,
                    mirror: e.altKey,
                    lockAspectRatio: e.shiftKey,
                    items: createInitialItemsToResize(selection, editorState, canvasWrapperRef),
                })
                return;
            }
            if (mouseState.onObjectHandleId) {
                beginMove(mouseState.startPoint, currentPoint, new Set([mouseState.onObjectHandleId]), e.altKey);
                return;
            }
            // If dragging on selection and selection is not an artboard, start move of selection
            if (mouseState.overSelection && (selection.size !== 1 || !idIsArtboard(Array.from(selection)[0]))) {
                beginMove(mouseState.startPoint, currentPoint, selection, e.altKey);
                return;
            }
            // If dragging on an object that is not an artboard, start drag
            if (mouseState.onObjectId && !idIsArtboard(mouseState.onObjectId)) {
                beginMove(mouseState.startPoint, currentPoint, new Set([mouseState.onObjectId]), e.altKey);
                return;
            }
            // Otherwise, start box select
            updateMouseState({
                type: 'boxSelect',
                startPoint: mouseState.startPoint,
                rect: rectFromCorners(mouseState.startPoint, currentPoint),
                inParent: mouseState.onObjectId && idIsArtboard(mouseState.onObjectId) ? mouseState.onObjectId : null,
                gestureId: uuidv4()
            });
            return;
        }
        // Update in-progress gesture
        if (mouseState.type === 'drawBox') {
            updateMouseState({
                type: 'drawBox',
                startPoint: mouseState.startPoint,
                rect: rectFromCorners(mouseState.startPoint, currentPoint, e.shiftKey ? 1 : undefined),
                inParent: mouseState.inParent,
                mode: mouseState.mode,
            });
            return;
        } else if (mouseState.type === 'move') {
            updateMouseState({
                type: 'move',
                startPoint: mouseState.startPoint,
                endPoint: viewportPos(e),
                sources: mouseState.sources,
                duplicate: e.altKey,
            });
            return;
        } else if (mouseState.type === 'resize') {
            const { startPoint, handle, items } = mouseState;
            updateMouseState({
                type: 'resize',
                startPoint,
                endPoint: currentPoint,
                handle,
                items,
                mirror: e.altKey,
                lockAspectRatio: e.shiftKey,
            });
        } else if (mouseState.type === 'boxSelect') {
            const {startPoint, inParent, gestureId} = mouseState;
            const newRect = rectFromCorners(startPoint, currentPoint);
            updateMouseState({
                type: 'boxSelect',
                startPoint,
                rect: newRect,
                inParent,
                gestureId
            });
        }
    }

    // Attached directly to element
    function mouseUp(e: React.MouseEvent) {
        setGestureId(gestureId + 1);
        const editorState = editor.state.value;
        if (!mouseState) {
            return;
        }
        e.preventDefault();
        setMouseState(null);
        if (pointerLockedMovement.current) {
            document.exitPointerLock();
            pointerLockedMovement.current = null;
        }
        if (mouseState.type === 'drawBox') {
            commitDrawBox(editor);
        } else if (mouseState.type === 'move') {
            commitMove(editor);
        } else if (mouseState.type === 'resize') {
            commitResizesInProgress(editor);
        } else if (mouseState.type === 'down') {
            if (editorState.penMode) {
                // Clicked on canvas with a pen mode. Insert object at center.
                if (createPenObjectDrawingInProgressCenteredAtPoint(editor, {x: e.clientX, y: e.clientY, space: 'client'})) {
                    commitDrawBox(editor);
                }
            } else if (mouseState.overSelection && selection.size === 1 && isEditableText(Array.from(selection)[0])) {
                // Begin editing
                editor.modify(state => state.editingTextObjectId = Array.from(selection)[0]);
            } else {
                // User clicked on canvas without a pen mode.
                // Update selection
                const clicked = clickedHandleId(e) || clickedObjectId(e);
                editor.modifyUndoable(state => {
                    const control = e.ctrlKey || e.metaKey || e.shiftKey;
                    const oldSel = state.selectedObjects;
                    return {
                        do: state => modifySelection(state.selectedObjects, clicked, control),
                        undo: state => state.selectedObjects = oldSel
                    }
                });
            }
        } else if (mouseState.type === 'boxSelect') {
            editor.modify(s => s.boxSelectRect = undefined);
        }
    }

    return {
        mouseDown,
        mouseMove,
        mouseUp,
        mouseLeave: (e: React.MouseEvent) => { },
    };
}

function commitDrawBox(editor: Editor) {
    editor.modifyUndoable(state => {
        const drawingInProgress = state.drawingInProgress;
        if (!drawingInProgress) { return EmptyUndoableOperation }
        const oldPenMode = state.penMode;
        const oldSelection = state.selectedObjects;
        const newObj = {...drawingInProgress.object, id: uuidv4()};
        return {
            do: (state) => {
                state.drawingInProgress = undefined;
                state.penMode = undefined;
                state.selectedObjects = new Set([newObj.id]);
                addObject(state.document, newObj, drawingInProgress.locationInTree);
                if (oldPenMode === PenMode.TEXT) {
                    state.editingTextObjectId = newObj.id;
                }
            },
            undo: (state) => {
                deleteObjects(state, [newObj.id]).do(state);
                state.penMode = oldPenMode;
                state.selectedObjects = oldSelection;
            }
        }
    })
    editor.modify(state => state.drawingInProgress = undefined);
}

// After this, we can commit it to create the object
function createPenObjectDrawingInProgressCenteredAtPoint(editor: Editor, point: ClientPoint): boolean {
    const penMode = editor.state.value.penMode;
    if (!editor.viewportRef || !penMode) return false;
    const parent = objectIdAtClientPos(point as Point, true /* allow parents old */, editor.viewportRef, editor.state.value.document, undefined);
    const parentOffset = canvasOffsetForObject(parent, editor.viewportRef);
    if (!parentOffset) return false;

    const canvasPoint = editor.viewportToCanvasPos(editor.clientToViewportPos(point));
    const size: CanvasPoint = (function() {
        switch (penMode) {
            case PenMode.INPUT_FIELD: return {x: 150, y: 35, space: 'canvas'};
            case PenMode.TEXT: return {x: 150, y: 35, space: 'canvas'};
            default: return {x: 120, y: 120, space: 'canvas'};
        }
    })();
    const rect: CanvasRect = {
        space: 'canvas', 
        x: canvasPoint.x - parentOffset.x - size.x / 2, 
        y: canvasPoint.y - parentOffset.y - size.y / 2, 
        width: size.x, 
        height: size.y
    };
    const obj = newObjectForPenMode(penMode, rect, parent, editor.state.value);
    if (penMode === PenMode.TEXT) {
        (obj as EditableText).layout.xSize = {kind: 'hug'};
        (obj as EditableText).layout.ySize = {kind: 'hug'}; 
    }
    editor.modify(state => {
        state.drawingInProgress = {object: obj, locationInTree: {parent, index: -1}};
    })
    return true;
}

function modifySelection(ids: Set<string>, newItem: string | null, control: boolean) {
    if (control) {
        if (newItem) {
            if (ids.has(newItem)) {
                ids.delete(newItem);
            } else {
                ids.add(newItem);
            }
        }
    } else {
        ids.clear();
        if (newItem) {
            ids.add(newItem);
        }
    }
}

function clickedObjectId(e: React.MouseEvent): string | null {
    let target = e.target as HTMLElement;
    while (target && !target.hasAttribute('data-object-id')) {
        target = target.parentElement as HTMLElement;
    }
    return target?.getAttribute('data-object-id');
}

function clickedResizeHandle(e: React.MouseEvent): ResizeHandle | null {
    let target = e.target as HTMLElement;
    while (target && !target.hasAttribute('data-resize-handle-id')) {
        target = target.parentElement as HTMLElement;
    }
    const id = target?.getAttribute('data-resize-handle-id');
    if (!id) { return null; }
    return RESIZE_HANDLES.find(h => h.id === id) || null;
}

// 'bypass views' like text fields and embeds ignore our gestures
function clickedBypassView(e: React.MouseEvent): boolean {
    let target = e.target as HTMLElement;
    while (target && !target.classList.contains('bypassCanvasClicks')) {
        target = target.parentElement as HTMLElement;
    }
    return !!target;
}

function moveSources(selection: Set<string>, editorState: EditorState, canvasWrapperRef: React.RefObject<HTMLDivElement>): MoveSource[] {
    const sources: MoveSource[] = [];
    selection.forEach(id => {
        const location = locationOfObject(editorState.document, id);
        const element = elementForObjectId(id, canvasWrapperRef);
        const canvasRect = canvasBoundingRectForObject(id, canvasWrapperRef, editorState.canvasPos);
        const viewportRect = boundingRectForObjectId(id, canvasWrapperRef)
        const layout = layoutOf(editorState.document.objects[id]);
        if (!(element && location && viewportRect && canvasRect)) { return; }
        sources.push({
            id,
            location,
            rectInViewport: viewportRect,
            offsetFromParent: {x: element.offsetLeft, y: element.offsetTop, space: 'canvas'},
            canvasPosition: canvasRect,
            parentCanvasRect: location.parent ? canvasBoundingRectForObject(location.parent, canvasWrapperRef, editorState.canvasPos) || undefined : undefined,
            constraints: constraintsFromLayout(layout),
        })
    });
    return sources;
}

export function objectIdAtClientPos(pos: Point, allowParentsOnly: boolean, canvasWrapperRef: React.RefObject<HTMLDivElement>, doc: Document, excludeIds?: Set<String>): string | null {
    const el = canvasWrapperRef.current;
    if (!el) return null;
    const elements = document.elementsFromPoint(pos.x, pos.y);
    for (const element of elements) {
        if (element.hasAttribute('data-object-id')) {
            const id = element.getAttribute('data-object-id');
            const obj = doc.objects[id || ''];
            if (obj && id) {
                if (allowParentsOnly && !supportsChildren(obj)) {
                    continue;
                }
                if (excludeIds && excludeIds.has(id)) {
                    continue;
                }
                return id;
            }
        }
    }
    return null;
}

// "handles" are things like artboard labels
function clickedHandleId(e: React.MouseEvent): string | null {
    let target = e.target as HTMLElement;
    while (target && !target.hasAttribute('data-object-handle-id')) {
        target = target.parentElement as HTMLElement;
    }
    return target?.getAttribute('data-object-handle-id');
}

function newObjectForPenMode(mode: PenMode, rect: CanvasRect, parentId: string | null, editorState: EditorState): BaseObject {
    const layout: LayoutProps = {
        position: {
            kind: 'absolute',
            x: {value: rect.x, unit: 'pixels', anchor: 'leading'},
            y: {value: rect.y, unit: 'pixels', anchor: 'leading'}
        },
        xSize: { kind: 'fixed', unit: 'pixels', value: rect.width},
        ySize: { kind: 'fixed', unit: 'pixels', value: rect.height}
    };
    switch (mode) {
        case PenMode.RECTANGLE:
        case PenMode.OVAL:
            const obj: EditableFrame = {
                type: 'editableFrame',
                id: 'temp',
                layout,
                stylingProps: { background: { kind: 'solid', color: {r: 255, g: 0, b: 0, a: 1 }} },
                children: []
            };
            if (mode === PenMode.OVAL) {
                obj.stylingProps.cornerRadius = Math.max(rect.width, rect.height) / 2;
            }
            return obj;
        case PenMode.FRAME:
            // Make artboard if root
            if (!parentId) {
                const rootObjects = editorState.document.rootIds.map(id => editorState.document.objects[id]);
                const artboards = rootObjects.filter(obj => isArtboard(obj));
                const board: EditableFrame = {
                    type: 'editableFrame',
                    id: 'temp',
                    layout,
                    children: [],
                    stylingProps: { background: { kind: 'solid', color: {r: 255, g: 255, b: 255, a: 1 }} },
                    name: `Artboard ${artboards.length + 1}`,
                    isArtboard: true
                };
                return board;
            } else {
                // Normal frame
                const frame: EditableFrame = {
                    type: 'editableFrame',
                    id: 'temp',
                    layout,
                    children: [],
                    stylingProps: { background: { kind: 'solid', color: {r: 255, g: 255, b: 255, a: 1 }} }
                };
                return frame;
            }
        case PenMode.TEXT:
            const text: EditableText = {
                type: 'editableText',
                id: 'temp',
                layout,
                text: DEFAULT_TEXT,
                alignment: 'center',
            };
            return text;
        case PenMode.HTML:
            const embed: HTMLEmbed = {
                type: 'htmlEmbed',
                id: 'temp',
                layout,
                codeGenParams: {programDescription: '', urlMap: emptyURLMap(), thread: []},
                params: []
            };
            return embed;
        case PenMode.INPUT_FIELD:
            const input: EditableTextField = {
                type: 'editableTextField',
                id: 'temp',
                layout,
                value: '',
                placeholder: 'Input',
                stylingProps: {
                    borderColor: {r: 0, g: 0, b: 0, a: 0.5},
                    borderWidth: 1,
                    background: { kind: 'solid', color: {r: 255, g: 255, b: 255, a: 1}}
                }
            };
            return input;
    }
}

function objectsWithinBoxSelection(parentId: string | null, selectionRect: ViewportRect, editor: Editor): string[] {
    const children = getChildrenArray(parentId, editor.state.value.document);
    if (!children) return [];
    const matches: string[] = [];
    for (const child of children) {
        const bbox = viewportBoundingRectForObject(child, editor.viewportRef);
        if (bbox && rectsIntersect(bbox, selectionRect)) {
            matches.push(child);
        }
    }
    return matches;
}
