import { currentBaseDomain, ParsedRoute, urlForParsedRoute } from "../../routing";
import { createDefaultDoc } from "../defaultModel";
import { v4 as uuidv4 } from "uuid";
import { APIClient } from "./apiClient";
import { Document } from "../model";

export async function newDoc(apiClient: APIClient) {
    const docData: Document = createDefaultDoc(uuidv4());
    await apiClient.insert(docData);
    // Redirect to the new document
    const route: ParsedRoute = {
      kind: 'editDoc',
      docId: docData.id,
      baseDomain: currentBaseDomain()
    };
    window.location.href = urlForParsedRoute(route);
}
