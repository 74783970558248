import { Rect, ViewportRect, rectFromClient } from "../data/geo";

export function elementForObjectId(id: string, canvasWrapperRef: React.RefObject<HTMLDivElement>): HTMLDivElement | null {
    const root = canvasWrapperRef.current;
    if (!root) return null;
    return root.querySelector(`[data-object-id="${id}"]`);
}

// Return viewport coordinates
export function boundingRectForObjectId(id: string, canvasWrapperRef: React.RefObject<HTMLDivElement>): ViewportRect | null {
    const canvas = canvasWrapperRef.current;
    if (!canvas) return null;

    const canvasRect = rectFromClient(canvas.getBoundingClientRect());
    const element = elementForObjectId(id, canvasWrapperRef);
    if (!element) return null;
    const objectRect = rectFromClient(element.getBoundingClientRect());
    objectRect.x -= canvasRect.x;
    objectRect.y -= canvasRect.y;
    return {...objectRect, space: 'viewport'};
}
