
export function replaceInline<T>(source: T, target: T) {
    // Clear `target` keys and replace them with `source` keys
    for (const key in target) {
        delete target[key];
    }
    for (const key in source) {
        target[key] = source[key];
    }
}
