import { useCallback, useEffect, useState } from "react";
import { Editor, EditorContext, EmptyUndoableOperation, useEditor } from "../../data/editor";
import { BaseObject, EditableFrame } from "../../data/model";
import { MissableButton, PropButton, SidebarSection, SidebarSegmentedControl, SidebarTextArea } from "./controls";
import OpenAI from "openai";
import { FRAME_T_CODE_TEMPLATE_TOKEN_TO_REPLACE, FRAME_TO_CODE_TEMPLATE, generateCode } from "../../data/generation/frameToCode";
import { codegen2, CodeGenParams, renderObjectToString } from "../../data/generation/codegen2";
import { emptyURLMap } from "../../data/generation/urlMap";
import { modifyObjectFieldUndoable } from "../../data/editUtils";

interface MakeRealProps {
    object: EditableFrame;
}

export default function MakeReal(props: MakeRealProps) {
    const object = props.object;
    const editor = useEditor();
    const [generating, setGenerating] = useState(false);
    const visible = !!object.codeGenParams;
    const params: CodeGenParams = {programDescription: '', urlMap: emptyURLMap(), thread: []};
    const prompt = object.codeGenParams?.programDescription || "";

    const setPrompt = (str: string) => {
        modifyObjectFieldUndoable<EditableFrame, 'codeGenParams'>(editor, object.id, 'editableFrame', 'codeGenParams', {...params, programDescription: str});
    };

    // interface CodeGenInputs {
    //     frameAsString?: string;
    //     referenceFrames?: {[id: string]: string};
    //     programDescription?: string;
    //     params: ObjectParam[];
    //     urlMap: URLMap;
    
    //     thread: CodeChatMessage[]; // First user message is generated from the program description, so the first thread item is an ASSISTANT message
    // }

    async function generate() {
        if (generating) {
            return;
        }
        setGenerating(true);
        try {
            const msg = await codegen2({
                frameAsString: renderObjectToString(object.id, editor),
                programDescription: prompt,
                params: [],
                urlMap: params.urlMap,
                thread: [] // clear thread
            });
            modifyObjectFieldUndoable<EditableFrame, 'codeGenParams'>(editor, object.id, 'editableFrame', 'codeGenParams', 
                {
                    ...params, 
                    thread: [msg], 
                    lastGeneratedCode: msg.code || params.lastGeneratedCode
                });
            setGenerating(false);
        } catch (e) {
            console.error(e);
            setGenerating(false);
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    function setDisplayAsCode(code: boolean) {
        modifyObjectFieldUndoable<EditableFrame, 'displayAsCode'>(editor, object.id, 'editableFrame', 'displayAsCode', code);
    }

    function addInteractivity() {
        modifyObjectFieldUndoable<EditableFrame, 'codeGenParams'>(editor, object.id, 'editableFrame', 'codeGenParams', params);
    }
    
    function removeInteractivity() {
        editor.modifyUndoable(state => {
            const {codeGenParams, displayAsCode} = object;
            return {
                do: state => {
                    const obj = (state.document.objects[object.id] as EditableFrame);
                    obj.codeGenParams = undefined;
                    obj.displayAsCode = false;
                },
                undo: state => {
                    const obj = (state.document.objects[object.id] as EditableFrame);
                    obj.codeGenParams = codeGenParams;
                    obj.displayAsCode = displayAsCode;
                }
            }
        });
    }

    if (!visible) {
        return (
            <SidebarSection>
                <PropButton onClick={addInteractivity} label="Make Interactive" />
            </SidebarSection>
        )
    }

    return (
        <SidebarSection data-wants-spinner-cursor={generating}>
            <h6>Make Interactive</h6>
            <label>Optional description of functionality:</label>
            <SidebarTextArea value={prompt} onChange={e => setPrompt(e.target.value)} onKeyDown={onKeyDown} />
            <PropButton onClick={generate} disabled={generating} label="Generate Code" />

            <SidebarSegmentedControl<boolean>
                options={[false, true]}
                selection={object.displayAsCode ?? false}
                viewForOption={option => option ? 'Code' : 'Design'}
                onChange={setDisplayAsCode} />
            
            <MissableButton onClick={removeInteractivity}>Remove Interactivity</MissableButton>
        </SidebarSection>
    );
}
