import { childrenOf, Document } from "./model";

export function processDocumentForConsistency(doc: Document) {
    removeObjectsNotInTree(doc);
}

function removeObjectsNotInTree(doc: Document) {
    const unusedIds = new Set<string>(Object.keys(doc.objects));
    // Traverse the tree and remove all objects that are in the tree
    function traverse(parentId: string) {
        const obj = doc.objects[parentId];
        if (!obj) {
            return;
        }
        unusedIds.delete(parentId);
        const children = childrenOf(obj);
        for (const child of children) {
            traverse(child);
        }
    }

    for (const root of doc.rootIds) {
        traverse(root);
    }

    // Remove all objects that are not in the tree
    for (const id of Array.from(unusedIds)) {
        delete doc.objects[id];
    }
}
