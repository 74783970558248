import { BaseObject, Color, HTMLEmbed, ObjectParam, ParamKind, paramsOf } from "../../data/model"
import { v4 as uuidv4 } from 'uuid';
import clone from 'rfdc';
import equal from "fast-deep-equal/es6";
import { ifAllEqual } from "./style";
import { DropdownButton, InlineInput, NumberInput, PropCheckbox, SidebarMiniSection, SidebarPropContainer, SidebarSection, Spacer } from "./controls";
import { modifyObjectsUndoable, useEditor } from "../../data/editor";
import { replaceInline } from "../../utils/replaceAllKeys";
import { TbTrash } from "react-icons/tb";
import styled from "styled-components";
import { SingleColorPicker } from "./colorPickers";

const copy = clone();

interface ParamsEditorProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    objects: BaseObject[]
}

export default function ParamsEditor(props: ParamsEditorProps) {
    const {wrapperRef, objects} = props;
    const allSupportParams = ifAllEqual(objects.map(o => paramsOf(o) !== null));
    const sharedParams = ifAllEqual(objects.map(o => paramsOf(o)));
    const editor = useEditor();

    function updateParams(fn: (params: ObjectParam[]) => void) {
        modifyObjectsUndoable(editor, objects.map(o => o.id), (object) => {
            const params = paramsOf(object);
            if (!params) { return (_) => {}};
            const oldParams = copy(params);
            fn(params);
            return (object) => {
                (object as any).params = oldParams;
            };
        });
    }

    if (!allSupportParams || sharedParams === undefined) {
        return null;
    }

    function addParam(param: ObjectParam) {
        updateParams(params => params.push(param));
    }

    const nonSignalParams = (sharedParams || []).filter(p => p.kind.kind !== 'signal');
    const signalParams = (sharedParams || []).filter(p => p.kind.kind === 'signal');

    function paramView(i: number, param: ObjectParam) {
        return (
            <ParamCell key={param.id} param={param} onChange={newParam => {
                updateParams(params => params[i] = newParam);
            }} onDelete={() => {
                updateParams(params => params.splice(i, 1));
            }} />
        )
    }

    const existingParamNames = new Set((sharedParams || []).map(p => p.name));

    return (
        <>
            <SidebarSection>
                <h6>Inputs</h6>
                {nonSignalParams.map((param, i) => paramView(i, param))}
                <NewParamButton onNewParamAdded={addParam} existingParamNames={existingParamNames}  />
            </SidebarSection>
            <SidebarSection>
                <h6>Outputs</h6>
                {signalParams.map((param, i) => paramView(i, param))}
                <NewSignalButton onNewParamAdded={addParam} existingParamNames={existingParamNames} />
            </SidebarSection>
        </>
    )
}

// export interface ParamKind {
//     kind: 'string' | 'number' | 'boolean' | 'color' | 'signal' | 'dict'
//     range?: { min: number, max: number } // for kind: 'number'
//     signalValue?: ParamKind
//     fields: { [key: string]: ParamKind }
// }

// export interface ObjectParam {
//     id: string
//     name: string
//     kind: ParamKind
//     value: any
// }

interface NewParamButtonProps {
    onNewParamAdded: (param: ObjectParam) => void
    existingParamNames: Set<string>
}

function NewParamButton(props: NewParamButtonProps) {
    const {onNewParamAdded} = props;
    // Dont include 'signal' and 'dict' for now
    const availKinds: ParamKind[] = [{kind: 'string'}, {kind: 'number'}, {kind: 'boolean'}, {kind: 'color'}];
    return <DropdownButton options={availKinds} labelForOption={kind => kind.kind} onPick={kind => {
        onNewParamAdded(newParamWithKind(kind, props.existingParamNames));
    }}>Add Input...</DropdownButton>
}

interface NewSignalButtonProps {
    onNewParamAdded: (param: ObjectParam) => void
    existingParamNames: Set<string>
}

function NewSignalButton(props: NewSignalButtonProps) {
    const {onNewParamAdded} = props;
    // Don't include 'record' for now
    const availArgKinds: ParamKind[] = [{kind: 'string'}, {kind: 'number'}, {kind: 'boolean'}, {kind: 'color'}];
    return <DropdownButton options={availArgKinds} labelForOption={kind => kind.kind} onPick={kind => {
        onNewParamAdded(newSignalParamWithKind(kind, props.existingParamNames));
    }}>Add Output...</DropdownButton>;
}

function newParamWithKind(kind: ParamKind, existingParamNames: Set<string>): ObjectParam {
    switch (kind.kind) {
        case 'string':
            return {id: uuidv4(), name: pickNonConflictingName('string', existingParamNames), kind: kind, value: ""};
        case 'number':
            return {id: uuidv4(), name: pickNonConflictingName('number', existingParamNames), kind: kind, value: 0};
        case 'boolean':
            return {id: uuidv4(), name: pickNonConflictingName('bool', existingParamNames), kind: kind, value: false};
        case 'color':
            return {id: uuidv4(), name: pickNonConflictingName('color', existingParamNames), kind: kind, value: {r: 0, g: 0, b: 0, a: 1}};
        default:
            return {id: uuidv4(), name: pickNonConflictingName('input', existingParamNames), kind: kind, value: null as any};
    }
}

function newSignalParamWithKind(kind: ParamKind, existingParamNames: Set<string>): ObjectParam {
    // TODO: Handle record
    switch (kind.kind) {
        case 'string':
            return {id: uuidv4(), name: pickNonConflictingName('stringOutput', existingParamNames), kind: {kind: 'signal', signalValue: kind}}
        case 'number':
            return {id: uuidv4(), name: pickNonConflictingName('numberOutput', existingParamNames), kind: {kind: 'signal', signalValue: kind}};
        case 'boolean':
            return {id: uuidv4(), name: pickNonConflictingName('boolOutput', existingParamNames), kind: {kind: 'signal', signalValue: kind}};
        case 'color':
            return {id: uuidv4(), name: pickNonConflictingName('colorOutput', existingParamNames), kind: {kind: 'signal', signalValue: kind}};;
        default:
            return {id: uuidv4(), name: pickNonConflictingName('output', existingParamNames), kind: {kind: 'signal', signalValue: kind}}
    }
}

function pickNonConflictingName(proposed: string, existingNames: Set<string>): string {
    if (!existingNames.has(proposed)) {
        return proposed;
    }
    let i = 2;
    while (existingNames.has(`${proposed}${i}`)) {
        i++;
    }
    return `${proposed}${i}`;
}

// interface DropdownButtonProps<T> {
//     children: React.ReactNode;
//     options: T[];
//     labelForOption: (option: T) => string;
//     onPick: (option: T) => void;
// }

interface ParamCellProps {
    param: ObjectParam
    onChange: (param: ObjectParam) => void
    onDelete: () => void
}

const ParamCellBorderlessInput = styled.input`
    appearance: none;
    border: none;
    background: none;
    text-align: right;
    flex-grow: 1;
`;

function ParamCell(props: ParamCellProps) {
    const {param, onChange, onDelete} = props;
    const {name, kind, value} = param;

    let valueInput: React.ReactNode = null;
    switch (kind.kind) {
        case 'string':
            valueInput = (
                <SidebarPropContainer>
                    <label>Value</label>
                    <InlineInput type="text" value={value as string} onChange={e => onChange({...param, value: e.target.value})} />
                </SidebarPropContainer>
            )
            break;
        case 'number':
            valueInput = (
                <NumberInput value={value as number} onChange={v => onChange({...param, value: v})} label="Value" />
            )
            break;
        case 'boolean':
            valueInput = <PropCheckbox value={value as boolean} onChange={val => onChange({...param, value: val})} label="Value" />
            break;
        case 'color':
            // Spacer and normal color input
            valueInput = <SingleColorPicker color={value as Color} onChange={val => onChange({...param, value: val})} />
            break;
        default:
            break;
    }

    return (
        <SidebarMiniSection>
            <SidebarPropContainer>
                <label>Name</label>
                <ParamCellBorderlessInput type="text" value={name} onChange={e => onChange({...param, name: e.target.value})} />
            </SidebarPropContainer>
            {valueInput}
            <TbTrash className="deleteIcon" onClick={onDelete} />
        </SidebarMiniSection>
    )
}
