import { Editor } from "./editor";
import { BaseObject } from "./model";

export function modifyObjectFieldUndoable<T extends BaseObject, K extends keyof T>(editor: Editor, id: string, type: T['type'], field: K, value: T[K]) {
    editor.modifyUndoable(state => {
        const oldObj = state.document.objects[id];
        const hasOldVal = oldObj && oldObj.type === type;
        const oldVal = (oldObj && oldObj.type === type) ? (oldObj as T)[field] : undefined;
        return {
            do: state => {
                const obj = state.document.objects[id];
                if (obj && obj.type === type) {
                    (obj as T)[field] = value;
                }
            },
            undo: state => {
                const obj = state.document.objects[id];
                if (obj && obj.type === type && hasOldVal) {
                    (obj as T)[field] = oldVal!;
                }
            }
        }
    });
}

export function modifyObjectSubfieldUndoable<T extends BaseObject, K extends keyof T, SK extends keyof T[K]>(editor: Editor, id: string, type: T['type'], field: K, subfield: SK, value: T[K][SK]) {
    editor.modifyUndoable(state => {
        const oldObj = state.document.objects[id];
        const hasOldVal = oldObj && oldObj.type === type;
        const oldVal = (oldObj && oldObj.type === type) ? (oldObj as T)[field][subfield] : undefined;
        return {
            do: state => {
                const obj = state.document.objects[id];
                if (obj && obj.type === type && (obj as T)[field]) {
                    (obj as T)[field][subfield] = value;
                }
            },
            undo: state => {
                const obj = state.document.objects[id];
                if (obj && obj.type === type && hasOldVal && (obj as T)[field]) {
                    (obj as T)[field][subfield] = oldVal!;
                }
            }
        }
    });
}

// function propUpdater<T>(readVal: (obj: StylingProps) => T, writeVal: (obj: StylingProps, val: T) => void): PropUpdater<T> {
//     return (val, gestureId) => {
//         const ids = objects.map(obj => obj.id);
//         const oldValues: [string, T][] = objects.map(obj => ([obj.id, readVal(stylingOf(obj)!)]));
//         editor.modifyUndoable(state => {
//             return {
//                 gestureId,
//                 do: state => {
//                     ids.forEach(id => {
//                         const obj = state.document.objects[id];
//                         if (!obj) return;
//                         const styling = stylingOf(obj)!;
//                         writeVal(styling, val);
//                     });
//                 },
//                 undo: state => {
//                     oldValues.forEach(([id, value]) => {
//                         const obj = state.document.objects[id];
//                         if (!obj) return;
//                         const styling = stylingOf(obj)!;
//                         writeVal(styling, value);
//                     });
//                 }
//             }
//         });
//     }
// }

// function setProp<K extends keyof StylingProps>(key: K, value: StylingProps[K], gestureId: string | undefined = undefined) {
//     propUpdater(obj => obj[key], (obj, val) => obj[key] = val)(value, gestureId);
// }