import { createContext, useContext, useEffect, useState } from "react";
import { createDefaultDoc } from "./defaultModel";
import { CanvasPoint, CanvasRect, ClientRect, InfiniteCanvasPosition, Point, Rect, rectFromClient, ViewportPoint, ViewportRect, zeroRect } from "./geo";
import { Document, EditableFrame, BaseObject, Positioning } from "./model";
import Observable from "./observable";
import clone from 'rfdc';
import equal from 'fast-deep-equal/es6';
import { ObjectLocation } from "./operations";
import { MoveTarget } from "../ui/moveLogic";
import { ResizeTarget } from "../ui/resizeLogic";
import { editorStateModifiedForDisplay } from "./renderHelpers";
import { canvasBoundingRectForObject, canvasToViewportRect, viewportToCanvasRect } from "./coordinates";
import { APIClient, Unsubscribe } from "./apiClient/apiClient";
import { DropPos } from "../ui/dragHandlers";

const copy = clone();

export interface EditorState {
    document: Document;
    selectedObjects: Set<string>;
    canvasPos: InfiniteCanvasPosition;
    undos: UndoItem[]; // Newest undos are pushed onto end
    redos: UndoItem[]; // When something is undone, it's pushed on the end of this stack. Redos are processed from the end
    currentFrameId: number; // We use this to coalesce undos that happen in the same frame so that we undo them all together
    penMode?: PenMode;
    drawingInProgress?: DrawingInProgress;
    movesInProgress: MoveTarget[];
    editingTextObjectId?: string;
    resizesInProgress: ResizeTarget[];
    externalDropPlaceholder?: DropPos;
    boxSelectRect?: ViewportRect;
}

export interface DrawingInProgress {
    locationInTree: ObjectLocation;
    object: BaseObject;
}

export enum PenMode {
    RECTANGLE = 'r',
    OVAL = 'o',
    TEXT = 't',
    FRAME = 'f',
    HTML = 'c',
    INPUT_FIELD = 'i',
}

const UNDO_STACK_DEPTH = 50;
export const OFFLINE = false; // for debugging

export class Editor {
    docId: string;
    apiClient: APIClient;
    state: Observable<EditorState>
    frameIncrementScheduled: boolean = false;
    displayUpdateScheduled: boolean = false;
    stateForDisplay: Observable<EditorState> // copy of state with drawingInProgress, movesInProgress, resizesInProgress, etc applied
    viewportRef: React.RefObject<HTMLDivElement> | undefined;

    lastSavedDoc: Document | null = null;    
    needsSave: boolean = false;
    saveInProgress: boolean = false; // If save in progress, needsSave=true means we need to save again after the current save finishes
    saveScheduled: boolean = false;
    saved: Observable<boolean> = new Observable(false);
    
    unsubscribers: Unsubscribe[] = [];
    loadedYet = false
    editMode = false;

    constructor(apiClient: APIClient, docId: string, editMode: boolean) {
        this.docId = docId;
        this.apiClient = apiClient;
        this.state = new Observable(defaultEditorState());
        this.stateForDisplay = new Observable(defaultEditorState());
        this.editMode = editMode;
        
        if (OFFLINE) {
            // do nothing
        } else if (editMode) {
            // edit mode: observe real document live
            this.unsubscribers.push(apiClient.observeDocument(docId, doc => {
                // HACK: Disable observation right now
                if (this.loadedYet) { return }
                this.loadedYet = true
                this.modify(state => {
                    state.document = doc;
                    this.lastSavedDoc = copy(doc);
                });
                this.saved.value = true;
            }));
        } else {
            // load read-only published view
            apiClient.fetchPublishedDoc(docId).then(doc => {
                if (!doc) { return }
                this.loadedYet = true;

                this.modify(state => {
                    state.document = doc;
                    this.lastSavedDoc = copy(doc);
                });
            });
        }
    }

    destroy() {
        for (const unsubscribe of this.unsubscribers) {
            unsubscribe();
        }
    }

    modifyUndoable(fn: (state: EditorState) => UndoableOperation) {
        // Copy main state:
        const state = copy(this.state.value);
        const operation = fn(copy(state));
        
        // See if we can find an operation with the same gestureId in the undo stack
        if (operation.gestureId) {
            const matchingUndoIndex = state.undos.findIndex(undo => undo.gestureId === operation.gestureId);
            if (matchingUndoIndex !== -1) {
                state.undos[matchingUndoIndex].do = operation.do;
                operation.do(state);
                this.state.value = state;
                this.scheduleIncrementFrame();
                this.scheduleDisplayUpdate();      
                this.scheduleSave();  
                return;
            }
        }

        operation.do(state);
        // Push undo
        state.undos.push({
            frameId: state.currentFrameId,
            do: operation.do,
            undo: operation.undo,
            gestureId: operation.gestureId,
        });
        // Clear redos
        state.redos = [];
        // Limit undo stack
        while (state.undos.length > UNDO_STACK_DEPTH) {
            state.undos.shift();
        }
        // Save
        this.state.value = state;
        this.scheduleIncrementFrame();
        this.scheduleDisplayUpdate();
        this.scheduleSave();
    }

    modify(fn: (state: EditorState) => void) {
        this._modifyInternal(fn);
        this.scheduleDisplayUpdate();
        this.scheduleSave();
    }

    private scheduleSave() {
        this.saved.value = false;
        if (this.saveInProgress) {
            this.needsSave = true;
            return;
        }
        this.needsSave = true;
        if (!this.saveScheduled) {
            this.saveScheduled = true;
            setTimeout(() => {
                this.saveScheduled = false;
                this.save();
            }, 5000);
        }
    }

    save() {
        if (OFFLINE) return;
        if (!this.needsSave) {
            return;
        }
        this.needsSave = false;
        this.saveInProgress = true;
        const doc = copy(this.state.value.document);
        const doSave = async() => {
            if (this.lastSavedDoc && equal(this.lastSavedDoc, doc)) {
                return;
            }
            await this.apiClient.save(doc);
            this.lastSavedDoc = doc;
        }
        doSave().then(() => {
            console.log('Document saved');
            this.saveInProgress = false;
            if (this.needsSave) {
                this.scheduleSave();
            } else {
                this.saved.value = true
            }
        }).catch(err => {
            console.error('Error saving document:', err);
            this.saveInProgress = false;
            this.needsSave = true;
        });
    }

    private _modifyInternal(fn: (state: EditorState) => void) {
        const state = copy(this.state.value);
        fn(state);
        this.state.value = state;
    }

    undo() {
        if (this.state.value.undos.length === 0) {
            return;
        }
        const state = copy(this.state.value);
        const lastFrameId = state.undos[state.undos.length - 1].frameId;
        // Process all undos with the same frameId, in reverse order
        while (state.undos.length > 0 && state.undos[state.undos.length - 1].frameId === lastFrameId) {
            const undo = state.undos.pop();
            if (undo) {
                undo.undo(state);
                state.redos.push(undo);
            }
        }
        this.state.value = state;
        this.scheduleDisplayUpdate();
    }

    redo() {
        if (this.state.value.redos.length === 0) {
            return;
        }
        const state = copy(this.state.value);
        const lastFrameId = state.redos[state.redos.length - 1].frameId;
        // Process all redos with the same frameId, in reverse order
        while (state.redos.length > 0 && state.redos[state.redos.length - 1].frameId === lastFrameId) {
            const redo = state.redos.pop();
            if (redo) {
                redo.do(state);
                state.undos.push(redo);
            }
        }
        this.state.value = state;
        this.scheduleDisplayUpdate();
    }

    private scheduleIncrementFrame() {
        if (!this.frameIncrementScheduled) {
            this.frameIncrementScheduled = true;
            requestAnimationFrame(() => {
                this.frameIncrementScheduled = false;
                this.modify(state => {
                    state.currentFrameId++;
                });
            });
        }
    }
    
    private scheduleDisplayUpdate() {
        if (!this.displayUpdateScheduled) {
            this.displayUpdateScheduled = true;
            requestAnimationFrame(() => {
                this.displayUpdateScheduled = false;
                this.stateForDisplay.value = editorStateModifiedForDisplay(this.state.value);
            });
        }
    }
    
    // MARK: Unit conversion
    viewportRect(): ClientRect {
        const boundingRect = this.viewportRef?.current?.getBoundingClientRect();
        if (boundingRect) {
            return rectFromClient(boundingRect);
        }
        return zeroRect('client');
    }

    viewportBounds(): ViewportRect {
        const rect = this.viewportRect();
        return { x: 0, y: 0, width: rect.width, height: rect.height, space: 'viewport' };
    }

    viewportToCanvasRect(viewportRect: ViewportRect): CanvasRect {
        return viewportToCanvasRect(viewportRect, this.state.value.canvasPos, this.viewportRect());
    }

    canvasToViewportRect(canvasRect: CanvasRect): ViewportRect {
        return canvasToViewportRect(canvasRect, this.state.value.canvasPos, this.viewportRect());
    }

    viewportToCanvasPos(viewportPos: ViewportPoint): CanvasPoint {
        const {x,y} = this.viewportToCanvasRect({x: viewportPos.x, y: viewportPos.y, width: 0, height: 0, space: 'viewport'});
        return {x, y, space: 'canvas'};
    }

    canvasToViewportPos(canvasPos: CanvasPoint): ViewportPoint {
        const {x,y} = this.canvasToViewportRect({x: canvasPos.x, y: canvasPos.y, width: 0, height: 0, space: 'canvas'});
        return {x, y, space: 'viewport'};
    }

    clientToViewportPos(clientPos: Point): ViewportPoint {
        const rect = this.viewportRect();
        return {x: clientPos.x - rect.x, y: clientPos.y - rect.y, space: 'viewport'};
    }

    // MARK: Object location
    boundingBoxForObjectId(id: string): CanvasRect | null {
        if (!this.viewportRef) return null;
        return canvasBoundingRectForObject(id, this.viewportRef, this.state.value.canvasPos);
    }
}

export interface UndoableOperation {
    gestureId?: string;
    do: (state: EditorState) => void;
    undo: (state: EditorState) => void;
}

export const EmptyUndoableOperation: UndoableOperation = {
    do: (_) => {},
    undo: (_) => {}
}

// Called immediately on all updates
export function useEditorState<T>(selector: (editor: EditorState) => T, deps: any[] = []): T {
    return _useEditorState(false, selector, deps);
}

// Called once per frame at most
export function useEditorDisplayState<T>(selector: (editor: EditorState) => T, deps: any[] = []): T {
    return _useEditorState(true, selector, deps);
}

function _useEditorState<T>(displayState: boolean, selector: (editor: EditorState) => T, deps: any[] = []): T {
    const editor = useContext(EditorContext);
    const stateVar = displayState ? editor.stateForDisplay : editor.state;
    const [value, setValue] = useState(selector(stateVar.value));
    useEffect(() => {
        const stateVar = displayState ? editor.stateForDisplay : editor.state;
        return stateVar.subscribe(newState => {
            const selected = selector(newState);
            if (!equal(selected, value)) {
                setValue(selected);
            }
        });
    }, [editor, ...deps, value]);
    return value;
}

export function useEditor(): Editor {
    return useContext(EditorContext);
}

// HACK
export const EditorContext = createContext<Editor>({} as Editor);

function defaultEditorState(): EditorState {
    return {
        selectedObjects: new Set(),
        canvasPos: { zoom: 1, centerX: 0, centerY: 0 },
        undos: [],
        redos: [],
        document: createDefaultDoc(),
        currentFrameId: 0,
        movesInProgress: [],
        resizesInProgress: [],
    };
}

interface UndoItem {
    frameId: number;
    gestureId?: string;
    // `do` and `undo` should modify `state` in place
    do: (state: EditorState) => void;
    undo: (state: EditorState) => void;
}

type ApplyUndoFn = (obj: BaseObject) => void;
export function modifyObjectsUndoable(editor: Editor, ids: string[], editFn: (obj: BaseObject) => ApplyUndoFn) {
    editor.modifyUndoable(state => {
        const objectUndoFns: { [key: string]: ApplyUndoFn } = {};
        // HACK: Process the updates once before actually processing in order to read the old values and capture the undo functions
        for (const id of ids) {
            const obj = state.document.objects[id];
            if (obj) {
                objectUndoFns[id] = editFn(copy(obj));
            }
        }
        return {
            do: state => {
                for (const id of ids) {
                    const obj = state.document.objects[id];
                    if (obj) {
                        editFn(obj);
                    }
                }
            },
            undo: state => {
                for (const id of ids) {
                    const obj = state.document.objects[id];
                    if (obj) {
                        objectUndoFns[id](obj);
                    }
                }
            }
        };
    }
    );
}
