import React, { createContext, useContext, useEffect, useState } from "react";
import { APIClient, DocumentMetadata, User } from "./apiClient";
import { SupabaseAPIClient } from "./supabaseApiClient";
import { Document } from "../model";

// Create the context
const APIClientContext = createContext<APIClient | null>(null);

// Create a provider component
interface APIClientProviderProps {
  children: React.ReactNode;
}

export function APIClientProvider({ children }: APIClientProviderProps) {
  const [apiClient] = useState<APIClient>(() => new SupabaseAPIClient());

  return (
    <APIClientContext.Provider value={apiClient}>
      {children}
    </APIClientContext.Provider>
  );
}

// Create a custom hook to use the API client
export const useAPIClient = (): APIClient => {
  const context = useContext(APIClientContext);
  if (context === null) {
    throw new Error('useAPIClient must be used within an APIClientProvider');
  }
  return context;
};

// Create a custom hook for the signed-in user
export const useSignedInUser = (): User | null | undefined => {
  const apiClient = useAPIClient();
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    return apiClient.observerSignedInUser(setUser);
  }, [apiClient]);

  return user;
};

// Create a custom hook for fetching documents
export const useUserDocuments = () => {
  const apiClient = useAPIClient();
  const [documents, setDocuments] = useState<DocumentMetadata[]>([]);

  useEffect(() => {
    return apiClient.observeDocumentsForSignedInUser(setDocuments);
  }, [apiClient]);

  return documents;
};

// Create a custom hook for fetching a single document
export const useDocument = (id: string) => {
  const apiClient = useAPIClient();
  const [document, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    return apiClient.observeDocument(id, setDocument);
  }, [apiClient, id]);

  return document;
};
