import { RGBColor, SketchPicker } from "react-color";
import { Popover, Rect } from "react-tiny-popover";
import { PopoverBackdrop, PopoverContainer, PropButtonWithPreview, SidebarSegmentedControl } from "./controls";
import { useCallback, useState } from "react";
import { Color, COLOR_CLEAR, COLOR_RED, Fill, fillToCSS } from "../../data/model";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import GradientPicker from "./gradientPicker";

interface SingleColorPickerProps {
    color: Color
    onChange: (color: Color, gestureId: string) => void
}

export function SingleColorPicker(props: SingleColorPickerProps) {
    const {color, onChange} = props;
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    const [popoverOpen, setPopoverOpen] = useState(false);
    const dismiss = useCallback(() => setPopoverOpen(false), []);
    const picker = (
        <PopoverContainer style={{userSelect: 'none'}}>
            <PopoverBackdrop onClick={dismiss} title="Close" />
            <SketchPicker 
                color={color} 
                styles={{picker: {boxShadow: 'none'} as any}}
                onChange={color => onChange(pickerToNativeColor(color.rgb), gestureId)} 
                onChangeComplete={ _ => setGestureId(uuidv4())} 
            />
        </PopoverContainer>
        );
    return (
        <Popover content={picker} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
            <div>
                <PropButtonWithPreview label="Color" onClick={() => setPopoverOpen(true)}>
                    <ColorPreview color={color} />
                </PropButtonWithPreview>
            </div>
        </Popover>
    )
}

interface FillPickerProps {
    fill: Fill | undefined
    onChange: (fill: Fill | undefined, gestureId: string) => void
}

export function FillPicker(props: FillPickerProps) {
    const {fill, onChange} = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const dismiss = useCallback(() => setPopoverOpen(false), []);
    const picker = (
        <FillPickerInner fill={fill} onChange={onChange} dismiss={dismiss} />
    );
    return (
        <Popover content={picker} isOpen={popoverOpen} onClickOutside={() => setPopoverOpen(false)}>
            <div>
                <PropButtonWithPreview label="Fill" onClick={() => setPopoverOpen(true)}>
                    <FillPreview fill={fill} />
                </PropButtonWithPreview>
            </div>
        </Popover>
    )
}

interface FillPickerInnerProps {
    fill: Fill | undefined
    onChange: (fill: Fill | undefined, gestureId: string) => void
    dismiss: () => void
}

function FillPickerInner({fill, onChange, dismiss}: FillPickerInnerProps) {
    let main: React.ReactNode = null;
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());
    if (!fill) {
        main = <FillEmptyState>No fill</FillEmptyState>
    } else if (fill.kind === 'solid') {
        main =  <SketchPicker
                    styles={{picker: {boxShadow: 'none'} as any}}
                    color={fill.color} 
                    onChange={color => onChange({ kind: 'solid', color: pickerToNativeColor(color.rgb) }, gestureId)} 
                    onChangeComplete={ _ => setGestureId(uuidv4())} 
                />
    } else if (fill.kind === 'gradient') {
        main = <GradientPicker gradient={fill} onChange={onChange} />
    }
    const setKind = useCallback((kind: Fill['kind'] | undefined) => {
        onChange(convertFillToKind(fill, kind), uuidv4());
    }, [fill]);
    const fillKinds: (Fill['kind'] | undefined)[] = ['solid', 'gradient', undefined];

    function nameForFillKind(kind: (Fill['kind'] | undefined)): string {
        switch (kind) {
            case 'gradient': return 'Gradient';
            case 'solid': return 'Color';
            case undefined: return "None";
        }
    }

    return (
        <PopoverContainer style={{userSelect: 'none'}}>
            <PopoverBackdrop onClick={dismiss} title="Close" />
            <SidebarSegmentedControl options={fillKinds} viewForOption={nameForFillKind} selection={fill?.kind} onChange={setKind} />
            {main}
        </PopoverContainer>
    )
}

const FillEmptyState = styled.div`
    width: 100%;
    height: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    font-size: 16px;
`;

function convertFillToKind(fill: Fill | undefined, kind: Fill['kind'] | undefined): Fill | undefined {
    let baseColor: Color = COLOR_RED;
    if (fill && fill.kind === 'solid') {
        baseColor = fill.color;
    }
    if (fill && fill.kind === 'gradient' && fill.colors.length > 0) {
        baseColor = fill.colors[0]
    }

    switch (kind) {
        case undefined: return undefined;
        case 'solid': return {kind: 'solid', color: baseColor};
        case 'gradient': return {kind: 'gradient', degrees: 0, colors: [baseColor, darken(baseColor)]};
    }
    return {kind: 'solid', color: baseColor};
}

// Just redirect to FillPreview
export function ColorPreview(props: {color: Color}) {
    return <FillPreview fill={{kind: 'solid', color: props.color}} />
}

export function FillPreview(props: {fill: Fill | undefined}) {
    const {fill} = props;
    return (
        <div style={{...fillToCSS(fill), width: 16, height: 16, borderRadius: 3}} />
        // <div style={{backgroundColor: `rgba(${fill.color.r},${fill.color.g},${fill.color.b},${fill.color.a})`, width: 16, height: 16, borderRadius: 3}} />
    )
}

export function pickerToNativeColor(color: RGBColor): Color {
    return {r: color.r, g: color.g, b: color.b, a: color.a || 1}
}

function darken(color: Color, amt: number = 40): Color {
    return {
        r: Math.max(0, color.r - amt),
        g: Math.max(0, color.g - amt),
        b: Math.max(0, color.b - amt),
        a: color.a
    }
}
