import { CanvasRect } from "../data/geo";
import { LayoutProps, Sizing } from "../data/model";

export function ensureSizesDontFillParent(layout: LayoutProps, curSize: CanvasRect) {
    if (layout.xSize && sizingFillsParent(layout.xSize)) {
        layout.xSize = { kind: 'fixed', value: curSize.width, unit: 'pixels' };
    }
    if (layout.ySize && sizingFillsParent(layout.ySize)) {
        layout.ySize = { kind: 'fixed', value: curSize.height, unit: 'pixels' };
    }
}

export function sizingFillsParent(sizing: Sizing | undefined): boolean {
    if (!sizing) { return false; } // hug is default
    switch (sizing.kind) {
        case 'fixed': return sizing.unit === 'percent';
        case 'hug': return false;
        case 'trailing': return true;
    }
}

export function ensureSimpleAnchors(layout: LayoutProps) {
    // TODO: Remove anchors that are percents and not 0 or 100
}
