import { RefObject } from "react"
import { Editor, EditorState, UndoableOperation } from "../data/editor"
import { CanvasPoint, CanvasRect, ClientPoint, ClientRect, CoordinateSpace, Rect, TypedRect, ViewportPoint, ViewportRect, unionRects } from "../data/geo"
import { Document, LayoutProps, Positioning, Sizing, layoutOf } from "../data/model"
import { ResizeHandle } from "./selectionFrameHandles"
import { boundingRectForObjectId } from "./viewHelpers"
import { canvasBoundingRectForObject, viewportToCanvasRect } from "../data/coordinates"
import clone from 'rfdc';
import { adjustDragDeltaUsingSnapping } from "./snapGuides"
import { nearestCommonParent } from "../data/operations"

const copy = clone();

export interface ResizeTarget {
    objectId: string
    d_size: CanvasPoint
    d_position: CanvasPoint // Not honored if object has auto layout
    originalSize: CanvasPoint
    offsetParentSize?: CanvasPoint
    snapRuleX?: number; // Display red snap guide at this, in canvas coords
    snapRuleY?: number;
}

export interface ItemToResize {
    id: string
    initialBoundingBox: ViewportRect
    initialCanvasBox: CanvasRect
    offsetParentSize?: CanvasPoint
}

export function createInitialItemsToResize(selection: Set<string>, editorState: EditorState, canvasWrapperRef: RefObject<HTMLDivElement>): ItemToResize[] {
    const items: ItemToResize[] = [];
    selection.forEach((id) => {
        const obj = editorState.document.objects[id];
        if (!obj) return;
        const viewportBox = boundingRectForObjectId(id, canvasWrapperRef);
        // console.log('viewportBox', viewportBox);
        const canvasBox = canvasBoundingRectForObject(id, canvasWrapperRef, editorState.canvasPos);
        const offsetParentBbox = obj.parent ? canvasBoundingRectForObject(obj.parent, canvasWrapperRef, editorState.canvasPos) : undefined;
        // console.log('canvasBox', canvasBox);
        if (!viewportBox || !canvasBox) return;
        items.push({
            id,
            initialBoundingBox: viewportBox,
            initialCanvasBox: canvasBox,
            offsetParentSize: offsetParentBbox ? {x: offsetParentBbox.width, y: offsetParentBbox.height, space: 'canvas'} : undefined
        });
    });
    return items;
}

export function computeResizes(
    items: ItemToResize[],
    handle: ResizeHandle,
    dragOffset: ViewportPoint,
    editor: Editor,
    mirror: boolean,
    lockAspectRatio: boolean,
    viewport: ClientRect
): ResizeTarget[] {
    if (items.length === 0) return [];
    const initialSelectionBoundingBox = unionRects(items.map((i) => i.initialBoundingBox));
    if (!initialSelectionBoundingBox) return [];
    const commonParent = nearestCommonParent(editor.state.value.document, items.map((i) => i.id));
    const {snapOffset, xSnapGuide, ySnapGuide} = adjustDragDeltaUsingSnapping(
        items.map((i) => i.id),
        commonParent || undefined,
        dragOffset,
        initialSelectionBoundingBox,
        handle,
        editor
    )
    const newDragOffset: ViewportPoint = {x: dragOffset.x + snapOffset.x, y: dragOffset.y + snapOffset.y, space: 'viewport'};
    const newSelectionBoundingBox = computeNewBoundingBox(initialSelectionBoundingBox, handle, newDragOffset, mirror, lockAspectRatio);
    const editorState = editor.state.value;

    const targets: ResizeTarget[] = [];
    for (const item of items) {
        const { initialBoundingBox, initialCanvasBox } = item;
        const newViewportBox = scaleInstanceBoundingBox(initialSelectionBoundingBox, newSelectionBoundingBox, initialBoundingBox);
        const newCanvasBox = viewportToCanvasRect(newViewportBox, editorState.canvasPos, viewport);
        targets.push({
            objectId: item.id,
            d_size: {
                x: (newCanvasBox.width - initialCanvasBox.width),
                y: (newCanvasBox.height - initialCanvasBox.height),
                space: 'canvas'
            },
            d_position: {
                x: (newCanvasBox.x - initialCanvasBox.x),
                y: (newCanvasBox.y - initialCanvasBox.y),
                space: 'canvas'
            },
            originalSize: {x: initialCanvasBox.width, y: initialCanvasBox.height, space: 'canvas'},
            offsetParentSize: item.offsetParentSize,
            snapRuleX: targets.length === 0 ? xSnapGuide : undefined,
            snapRuleY: targets.length === 0 ? ySnapGuide : undefined
        });
    }
    return targets;
}

function computeNewBoundingBox(initial: ViewportRect, handle: ResizeHandle, dragOffset: ViewportPoint, mirror: boolean, lockAspectRatio: boolean): ViewportRect {
    // TODO: Implement lockAspectRatio
    const newBox = { ...initial };
    if (handle.x) {
        switch (handle.x) {
            case 'leading':
                newBox.x += dragOffset.x;
                newBox.width -= dragOffset.x * (mirror ? 2 : 1);
                break;
            case 'trailing':
                newBox.width += dragOffset.x;
                if (mirror) {
                    newBox.x -= dragOffset.x;
                    newBox.width += dragOffset.x;
                }
                break;
        }
    }
    if (handle.y) {
        switch (handle.y) {
            case 'leading':
                newBox.y += dragOffset.y;
                newBox.height -= dragOffset.y * (mirror ? 2 : 1);
                break;
            case 'trailing':
                newBox.height += dragOffset.y;
                if (mirror) {
                    newBox.y -= dragOffset.y;
                    newBox.height += dragOffset.y;
                }
                break;
        }
    }
    return newBox;
}

function scaleInstanceBoundingBox(initialFull: ViewportRect, newFull: ViewportRect, instance: ViewportRect): ViewportRect {
    const scaleX = newFull.width / initialFull.width;
    const scaleY = newFull.height / initialFull.height;
    return {
        x: newFull.x + (instance.x - initialFull.x) * scaleX,
        y: newFull.y + (instance.y - initialFull.y) * scaleY,
        width: instance.width * scaleX,
        height: instance.height * scaleY,
        space: instance.space,
    };
}

// Stored for undo
interface OriginalSizing {
    id: string
    xSizing?: Sizing
    ySizing?: Sizing
    position?: LayoutProps['position']
}

export function applyResizes(doc: Document, resizes: ResizeTarget[]): UndoableOperation {
    const originals: OriginalSizing[] = resizes.flatMap((r) => {
        const object = doc.objects[r.objectId];
        if (!object) return [];
        const layout = layoutOf(object);
        if (!layout) return [];
        return [{
            id: r.objectId,
            xSizing: layout.xSize,
            ySizing: layout.ySize,
            position: layout.position,
        }];
    });
    const undo = (state: EditorState) => {
        for (const original of originals) {
            const object = state.document.objects[original.id];
            if (!object) continue;
            const layout = layoutOf(object);
            if (!layout) continue;
            layout.xSize = original.xSizing;
            layout.ySize = original.ySizing;
            layout.position = original.position;
        }
    };
    const do_ = (state: EditorState) => {
        state.resizesInProgress = [];
        for (let resize of resizes) {
            resize = copy(resize);

            const object = state.document.objects[resize.objectId];
            if (!object) continue;
            const layout = layoutOf(object);
            if (!layout) continue;

            // Modify the resize operation to correct for bottom-anchored and right-anchored objects
            if (layoutIsBottomAnchored(layout)) {
                resize.d_position.y += Math.round(resize.d_size.y);
            }
            if (layoutIsRightAnchored(layout)) {
                resize.d_position.x += Math.round(resize.d_size.x);
            }

            const pctMultiplierX = resize.offsetParentSize ? 100.0 / resize.offsetParentSize.x : 100.0;
            if (resize.d_size.x !== 0) {
                // layout.xSize = { fixed: 'pixels', value: resize.originalSize.x + resize.d_size.x };
                modifyFixedXSizing(layout, resize.d_size.x, pctMultiplierX, resize.originalSize);
            }
            const pctMultiplierY = resize.offsetParentSize ? 100.0 / resize.offsetParentSize.y : 100.0;
            if (resize.d_size.y !== 0) {
                // layout.ySize = { fixed: 'pixels', value: resize.originalSize.y + resize.d_size.y };
                modifyFixedYSizing(layout, resize.d_size.y, pctMultiplierY, resize.originalSize);
            }
            if (resize.d_position.x !== 0) {
                modifyFixedXPosition(layout, resize.d_position.x, pctMultiplierX);
            }
            if (resize.d_position.y !== 0) {
                modifyFixedYPosition(layout, resize.d_position.y, pctMultiplierY);
            }
        }
    };

    return {
        do: do_,
        undo: undo,
    }
}

export function commitResizesInProgress(editor: Editor) {
    if (editor.state.value.resizesInProgress.length === 0) return;
    const operation = applyResizes(editor.state.value.document, editor.state.value.resizesInProgress);
    editor.modifyUndoable(() => operation);
}

// `pctMultiplier` is what you'd multiply a px by to get a percentage. It's 1.0 / container_size * 100
function modifyFixedXPosition(layout: LayoutProps, delta: number, pctMultiplier: number) {
    const xPosition = layout.position?.kind === 'absolute' ? layout.position.x : undefined;
    if (!xPosition) return undefined;
    const convertedDelta = xPosition.unit === 'percent' ? delta * pctMultiplier : delta;
    if (xPosition.anchor === 'trailing') {
        xPosition.value -= convertedDelta;
    } else {
        xPosition.value += convertedDelta;
    }
}

function modifyFixedYPosition(layout: LayoutProps, delta: number, pctMultiplier: number) {
    const yPosition = layout.position?.kind === 'absolute' ? layout.position.y : undefined;
    if (!yPosition) return undefined;
    const convertedDelta = yPosition.unit === 'percent' ? delta * pctMultiplier : delta;
    if (yPosition.anchor === 'trailing') {
        yPosition.value -= convertedDelta;
    } else {
        yPosition.value += convertedDelta;
    }
}

function modifyFixedXSizing(layout: LayoutProps, delta: number, pctMultiplier: number, originalSize: CanvasPoint) {
    if (layout.xSize && layout.xSize.kind === 'fixed') {
        const convertedDelta = layout.xSize.unit === 'percent' ? delta * pctMultiplier : delta;
        layout.xSize.value += convertedDelta;
    } else if (layout.xSize && layout.xSize.kind === 'trailing') {
        const convertedDelta = layout.xSize.unit === 'percent' ? delta * pctMultiplier : delta;
        layout.xSize.offset -= convertedDelta;
    } else {
        layout.xSize = { kind: 'fixed', unit: 'pixels', value: originalSize.x + delta };
    }
}

function modifyFixedYSizing(layout: LayoutProps, delta: number, pctMultiplier: number, originalSize: CanvasPoint) {
    if (layout.ySize && layout.ySize.kind === 'fixed') {
        const convertedDelta = layout.ySize.unit === 'percent' ? delta * pctMultiplier : delta;
        layout.ySize.value += convertedDelta;
    } else if (layout.ySize && layout.ySize.kind === 'trailing') {
        const convertedDelta = layout.ySize.unit === 'percent' ? delta * pctMultiplier : delta;
        layout.ySize.offset -= convertedDelta;
    } else {
        layout.ySize = { kind: 'fixed', unit: 'pixels', value: originalSize.y + delta };
    }
}

function layoutIsBottomAnchored(layout: LayoutProps) {
    // return layout.yPosition && 'fixed' in layout.yPosition && layout.yPosition.trailing !== undefined && layout.yPosition.leading === undefined;
    return layout.position?.kind === 'absolute' && layout.position.y.anchor === 'trailing';
}

function layoutIsRightAnchored(layout: LayoutProps) {
    // return layout.xPosition && 'fixed' in layout.xPosition && layout.xPosition.trailing !== undefined && layout.xPosition.leading === undefined;
    return layout.position?.kind === 'absolute' && layout.position.x.anchor === 'trailing';
}
