import { CanvasPoint } from "../data/geo";

export function resizeImage(file: Blob, maxDim: number): Promise<Blob> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      const scale = Math.min(maxDim / img.width, maxDim / img.height);
      canvas.width = Math.floor(img.width * scale);
      canvas.height = Math.floor(img.height * scale);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const isJpeg = file.type === 'image/jpeg';
      canvas.toBlob((blob) => resolve(blob!), isJpeg ? 'image/jpeg' : 'image/png');
    }
    img.src = URL.createObjectURL(file);
  });
}

export function getImageDimensions(file: Blob): Promise<CanvasPoint> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ x: img.width, y: img.height, space: 'canvas' });
      URL.revokeObjectURL(img.src);
    };
    img.src = URL.createObjectURL(file);
  });
}

export function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsDataURL(blob);
    })
}
