
export default class Observable<T> {
    private _value: T;
    private _listeners: Array<(value: T) => void> = [];
    
    constructor(value: T) {
        this._value = value;
    }
    
    get value() {
        return this._value;
    }
    
    set value(value: T) {
        this._value = value;
        this._listeners.forEach(listener => listener(value));
    }
    
    subscribe(listener: (value: T) => void): Unsubscribe {
        this._listeners.push(listener);
        listener(this._value);
        return () => {
            this._listeners = this._listeners.filter(l => l !== listener);
        };
    }
}

type Unsubscribe = () => void;
