import { RefObject } from "react";
import { Editor, EditorState } from "./editor";
import { Document, LayoutProps, Positioning, childrenOf, isAbsolutePosition, layoutOf } from "./model";
import { CanvasPoint } from "./geo";
import clone from 'rfdc';
import { boundingRectForObjectId, elementForObjectId } from "../ui/viewHelpers";
import { canvasBoundingRectForObject } from "./coordinates";

const copy = clone();

export function modifyConstraintsOnView(state: EditorState, objectId: string, canvasWrapper: RefObject<HTMLDivElement>, newConstraints: {x?: Set<Constraint>, y?: Set<Constraint>}): LayoutProps {
    const obj = state.document.objects[objectId];
    if (!obj) return {};

    const layoutProps: LayoutProps = copy(layoutOf(obj) || {});
    // const element = elementForObjectId(objectId, canvasWrapper);
    const elementCanvasRect = canvasBoundingRectForObject(objectId, canvasWrapper, state.canvasPos);
    const parentCanvasRect = obj.parent ? canvasBoundingRectForObject(obj.parent, canvasWrapper, state.canvasPos) : null;
    if (!elementCanvasRect || !parentCanvasRect) return layoutProps;

    const offsetFromParent: CanvasPoint = {x: elementCanvasRect.x - parentCanvasRect.x, y: elementCanvasRect.y - parentCanvasRect.y, space: 'canvas'};
    const newFixedPos: {x: Positioning, y: Positioning} = 
    layoutProps.position?.kind === 'absolute' ? layoutProps.position 
    : {x: {anchor: 'leading', value: offsetFromParent.x, unit: 'pixels'}, y: {anchor: 'leading', value: offsetFromParent.y, unit: 'pixels'}};

    const updateConstraints = (constraints: Set<Constraint>, axis: 'x' | 'y') => {
        const itemSize = axis === 'x' ? elementCanvasRect.width : elementCanvasRect.height;
        const itemLeadingOffset = axis === 'x' ? elementCanvasRect.x : elementCanvasRect.y;
        const parentLeadingOffset = axis === 'x' ? parentCanvasRect.x : parentCanvasRect.y;
        const parentSize = axis === 'x' ? parentCanvasRect.width : parentCanvasRect.height;
        const sizeKey = axis === 'x' ? 'xSize' : 'ySize';

        if (constraints.has(Constraint.leading) && constraints.has(Constraint.trailing)) {
            newFixedPos[axis] = {anchor: 'leading', value: itemLeadingOffset - parentLeadingOffset, unit: 'pixels'};
            layoutProps[sizeKey] = {kind: 'trailing', offset: parentSize + parentLeadingOffset - itemLeadingOffset - itemSize, unit: 'pixels'};
        } else {
            switch (constraints.values().next().value) {
                case Constraint.leading:
                    newFixedPos[axis] = {anchor: 'leading', value: itemLeadingOffset - parentLeadingOffset, unit: 'pixels'};
                    break;
                case Constraint.trailing:
                    newFixedPos[axis] = {anchor: 'trailing', value: parentSize + parentLeadingOffset - itemLeadingOffset - itemSize, unit: 'pixels'};
                    break;
                case Constraint.center:
                    newFixedPos[axis] = {anchor: 'center', value: itemLeadingOffset + itemSize / 2 - parentLeadingOffset - parentSize / 2, unit: 'pixels'};
                    break;
            }
            if (layoutProps[sizeKey]?.kind === 'trailing') {
                layoutProps[sizeKey] = {kind: 'fixed', value: itemSize, unit: 'pixels'};
            }
        }
    };

    if (newConstraints.x) {
        updateConstraints(newConstraints.x, 'x');
    }
    if (newConstraints.y) {
        updateConstraints(newConstraints.y, 'y');
    }
    layoutProps.position = {kind: 'absolute', ...newFixedPos};

    return layoutProps;
}

// Constraints are not the internal model we use for layout, but we can translate to/from this model since it's more user-friendly
export enum Constraint {
    leading,
    trailing,
    center,
}

export interface Constraints {
    x: Set<Constraint>;
    y: Set<Constraint>;
}

export function constraintsFromLayout(layout: LayoutProps | null): Constraints {
    const constraints = {
        x: new Set<Constraint>(),
        y: new Set<Constraint>(),
    };
    const pos = layout?.position;
    if (!pos || pos.kind !== 'absolute') {
        return constraints;
    }
    constraints.x.add(constraintFromAnchor(pos.x.anchor));
    constraints.y.add(constraintFromAnchor(pos.y.anchor));
    if (layout && layout.xSize && layout.xSize.kind === 'trailing') {
        constraints.x.add(pos.x.anchor === 'trailing' ? Constraint.leading : Constraint.trailing);
    }
    if (layout && layout.ySize && layout.ySize.kind === 'trailing') {
        constraints.y.add(pos.y.anchor === 'trailing' ? Constraint.leading : Constraint.trailing);
    }
    return constraints;
}

function constraintFromAnchor(anchor: Positioning['anchor']): Constraint {
    switch (anchor) {
        case 'leading':
            return Constraint.leading;
        case 'trailing':
            return Constraint.trailing;
        case 'center':
            return Constraint.center;
    }
}
