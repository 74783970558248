import { RefObject, useCallback, useEffect, useState } from "react";
import OpenAI from "openai";
import { BaseObject, HTMLEmbed } from "../../data/model";
import { useEditor } from "../../data/editor";
import { PropButton, SidebarSection, SidebarTextArea } from "./controls";
import { modifyObjectFieldUndoable, modifyObjectSubfieldUndoable } from "../../data/editUtils";
import { codegen2 } from "../../data/generation/codegen2";

interface HTMLEmbedSettingsProps {
    canvasWrapper: RefObject<HTMLDivElement>;
    objects: BaseObject[];
}

export default function HTMLEmbedSettings(props: HTMLEmbedSettingsProps) {
    const { objects } = props;
    const valid = objects.length === 1 && objects[0].type === 'htmlEmbed';
    const editor = useEditor();
    const [generating, setGenerating] = useState(false);

    if (!valid) {
        return null;
    }

    // const [prompt, setPrompt] = useState('');
    const object = objects[0] as HTMLEmbed;
    const setPrompt = function(str: string)  {
        for (const id of objects.map(obj => obj.id)) {
            modifyObjectSubfieldUndoable<HTMLEmbed, 'codeGenParams', 'programDescription'>(editor, id, 'htmlEmbed', 'codeGenParams', 'programDescription', str);
        }
    };
    const prompt = object.codeGenParams.programDescription;

    async function generate() {
        if (!valid || prompt === '' || generating) {
            return;
        }
        setGenerating(true);
        const params = object.codeGenParams;
        try {
            const msg = await codegen2({
                frameAsString: undefined,
                programDescription: prompt,
                params: object.params,
                urlMap: object.codeGenParams.urlMap,
                thread: [] // clear thread
            });
            modifyObjectFieldUndoable<HTMLEmbed, 'codeGenParams'>(editor, object.id, 'htmlEmbed', 'codeGenParams', 
                {
                    ...params, 
                    thread: [msg], 
                    lastGeneratedCode: msg.code || params.lastGeneratedCode
                });
            setGenerating(false);
        } catch (e) {
            console.error(e);
            setGenerating(false);
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    return (
        <SidebarSection>
            <h6>Generate HTML</h6>
            <SidebarTextArea value={prompt} onChange={e => setPrompt(e.target.value)} onKeyDown={onKeyDown} />
            <PropButton onClick={generate} disabled={generating} label="Generate" />
        </SidebarSection>
    )
}
