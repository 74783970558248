import { v4 as uuidv4 } from "uuid";
import { canvasOffsetForObject } from "../data/coordinates"
import { Editor, EditorState } from "../data/editor"
import { CanvasPoint, ClientPoint, ViewportPoint } from "../data/geo"
import { childrenOf, EditableFrame, ImageObject, layoutOf, LayoutProps, stylingOf } from "../data/model"
import { addObject, locationOfObject, ObjectLocation, removeObject } from "../data/operations"
import { editorStateModifiedForDisplay } from "../data/renderHelpers"
import { diveIntoChildrenContainingMouseAndFittingSize, dropIndex } from "./moveLogic"
import { useAPIClient } from "../data/apiClient/apiClientHooks"
import { APIClient } from "../data/apiClient/apiClient"
import { getImageDimensions, resizeImage } from "../utils/image";

interface DragHandlers {
    onDragEnter: React.DragEventHandler
    onDragLeave: React.DragEventHandler
    onDragOver: React.DragEventHandler
    onDrop: React.DragEventHandler
}

export interface DropPos {
    location: ObjectLocation; // in hierarchy
    size: CanvasPoint
    offset?: CanvasPoint // if undefined, use auto layout
}

export function useDragHandlers(editor: Editor): DragHandlers {
    const apiClient = useAPIClient();
    const onDragEnter: React.DragEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        editor.modify(s => {
            s.externalDropPlaceholder = computeDropPos({ x: e.clientX, y: e.clientY, space: "client" }, editor);
        })
    }
    const onDragLeave: React.DragEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        editor.modify(s => {
            s.externalDropPlaceholder = undefined;
        })
    }
    const onDragOver: React.DragEventHandler = (e) => {
        const dropPos = computeDropPos({ x: e.clientX, y: e.clientY, space: "client" }, editor);
        // console.log(dropPos);
        e.preventDefault();
        e.stopPropagation();
        editor.modify(s => {
            s.externalDropPlaceholder = dropPos;
        })
    }
    const onDrop: React.DragEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDropContent(e).then(content => {
            if (content) {
                commitDrop(editor, content, apiClient);
            }
        });
        // TODO: implement drop
    }
    return { onDragEnter, onDragLeave, onDragOver, onDrop };
}

// export function diveIntoChildrenContainingMouseAndFittingSize(
//     doc: Document,
//     objectId: string | null,
//     excludeIds: Set<string>,
//     mouse: ViewportPoint, // in viewport coords
//     size: ViewportPoint, // in viewport coords
//     canvasWrapper: RefObject<HTMLDivElement>
// ): string | null {

// export function dropIndex(displayDoc: Document, parentId: string | null, mouse: ViewportPoint, canvasWrapper: RefObject<HTMLDivElement>, selection: Set<string>): number {

function computeDropPos(clientPos: ClientPoint, editor: Editor): DropPos | undefined {
    const canvasWrapper = editor.viewportRef;
    if (!canvasWrapper) return undefined;
    const viewportPos = editor.clientToViewportPos(clientPos);
    const dropSize: ViewportPoint = { x: 100, y: 100, space: "viewport" }; // just because
    const editorState = editor.state.value;
    const doc = editorState.document;
    const parentId = diveIntoChildrenContainingMouseAndFittingSize(doc, null, new Set([DROP_PLACEHOLDER_ID]), viewportPos, dropSize, canvasWrapper);
    // Convert to canvas pos
    const canvasPos = editor.viewportToCanvasPos(viewportPos);
    const parentOffset = canvasOffsetForObject(parentId, canvasWrapper);
    if (!parentOffset) return undefined;
    const offsetInParent: CanvasPoint = { x: canvasPos.x - parentOffset.x, y: canvasPos.y - parentOffset.y, space: "canvas" };
    // TODO: compute displayDoc
    const displayedDoc = editorStateModifiedForDisplay(editorState).document;
    const dropIdx = dropIndex(displayedDoc, parentId, viewportPos, canvasWrapper, new Set([DROP_PLACEHOLDER_ID]));
    const parentIsAutoLayout = parentId && !!(layoutOf(doc.objects[parentId])?.flexLayout);
    return {
        location: { parent: parentId, index: dropIdx },
        size: { x: dropSize.x, y: dropSize.y, space: "canvas" },
        offset: parentIsAutoLayout ? undefined : offsetInParent,
    };
}

const DROP_PLACEHOLDER_ID = '__drop_placeholder__';

export function applyExternalDropPlaceholder(editorState: EditorState, placeholder: DropPos) {
    addObject(editorState.document, createExternalDropPlaceholderObject(placeholder), placeholder.location);
}

function createExternalDropPlaceholderObject(dropPos: DropPos): ImageObject {
    return {
        id: DROP_PLACEHOLDER_ID,
        type: "image",
        layout: {
            position: dropPos.offset ? staticPosition(dropPos.offset) : {kind: 'inline'},
            xSize: { kind: 'fixed', value: dropPos.size.x, unit: 'pixels' },
            ySize: { kind: 'fixed', value: dropPos.size.y, unit: 'pixels' },
        },
        stylingProps: {
            background: { kind: 'solid', color: {r: 180, g: 180, b: 180, a: 1} },
        },
    }
}

function staticPosition(offset: CanvasPoint): LayoutProps['position'] {
    return { kind: 'absolute', x: { value: offset.x, unit: 'pixels', anchor: 'leading' }, y: { value: offset.y, unit: 'pixels', anchor: 'leading' } };
}

type DropContent = 
  | { kind: 'text'; text: string }
  | { kind: 'image'; dimensions: CanvasPoint; data: Blob }
  | { kind: 'other'; data: Blob };

const MAX_IMAGE_DIM = 2000;

async function handleDropContent(event: React.DragEvent): Promise<DropContent | null> {
  event.preventDefault();

  const items = event.dataTransfer?.items;
  if (!items) return null;

  for (const item of Array.from(items)) {
    if (item.kind === 'string' && item.type === 'text/plain') {
      return new Promise<DropContent>((resolve) => {
        item.getAsString((text) => {
          resolve({ kind: 'text', text });
        });
      });
    } else if (item.kind === 'file') {
      let file: Blob | null = item.getAsFile();
      if (file) {
        const isAllowedImageType = /^image\/(jpeg|png|gif|webp)$/i.test(file.type);
        if (isAllowedImageType) {
          let dimensions = await getImageDimensions(file);
          if (dimensions.x > MAX_IMAGE_DIM || dimensions.y > MAX_IMAGE_DIM) {
            file = await resizeImage(file, MAX_IMAGE_DIM);
            dimensions = await getImageDimensions(file);
          }
          return { kind: 'image', dimensions, data: file };
        } else {
          return { kind: 'other', data: file };
        }
      }
    }
  }

  return null;
}

function commitDrop(editor: Editor, content: DropContent, apiClient: APIClient) {
    const dropPos = editor.state.value.externalDropPlaceholder;
    if (!dropPos) return;

    // TODO: Support uploading files and text
    if (content.kind !== 'image') {
        editor.modify(s => {
            s.externalDropPlaceholder = undefined;
        });
        return;
    }

    const newObj = createExternalDropPlaceholderObject(dropPos);
    newObj.id = uuidv4();
    adjustSizeToMatchAspectRatio(newObj.layout, content.dimensions);

    // editor.modify(s => {
    //     s.externalDropPlaceholder = undefined;
    //     addObject(s.document, newObj, dropPos.location);
    // });
    editor.modifyUndoable(s => {
        return {
            do: state => {
                state.externalDropPlaceholder = undefined;
                addObject(state.document, newObj, dropPos.location);
            },
            undo: state => {
                removeObject(state.document, newObj.id);
            }
        }
    });

    // Upload data
    apiClient.upload(content.data, undefined).then(metadata => {
        console.log('Got metadata', metadata);
        editor.modify(s => {
            const obj = s.document.objects[newObj.id];
            if (!obj) return;
            const styling = stylingOf(obj);
            if (styling) {
                // export type ImagePointer = { id: string, url: string, dimensions: CanvasPoint, placeholderColor: Color, alt?: string };
                styling.image = { id: metadata.id, url: metadata.url, signedUrl: metadata.signedUrl, dimensions: content.dimensions, placeholderColor: { r: 180, g: 180, b: 180, a: 1 } };
            }
        });
    });
}

function adjustSizeToMatchAspectRatio(layout: LayoutProps, size: CanvasPoint) {
    const aspect = size.x / Math.max(1, size.y);
    if (!layout.xSize || !layout.ySize) return;

    if (layout.xSize.kind === 'fixed' && layout.ySize.kind === 'fixed') {
        // Shrink the larger dimension to match the aspect ratio
        if (aspect > 1) {
            layout.ySize.value = layout.xSize.value / aspect;
        } else {
            layout.xSize.value = layout.ySize.value * aspect;
        }
    }
}
