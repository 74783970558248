import styled from "styled-components";
import { Color, COLOR_BLACK, colorToCSS, Gradient } from "../../data/model";
import { useState } from "react";
import { CONTROL_ROW_HEIGHT, NumberInput } from "./controls";
import { SketchPicker } from "react-color";
import { pickerToNativeColor } from "./colorPickers";
import { v4 as uuidv4 } from "uuid";

interface GradientPickerProps {
    gradient: Gradient
    onChange: (gradient: Gradient, gestureId: string) => void
}

export default function GradientPicker(props: GradientPickerProps) {
    const {gradient, onChange} = props;
    const [editingStopIndex, setEditingStopIndex] = useState<number>(0);
    const [gestureId, setGestureId] = useState<string>(() => uuidv4());

    const color = editingStopIndex < props.gradient.colors.length ? props.gradient.colors[editingStopIndex] : COLOR_BLACK;
    const setColor = (color: Color) => {
        const newGradient = {...gradient};
        if (editingStopIndex < newGradient.colors.length) {
            newGradient.colors[editingStopIndex] = color;
        }
        onChange(newGradient, gestureId);
    };

    const setDegrees = (degrees: number, gestureId: string) => {
        onChange({...gradient, degrees}, gestureId);
    }

    return (
        <Container>
            <TopStrip>
                <GradientPreview style={{background: colorsToHorizontalGradient(gradient.colors)}}>
                    {gradient.colors.map((color, i) => (
                        <Stop key={i} $color={color} onClick={() => setEditingStopIndex(i)} $selected={i === editingStopIndex} />
                    ))}
                </GradientPreview>
                <NumberInput label="Angle" value={gradient.degrees} onChange={val => setDegrees(val, 'degrees')} width="100px" />
            </TopStrip>
            <SketchPicker
                    styles={{picker: {boxShadow: 'none'} as any}}
                    color={color} 
                    onChange={color => setColor(pickerToNativeColor(color.rgb))}
                    onChangeComplete={ _ => setGestureId(uuidv4())} 
                />
        </Container>
    )
}

function colorsToHorizontalGradient(colors: Color[]) {
    return `linear-gradient(to right, ${colors.map(c => colorToCSS(c)).join(", ")})`;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const TopStrip = styled.div`
    display: flex;
    gap: 8px;
`;

const GradientPreview = styled.div`
    height: ${CONTROL_ROW_HEIGHT}px;
    flex-grow: 1;
    border-radius: 3px;
    display: flex;
    gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${(CONTROL_ROW_HEIGHT - 16) / 2}px;
`;

const Stop = styled.div<{$color: Color, $selected: boolean}>`
    width: 16px;
    height: 16px;
    background: ${props => colorToCSS(props.$color)};
    border-radius: 3px;
    border: 3px solid ${props => props.$selected ? 'white' : 'gray'};
    cursor: pointer;
    &:hover {
        border: 2px solid white;
    }
`;

