import { elementForObjectId } from "../ui/viewHelpers";
import { mapArrayToDict } from "../utils/collectionUtils";
import { replaceInline } from "../utils/replaceAllKeys";
import { canvasBoundingRectForObject, canvasSizeForObject } from "./coordinates";
import { Editor, EmptyUndoableOperation } from "./editor";
import { boundingRectForPoints, CanvasPoint, CanvasRect, centerOfRect, unionRects } from "./geo";
import { childrenOf, FlexLayout, flexLayoutOf, layoutOf, LayoutProps } from "./model";

export type LayoutMode = 'absolute' | 'inline';

export function getLayoutMode(props: LayoutProps | undefined | null): LayoutMode | undefined {
    if (!props) { return undefined; }
    if (props.position) {
        return props.position.kind;
    }
    return 'inline';
}


export function setLayoutModeUndoable(editor: Editor, mode: LayoutMode, objId: string, wrapperRef: React.RefObject<HTMLDivElement>) {
    const hasParent = !!editor.state.value.document.objects[objId].parent;
    if (!hasParent && mode === 'inline') { return; }
    switch (mode) {
        case 'inline':
            modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
                layout.position = {kind: 'inline'};
            });
            break;
        case 'absolute':
            modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
                if (!pos) return;
                layout.position = {kind: 'absolute', x: {value: pos.x, unit: 'pixels', anchor: 'leading'}, y: {value: pos.y, unit: 'pixels', anchor: 'leading'}};
                if (!layout.xSize || layout.xSize.kind !== 'fixed') {
                    layout.xSize = {kind: 'fixed', value: pos.width, unit: 'pixels'};
                }
                if (!layout.ySize || layout.ySize.kind !== 'fixed') {
                    layout.ySize = {kind: 'fixed', value: pos.height, unit: 'pixels'};
                }
            });
            break;
    }
}

export function modifyLayout(editor: Editor, objId: string, wrapperRef: React.RefObject<HTMLDivElement>, edit: (layout: LayoutProps, posInParentRect: CanvasRect | undefined, parentSize: CanvasPoint | undefined) => void) {
    // use bounding rect to find size, then compute offset from parent
    const rect = canvasBoundingRectForObject(objId, wrapperRef, editor.state.value.canvasPos);
    const objectElement = elementForObjectId(objId, wrapperRef);
    if (!rect || !objectElement) { 
        console.warn('[modifyLayout] Tried to modify layout of object that does not have a rendered DOM element.')
        // return; 
    }
    if (rect && objectElement) {
        // Update rect to be in parent coords
        rect.x = objectElement.offsetLeft;
        rect.y = objectElement.offsetTop;    
    }
    const obj = editor.state.value.document.objects[objId];
    if (!obj) { return; }
    const parentSizeOrNull = obj.parent ? canvasSizeForObject(obj.parent, wrapperRef) : undefined;
    const parentSize = parentSizeOrNull === null ? undefined : parentSizeOrNull;

    editor.modifyUndoable(state => {
        // Store old layout
        const obj = state.document.objects[objId];
        if (!obj) { return EmptyUndoableOperation }
        const oldLayout = layoutOf(obj);
        if (!oldLayout) { return EmptyUndoableOperation }

        return {
            do: state => {
                const obj = state.document.objects[objId];
                if (!obj) { return state; }
                const layout = layoutOf(obj);
                if (!layout) { return state; }
                edit(layout, rect ?? undefined, parentSize);
            },
            undo: state => {
                const obj = state.document.objects[objId];
                if (!obj) { return state; }
                const layout = layoutOf(obj);
                if (!layout) { return state; }
                replaceInline(oldLayout, layout);
            }
        }
    });
}

// Undoable
export function addAutoLayout(editor: Editor, wrapperRef: React.RefObject<HTMLDivElement>, objectIds: string[]) {
    // First, modify layout to add flex (if not already there)
    const newFlexLayouts = mapArrayToDict(objectIds, (id) => [id, computeFlexLayoutMatchingExistingLayout(id, editor)]);

    objectIds.forEach(objId => {
        modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
            layout.flexLayout = newFlexLayouts[objId] ?? DEFAULT_FLEX_LAYOUT;
            // layout.flexLayout = {direction: 'column', gap: 0, justifyContent: 'flex-start', alignItems: 'flex-start'};
        });
    });
    const allChildren = objectIds.flatMap(objId => {
        const obj = editor.state.value.document.objects[objId];
        if (!obj) { return []; }
        return childrenOf(obj);
    });
    allChildren.forEach(childId => {
        setLayoutModeUndoable(editor, 'inline', childId, wrapperRef);
    });
}

export function removeAutoLayout(editor: Editor, wrapperRef: React.RefObject<HTMLDivElement>, objectIds: string[]) {
    objectIds.forEach(objId => {
        modifyLayout(editor, objId, wrapperRef, (layout, pos) => {
            delete layout.flexLayout;
        });
    });
    const allChildren = objectIds.flatMap(objId => {
        const obj = editor.state.value.document.objects[objId];
        if (!obj) { return []; }
        return childrenOf(obj);
    });
    allChildren.forEach(childId => {
        setLayoutModeUndoable(editor, 'absolute', childId, wrapperRef);
    });
}

const DEFAULT_FLEX_LAYOUT: FlexLayout = {direction: 'column', gap: 0, justifyContent: 'flex-start', alignItems: 'flex-start'};

// Compute an auto-layout that broadly matches our existing layout
export function computeFlexLayoutMatchingExistingLayout(parentId: string, editor: Editor): FlexLayout {
    const doc = editor.state.value.document;
    const canvasWrapper = editor.viewportRef;
    if (!canvasWrapper) return DEFAULT_FLEX_LAYOUT;
    const parent = doc.objects[parentId];
    console.log('parent:', parent);
    if (!parent) return DEFAULT_FLEX_LAYOUT;

    // const boundingBoxes: {[id: string]: CanvasRect} = {};
    const centers: CanvasPoint[] = [];
    const childRects: CanvasRect[] = [];
    const totalSizes: CanvasPoint = {space: 'canvas', x: 0, y: 0};
    const maxSizes: CanvasPoint = {space: 'canvas', x: 0, y: 0};
    const children = childrenOf(parent);
    console.log('children:', children.length);
    if (children.length === 0) {
        return DEFAULT_FLEX_LAYOUT
    }
    for (const child of children) {
        const bbox = canvasBoundingRectForObject(child, canvasWrapper, editor.state.value.canvasPos);
        if (!bbox) continue;
        centers.push(centerOfRect(bbox));
        totalSizes.x += bbox.width;
        totalSizes.y += bbox.height;
        maxSizes.x = Math.max(maxSizes.x, bbox.width);
        maxSizes.y = Math.max(maxSizes.y, bbox.y);
        childRects.push(bbox);
    }
    const centersBoundingBox = boundingRectForPoints(centers);
    const childrenBoundingBox = unionRects(childRects);
    console.log('centers:', centersBoundingBox);
    console.log('childrenbbox:', childrenBoundingBox);
    if (!centersBoundingBox || !childrenBoundingBox) return DEFAULT_FLEX_LAYOUT;

    // HACK: When performing a Shift+A on multiple objects, we group them and then apply auto layout in a single frame.
    // This means we can't fetch the parent's (the new group)'s bbox because it hasn't been rendered yet.
    // In this case, use the child bounding box (which should match) for layout estimation
    const parentBox = canvasBoundingRectForObject(parentId, canvasWrapper, editor.state.value.canvasPos) || childrenBoundingBox;

    const direction: FlexLayout['direction'] = centersBoundingBox.width > centersBoundingBox.height ? 'row' : 'column';
    const paddingX = (parentBox.width - childrenBoundingBox.width) / 2;
    const paddingY = (parentBox.height - childrenBoundingBox.height) / 2;
    const gap = Math.max(0, 
        direction === 'row' ? (parentBox.width - totalSizes.x - paddingX * 2) : (parentBox.height - totalSizes.y - paddingY * 2)
    );
    const childDistanceFromTop = childrenBoundingBox.y - parentBox.y;
    const childDistanceFromBottom = parentBox.y + parentBox.height - (childrenBoundingBox.y + childrenBoundingBox.height);
    const childDistanceFromLeft = (childrenBoundingBox.x - parentBox.x);
    const childDistanceFromRight = parentBox.x + parentBox.width - (childrenBoundingBox.x + childrenBoundingBox.width);
    const childDistanceFromCenterX = Math.abs(centerOfRect(childrenBoundingBox).x - centerOfRect(parentBox).x);
    const childDistanceFromCenterY = Math.abs(centerOfRect(childrenBoundingBox).y - centerOfRect(parentBox).y);
    const xDists: [BasicFlexAlign, number][] = [['flex-start', childDistanceFromLeft], ['center', childDistanceFromCenterX], ['flex-end', childDistanceFromRight]];
    const yDists: [BasicFlexAlign, number][] = [['flex-start', childDistanceFromTop], ['center', childDistanceFromCenterY], ['flex-end', childDistanceFromBottom]];
    xDists.sort((a,b) => a[1] - b[1]);
    yDists.sort((a,b) => a[1] - b[1]);
    const xAlign = xDists[0][0];
    const yAlign = yDists[0][0];
    return {
        direction,
        gap,
        justifyContent: direction === 'column' ? yAlign : xAlign,
        alignItems: direction === 'column' ? xAlign : yAlign
    }
}

type BasicFlexAlign = 'flex-start' | 'center' | 'flex-end';